import React, { Component } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { getVideos } from "../../actions/videoActions";
import { editSubPost, deleteSubPost } from "../../actions/subscriptionsActions";
import { connect } from "react-redux";
import Select from "react-select";
import FileUpload from "../common/FileUpload";
import PropTypes from "prop-types";
import ToggleButton from "react-toggle-button";
import FileUploadSubs from "../common/FileUploadSubs";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

class EditSubPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      published: false,
      video: "",
      selectedOption: null, // Selected Video
      filename: "",
      attachment: {
        label: "",
        value: ""
      },
      errors: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  componentDidMount() {
    this.props.getVideos();

    this.setState({
      name: this.props.post.title,
      selectedOption: this.props.post.video,
      published: this.props.post.published
    });

    if (this.props.post.description) {
      this.setState({
        description: this.props.post.description
      });
    }

    if (
      this.props.post.attachment !== undefined &&
      this.props.post.attachment !== null
    ) {
      this.setState({
        attachment: this.props.post.attachment
      });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleQuillChange(value) {
    this.setState({ description: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const id = this.props.id;

    let attachment = null;

    if (this.state.filename !== "" && this.props.subscriptions.file !== null) {
      attachment = {
        label: this.state.filename,
        value: this.props.subscriptions.file
      };
    } else {
      attachment = this.state.attachment;
    }

    const payload = {
      post: {
        title: this.state.name,
        video: this.state.selectedOption,
        published: this.state.published,
        attachment: attachment,
        _id: this.props.post._id,
        description: this.state.description
      }
    };

    this.props.editSubPost(payload, id, this.props.history);
  }

  handleDelete() {
    this.props.deleteSubPost(this.props.post._id);

    console.log(this.props.post._id);
  }

  render() {
    const { name, video, published, errors, filename } = this.state;

    const { videos } = this.props.videos;
    let options; // options for select
    if (videos) {
      options = videos;
    }
    const { selectedOption } = this.state;

    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]
      ]
    };

    return (
      <div className="container">
        <div className="d-flex justify-content-between mt-3">
          <div>
            <h1>Add post</h1>
          </div>
          <div>
            <button onClick={this.handleSubmit} className="btn btn-pp">
              Save
            </button>
          </div>
        </div>

        <div>
          <div>
            <TextFieldGroup
              placeholder="Name"
              name="name"
              type="name"
              value={name}
              onChange={this.handleChange}
              error={errors.name}
            />

            <ReactQuill
              value={this.state.description}
              onChange={this.handleQuillChange}
              modules={modules}
            />
          </div>

          <div className="mt-3">
            <h3>Select a video:</h3>
            <Select
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <div className="mt-3 mb-3">
            <div>
              <h3>Attachment:</h3>
            </div>
            <div>
              <span>Name: {this.state.attachment.label}</span>
            </div>
            <div>
              <span>Link: {this.state.attachment.value}</span>
            </div>
          </div>
          <div>
            <div className="mr-5">
              <h3>Document upload</h3>
            </div>
            <div className="mr-3">
              <TextFieldGroup
                placeholder="filename"
                name="filename"
                type="name"
                value={filename}
                onChange={this.handleChange}
                error={errors.name}
              />
            </div>

            <div>
              <FileUploadSubs />
            </div>
          </div>

          <div className="mt-5">
            Published
            <ToggleButton
              value={this.state.published}
              inactiveLabel="NO"
              activeLabel="YES"
              onToggle={published => {
                this.setState({
                  published: !published
                });
              }}
            />
          </div>
        </div>
        <button className="btn btn-danger mt-5" onClick={this.handleDelete}>
          DELETE
        </button>
      </div>
    );
  }
}

EditSubPost.propTypes = {
  post: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  videos: state.videos,
  subscriptions: state.subscriptions
});

export default connect(
  mapStateToProps,
  { getVideos, editSubPost, deleteSubPost }
)(EditSubPost);
