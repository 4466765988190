import React, { Component } from "react";
import { connect } from "react-redux";
import { fileUpload, clearFile } from "../../actions/courseActions";
import { getBuckets } from "../../actions/videoActions";
import Select from "react-select";
import PropTypes from "prop-types";
import VideoUpload from "./VideoUpload";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class VideoUploadParent extends Component {
  componentDidMount() {
    this.props.getBuckets();
  }

  render() {
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isShow = privileges && isVisible(privileges, "upload")
    if(!isShow)
    return <div className="p-5" />
    
    return (
      <div className="container">
        <VideoUpload buckets={this.props.videos.buckets} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  courses: state.courses,
  videos: state.videos
});

export default connect(
  mapStateToProps,
  { fileUpload, clearFile, getBuckets }
)(VideoUploadParent);
