import React, { Component } from 'react';

class PleaseVerify extends Component{

    render(){

        return (
          <div id="jumbo0">
            <div className="pleaseVerifyHeader">
              <h1>Thank you for signing up</h1>
              <h2>Please check your email for the verification link</h2>
              <p>You can close this tab or go to the <a href="/login">Login</a> page.</p>
            </div>
          </div>
        );
    }
}

export default PleaseVerify;
