import React, { Component } from "react";
import PropTypes from "prop-types";
import UserQuestion from "./UserQuestion";
import axios from "axios";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getUniversity } from "../../actions/courseActions";

class UserQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correctAnswers: null,
      questionNum: null,
      passed: null
    };
  }

  componentDidMount() {
    axios
      .get("/api/courses/checkPassed", {
        params: { id: this.props.match.params.lesson }
      })
      .then(res => {
        this.setState({
          passed: res.data
        });
        console.log(res.data);
      })
      .catch(err => {
        console.log("Error retrieving passed status");
      });
  }

  countCorrect() {
    const { correctAnswers } = this.state;
    let questionNum = this.props.questions.length;
    let counter = correctAnswers + 1;
    this.setState({
      correctAnswers: counter
    });

    console.log(counter);
    console.log(questionNum);

    if (counter === questionNum) {
      const payload = {
        lessonID: this.props.match.params.lesson
      };
      axios
        .post("/api/courses/answerQuestions", payload)
        .then(res => {
          console.log(res.data);
          this.setState({
            passed: true
          });
        })
        .catch(err => {
          console.log("Error changing passed status");
        });

      const id = this.props.match.params.id;
      if (id === "5bf46a89bd079d4c38a9fcee") {
        //UNIVERSITY ID
        this.props.getUniversity();
        this.setState({ isUniversity: true });
        console.log("got university");
      } else {
        this.props.getCourse(id);
        console.log("got course");
      }
    }
  }

  renderQuestions(questions) {
    const { passed } = this.state;

    if (passed === true) {
      return (
        <div>
          <span className="text-success">
            You answered all questions correctly, good job!
          </span>
        </div>
      );
    } else {
      return <div>{questions}</div>;
    }
  }

  render() {
    let questions;

    if (this.props.questions === null) {
      questions = null;
    } else {
      questions = this.props.questions.map(question => {
        return (
          <div key={question._id}>
            <UserQuestion
              question={question}
              countCorrect={() => this.countCorrect()}
            />
          </div>
        );
      });
    }

    return (
      <div>
        <div>
          <h3>Questions:</h3>
        </div>
        <div>{this.renderQuestions(questions)}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

UserQuestions.propTypes = {
  questions: PropTypes.array
};

export default connect(
  mapStateToProps,
  { getUniversity }
)(withRouter(UserQuestions));
