import { GET_SHOPPINGCART_ORDERS, SET_ORDERS_LOADING } from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  orders: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SHOPPINGCART_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false
      };
    case SET_ORDERS_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
