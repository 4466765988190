import axios from "axios";
import {
  GET_ERRORS,
  GET_SHOPPINGCART_ORDERS,
  SET_ORDERS_LOADING
} from "./types";

// Get 1shoppingcart orders
export const getShoppingCartOrders = page => dispatch => {
  axios
    .get("/api/admin/getOrders", { params: { page: page } })
    .then(res => {
      dispatch({
        type: GET_SHOPPINGCART_ORDERS,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

export const setOrderLoading = () => dispatch => {
  dispatch({
    type: SET_ORDERS_LOADING
  });
};
