import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { Link } from '../../../node_modules/react-router-dom';
import { connect } from 'react-redux';
import { getUser,getAdmins, editUserProfileAdmin, getMembers, clearErrors } from '../../actions/memberActions';
import { clearFile } from '../../actions/courseActions';
import ToggleButton from 'react-toggle-button';
import Subscriptions from './Subscriptions';
import { slide as Menu } from 'react-burger-menu';
import { deleteAdmin } from '../../actions/authActions';
import { getRoles } from '../../actions/roleActions';
import FileUpload from '../common/FileUpload';
import TextFieldGroup from '../common/TextFieldGroup';
import isEmpty from '../../validation/is-empty';
import axios from 'axios';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import { CLEAR_ERRORS } from '../../actions/types';
import { thisExpression } from 'babel-types';
import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class EditAdmin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: '',
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			status: '',
			emailStatus: false,
			shouldDeleteUserRender: false,
			filename: '',
			downloads: [],
			loading: true,
			errors: {},
			rolemeta: "",
			roles: props.roles || [],
			currentPassword: "This password can't be decrypted"
		};

		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleAddDownload = this.handleAddDownload.bind(this);
		this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
		this.handleQuillChange = this.handleQuillChange.bind(this);
		this.getUserPassword = this.getUserPassword.bind(this);
		this.handleChangeRole = this.handleChangeRole.bind(this)
	}

	componentDidMount() {
		console.log({
			props: this.props
		})
		this.props.getRoles()
		// this.props.getUser(this.props.match.params.id);
		
		if (this.props.match.params.id) {
			this.props.getUser(this.props.match.params.id);
			this.getUserPassword(this.props.match.params.id);
		} else {
			this.props.getUser(this.props.id, 'isadmin');
			this.getUserPassword(this.props.id);
		}
	}

	componentWillUnmount() {
		this.props.clearErrors();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.errors) {
			this.setState({
				errors: nextProps.errors
			});
		}
		
		if (isEmpty(nextProps.errors) === true) {
			if (nextProps.members.member) {
				const member = nextProps.members.member;

				this.setState({
					id: member._id,
					firstName: member.firstName,
					lastName: member.lastName,
					email: member.email,
					status: member.status,
					role: member.role,
					rolemeta: member.rolemeta,
					loading: false
				});
			}
		}
		if(nextProps.roles !== this.props.roles){
			this.setState({
				roles: nextProps.roles
			})
		}
	}

	handleChangeRole(e) {
		e.preventDefault();
		const { id, rolemeta } = this.state;
		axios
			.post('/api/admin/editrole', {
				userid: id,
				role: rolemeta
			})
			.then((res) => {
				// this.props.getUser(this.props.id, 'isadmin');
				this.props.getAdmins();
			})
			.catch((err) => console.log({ err }));
	}
	handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });

		if (
			(e.target.name === 'status' && e.target.value === 'DISABLED') ||
			(e.target.name === 'status' && e.target.value === 'EXPIRED')
		) {
			this.setState({
				emailStatus: false
			});
		}
	}

	handleQuillChange(value) {
		this.setState({ notes: value });
	}

	handleSubmit(e) {
		e.preventDefault();

		const payload = {
			user: {
				_id: this.state.id,
				firstName: this.state.firstName,
				lastName: this.state.lastName,
				email: this.state.email,
				status: this.state.status
			}
		};
		this.props.editUserProfileAdmin({ ...payload, isadmin: 'isadmin' });
	}
	getUserPassword(_id) {
		console.log({ _id });
		const payload = {
			user: {
				_id
			},
			isadmin: 'isadmin'
		};
		axios
			.post('/api/admin/getpassword', payload)
			.then((res) => {
				console.log({ res });
				const { password } = res.data;
				if (password)
					this.setState({
						currentPassword: password
					});
			})
			.catch((err) => {
				console.log({ err });
				this.setState({
					currentPassword: 'This password cant be decrypted'
				});
			});
	}
	handlePasswordSubmit(e) {
		e.preventDefault();
		const { isadmin } = this.props;
		const payload = {
			userID: this.state.id,
			password: this.state.password
		};

		axios
			.post('/api/admin/changeUserPassword', isadmin ? { ...payload, isadmin: 'isadmin' } : payload)
			.then((res) => {
				this.setState(
					{
						passwordChangeSuccess: true,
						password: '',
						currentPassword: 'loading...'
					},
					() => {
						this.getUserPassword(this.state.id, isadmin);
					}
				);
			})
			.catch((err) => {
				this.setState({
					errors: err.response.data
				});
			});
	}

	renderDeleteUser() {
		const { shouldDeleteUserRender, id } = this.state;

		if (shouldDeleteUserRender === false) {
			return (
				<div>
					<button
						onClick={() => {
							this.setState({ shouldDeleteUserRender: true });
						}}
						className="btn btn-danger"
					>
						DELETE THIS USER
					</button>
				</div>
			);
		} else if (shouldDeleteUserRender === true) {
			return (
				<div>
					Are you sure you want to delete this user?{' '}
					<small>There is no way to retrieve the user data once it has been deleted.</small>
					<div className="d-flex justify-content-start align-items-center">
						<div>
							<button className="btn btn-link text-danger" onClick={() => this.props.deleteAdmin(id)}>
								Yes
							</button>
						</div>
						<div>
							<button
								className="btn btn-link"
								onClick={() => {
									this.setState({ shouldDeleteUserRender: false });
								}}
							>
								No
							</button>
						</div>
					</div>
				</div>
			);
		}
	}

	deleteDl(index) {
		let arej = this.state.downloads;
		arej.splice(index, 1);
		this.setState({
			downloads: arej
		});
	}

	renderDownloads() {
		const { downloads } = this.state;

		if (isEmpty(downloads) === true) {
			return <div>This user has no downloads.</div>;
		} else {
			const renderDls = downloads.map((dl, index) => {
				return (
					<li key={dl.value} className="list-group-item">
						<div className="row">
							<div className="col-6">{dl.label}</div>
							<div className="col-5">
								<a href={dl.value} target="_blank">
									view
								</a>
							</div>
							<div className="col-1">
								<i onClick={() => this.deleteDl(index)} className="fas fa-trash-alt" />
							</div>
						</div>
					</li>
				);
			});
			return renderDls;
		}
	}

	handleDownloads() {
		const { errors, id, filename } = this.state;

		return (
			<div className="mt-3">
				<div>
					<div className="row">
						<div className="col-8">
							<TextFieldGroup
								placeholder="filename"
								name="filename"
								type="name"
								value={filename}
								onChange={this.handleChange}
								error={errors.name}
							/>
						</div>

						<div className="col-2" />

						<div className="col-2">
							<div>
								<button onClick={this.handleAddDownload} className="btn btn-link">
									Add
								</button>
							</div>
						</div>
					</div>
				</div>

				<div>
					<FileUpload />
				</div>
			</div>
		);
	}

	handleAddDownload() {
		const { downloads, filename } = this.state;
		const newArej = downloads;
		const newAjtem = {
			label: filename,
			value: this.props.courses.file
		};
		if (this.props.courses.file !== null) {
			newArej.push(newAjtem);
			this.setState({
				downloads: newArej,
				filename: ''
			});
		}
		this.props.clearFile();
	}

	render() {
		const { errors, id, filename } = this.state;
		
		const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
		const isDeletable = privileges && isVisible(privileges, "admins", "delete")
		const isEditable = privileges && isVisible(privileges, "admins", "edit")

		console.log(this.state.roles);

		let modules = {
			toolbar: [
				[ 'bold', 'italic', 'underline', 'strike' ], // toggled buttons

				[ { header: 1 }, { header: 2 } ], // custom button values
				[ { list: 'ordered' }, { list: 'bullet' } ],
				// [{ script: "sub" }, { script: "super" }], // superscript/subscript
				// [{ direction: "rtl" }], // text direction

				[ { header: [ 1, 2, 3, 4, 5, 6, false ] } ],

				[ { align: [] } ]
			]
		};
		if(!isEditable){
			return <div className="p-5" />
		}


		const member = (
			<div className="mt-3">
				<div className="row">
					<div className="col">
						<h3>Edit Profile</h3>
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">Status</span>
							</div>

							<select
								className="form-control"
								name="status"
								value={this.state.status}
								onChange={this.handleChange}
							>
								<option value="ACTIVE">ACTIVE</option>
								<option value="DISABLED">DISABLED</option>
								<option value="EXPIRED">EXPIRED</option>
							</select>
						</div>

						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">First Name</span>
							</div>

							<input
								type="text"
								className={classnames('form-control', {
									'is-invalid': errors.firstName
								})}
								placeholder="Firstname"
								name="firstName"
								value={this.state.firstName}
								onChange={this.handleChange}
							/>
							{errors.firstName && <div className="invalid-feedback">{errors.firstName}</div>}
						</div>
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">Last Name</span>
							</div>

							<input
								type="text"
								className={classnames('form-control', {
									'is-invalid': errors.lastName
								})}
								placeholder="Lastname"
								name="lastName"
								value={this.state.lastName}
								onChange={this.handleChange}
							/>
							{errors.lastName && <div className="invalid-feedback">{errors.lastName}</div>}
						</div>

						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">Email</span>
							</div>

							<input
								type="text"
								className={classnames('form-control', {
									'is-invalid': errors.email
								})}
								placeholder="Email"
								name="email"
								value={this.state.email}
								onChange={this.handleChange}
							/>
							{errors.email && <div className="invalid-feedback">{errors.email}</div>}
						</div>

						<button className="btn btn-pp mt-3" onClick={this.handleSubmit}>
							Save
						</button>
					</div>

					<div className="col">
						<div>
							<h3>Current password</h3>
							<input
								type="text"
								className={classnames(
									'form-control',
									{
										// "is-invalid": errors.password
									}
								)}
								placeholder="Current Password"
								name="current_password"
								disabled={true}
								value={this.state.currentPassword}
								onChange={this.handleChange}
							/>
							<br />
							<h3>Change password</h3>
							<input
								type="password"
								className={classnames('form-control', {
									'is-invalid': errors.password
								})}
								placeholder="Password"
								name="password"
								value={this.state.password}
								onChange={this.handleChange}
							/>
							{errors.password && <div className="invalid-feedback">{errors.password}</div>}
							<div className="text-right">
								<button className="btn btn-pp mt-3 " onClick={this.handlePasswordSubmit}>
									Change password
								</button>
							</div>
						</div>

						<div>
							<h3>Select Role</h3>
							<select
								className="form-control"
								name="rolemeta"
								value={this.state.rolemeta}
								onChange={this.handleChange}
							>	
								<option value={null} selected disabled >None</option>
								{this.state.roles.filter(el => el.status).map(el => (
									<option key={el._id} value={el._id}>{el.name}</option>
								))}
								{/* <option value="Admin">Admin</option> */}
								{/* <option value="User">User</option> */}
							</select>
							<div className="text-right">
								<button className="btn btn-pp mt-3 " onClick={this.handleChangeRole}>
									Change Role
								</button>
							</div>
							<br />
						</div>
					</div>
				</div>
				<hr />
				{isDeletable && <div className="mt-5">{this.renderDeleteUser()}</div>}
			</div>
		);

		return (
			<div className="container">
				{this.state.loading === true ? (
					<div className="text-center">
						<img src={require('../Orders/loading.gif')} alt="" />
					</div>
				) : (
					member
				)}
			</div>
		);
	}
}

EditAdmin.propTypes = {
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	id: PropTypes.string.isRequired,
	getRoles: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	auth: state.auth,
	errors: state.errors,
	members: state.members,
	courses: state.courses,
	roles: state.roles.roles
});

export default withRouter(
	connect(mapStateToProps, {
		getUser,
		editUserProfileAdmin,
		getMembers,
		deleteAdmin,
		clearFile,
		clearErrors,
		getAdmins,
		getRoles
	})(EditAdmin)
);
