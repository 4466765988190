import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { GetAsset } from '../../actions/newsletterActions'
import Menu from '../common/Menu'
import NewsletterList from './AssestsList'


class Newsletters extends Component {
  componentDidMount() {
    this.props.GetAsset()
  }

  render() {
    const { assets } = this.props

    return (
      <div className="wrapper-alt">
        <Menu />
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Assets</h1>
            </div>
            <div>{<Link to="/admin/dashboard/assets/add">Add asset</Link>}</div>
          </div>
          <div>
            <NewsletterList assets={assets.assets} />
          </div>
        </div>
      </div>
    )
  }
}

Newsletters.propTypes = {}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  newsletters: state.newsletters,
  assets: state.assets,
})

export default connect(mapStateToProps, { GetAsset })(Newsletters)
