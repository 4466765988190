import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import { addMemberClearSuccess, addMemberSetSuccess } from "../../actions/formActions";
import { addRole } from "../../actions/roleActions";
import { withRouter } from "react-router-dom";

class AddRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      name: "",
      published: false,
      errors: {},
      isSuccess: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }
  componentDidMount(){
    console.log({
      Props: this.props,
    })
  }
  componentWillReceiveProps(nextProps) {
    console.log({
      nextProps
    })
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.forms){
      if(nextProps.forms.addMember.isSuccess){
        this.setState({ 
          isSuccess: true,
          description: "",
          name: "",
          published: false,
          errors: {},
        }, ()=> {
          this.props.addMemberClearSuccess()
          setTimeout(() => {
            this.setState({
              isSuccess: false,
            })
          }, 2000);
        });
      }
    }
  }

  //Handles state changes for inputs
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  //Handles state changes for inputs
  handleCheckbox(e) {
    const {
      name
    } = e.target
    this.setState(prevState=> ({ [name]: !prevState[name] }));
  }

  //Sends login credentials/payload to the backend
  handleSubmit(e) {
    e.preventDefault();

    const newRole = {
      name: this.state.name,
      description: this.state.description,
      status: this.state.published,
      privileges: {
        "order": {
            "view": false
        },
        "members": {
           "create":false,
            "view": false,
            "edit": false,
            "delete": false
        },
        "courses": {
           "create":false,
            "view": false,
            "edit": false,
            "delete": false
        },
        "planningandscanning": {
          "create":false,
            "view": false,
            "edit": false,
            "delete": false
        },
        "subscription": {
          "create":false,
            "view": false,
            "edit": false,
            "delete": false
        },
        "emails": {
            "view": false,
            "edit": false,
            "create": false
        },
        "upload": {
            "view": false
        },
        "admins": {
            "create": false,
            "edit": false,
            "delete": false
        }
      }
    };

    this.props.addRole(newRole);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="wrapper-alt">
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <h1 className="display-4 text-center">Add role</h1>

                <form noValidate onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.name
                      })}
                      placeholder="Name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.description
                      })}
                      placeholder="Description"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                    />
                    {errors.description && (
                      <div className="invalid-feedback">{errors.description}</div>
                    )}
                  </div>
                  <div>
                    <label className="form-check-inline" >
                      <input 
                        type="checkbox"
                        name="published"
                        className="form-check-inline"
                        checked={this.state.published}
                        onChange={this.handleCheckbox}
                      />
                      Published
                    </label>

                  </div>
                  <hr />

                  {this.state.isSuccess && 
                    <div className="alert alert-success">Member Successfully Created </div>
                  }
                  <input type="submit" className="btn btn-pp btn-block mt-4" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddRole.propTypes = {
  addRole: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  forms: state.forms,
  roles: state.roles
});

export default connect(
  mapStateToProps,
  { registerUser, addMemberClearSuccess, addMemberSetSuccess, addRole }
)(withRouter(AddRole));
