import {
    ADD_MEMBER_SET_SUCCESS,
    ADD_MEMBER_CLEAR_SUCCESS
} from './types'

export const addMemberSetSuccess = ()=> ({
    type: ADD_MEMBER_SET_SUCCESS,
})
export const addMemberClearSuccess = ()=> ({
    type: ADD_MEMBER_CLEAR_SUCCESS,
})