import axios from "axios";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  EDIT_PROFILE,
  GET_ADMINS,
  CLEAR_ERRORS
} from "./types";

import { getAdmins, getMembers } from "./memberActions";
import { addMemberSetSuccess } from "./formActions";

// Register Admin
export const registerAdmin = userData => dispatch => {
  axios
    .post("/api/admin", userData)
    .then(response => {
      dispatch(getAdmins());
      dispatch({
        type: CLEAR_ERRORS,
        payload: {}
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const deleteAdmin = id => dispatch => {
  axios
    .delete("/api/admin", { params: { id: id } })
    .then(response => {
      dispatch(getAdmins());
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const registerUser = userData => dispatch => {
  axios
    .post("/api/users", userData)
    .then(_response => {
      const payload = {
        limit: 20,
        page: "1"
      };

      dispatch(addMemberSetSuccess());
      dispatch(getMembers(payload));
      dispatch({
        type: CLEAR_ERRORS,
        payload: {}
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Login User
export const loginUser = userData => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(response => {
      // Set current user
      dispatch(setCurrentUser(response.data));
      localStorage.setItem("userData", JSON.stringify(response.data));
      dispatch({
        type: CLEAR_ERRORS,
        payload: {}
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Login Admin
export const loginAdmin = userData => dispatch => {
  axios
    .post("/api/admin/login", userData)
    .then(response => {
      // Set current user
      console.log({response}, '___________LOGIN______________')
      dispatch(setCurrentUser(response.data));
      localStorage.setItem("userData", JSON.stringify(response.data));
      dispatch({
        type: CLEAR_ERRORS,
        payload: {}
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Set current user
export const setCurrentUser = userData => {
  return {
    type: SET_CURRENT_USER,
    payload: userData
  };
};

//Logout
export const logoutUser = () => dispatch => {
  //Remove userData from local storage
  localStorage.removeItem("userData");
  dispatch(setCurrentUser({}));
  axios.get("/api/users/logout");
};

// Edit user profile
export const editUserProfile = payload => dispatch => {
  axios
    .put("/api/users/editProfile", payload)
    .then(response => {
      dispatch(setCurrentUser(response.data));
      localStorage.setItem("userData", JSON.stringify(response.data));
      // dispatch({
      //   type: EDIT_PROFILE,
      //   payload: response.data
      // });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};
