import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TextFieldGroup from "../common/TextFieldGroup";
import { createSubs } from "../../actions/subscriptionsActions";
import Menu from "../common/Menu";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class CreateSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      errors: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleQuillChange(value) {
    this.setState({ description: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { name, description } = this.state;

    this.props.createSubs(name, description);
  }

  render() {
    const { errors } = this.state;
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isAdd = privileges && isVisible(privileges, "subscription", "create")
    if(!isAdd){
      return <div className="p-5" />
    }

    if (this.props.subscriptions.subscription) {
      this.props.history.push(
        "/admin/dashboard/subscriptions/" +
          this.props.subscriptions.subscription._id
      );
    }

    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]
      ]
    };

    return (
      <div className="wrapper">
        <Menu />
        <div className="container">
          <h1>Create a new subscription</h1>

          <TextFieldGroup
            placeholder="name"
            name="name"
            type="name"
            value={this.state.name}
            onChange={this.handleChange}
            error={errors.name}
          />

          <div className="form-group">
            <ReactQuill
              value={this.state.description}
              onChange={this.handleQuillChange}
              modules={modules}
            />
          </div>
          <button className="btn btn-pp" onClick={this.handleSubmit}>
            Save
          </button>
        </div>
      </div>
    );
  }
}

CreateSubscription.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  subscriptions: state.subscriptions
});

export default connect(
  mapStateToProps,
  { createSubs }
)(CreateSubscription);
