import {
    SHOW_MEMBER_POPUP,
    HIDE_MEMBER_POPUP
} from "./types";

export const showMemberPopup = ()=> ({
    type: SHOW_MEMBER_POPUP,
})

export const hideMemberPopup = ()=> ({
    type: HIDE_MEMBER_POPUP,
})