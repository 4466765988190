import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import logo from './logo.png'
class TopNav extends Component {
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="">
          <span style={{ marginRight: "10px" }}>
            {user.firstName} {user.lastName}
          </span>
          <span style={{ marginRight: "10px" }}>
            {this.props.auth.user.role === "User" ? (
              <Link className="nav-link-pp" to="/dashboard">
                Dashboard
              </Link>
            ) : (
              <Link className="nav-link-pp" to="/admin/dashboard">
                Dashboard
              </Link>
            )}
          </span>
          <span style={{ marginRight: "10px" }}>
            {this.props.auth.user.role === "Admin" ? null : (
              <Link className="nav-link-pp" to="/dashboard/edit-profile">
                Edit Profile
              </Link>
            )}
          </span>

          <a
            href="#"
            className="nav-link-pp"
            onClick={this.onLogoutClick.bind(this)}
          >
            Logout
          </a>
        </li>
      </ul>
    );

    const guestLinks = (
      <div className="navbar ml-auto">
        <div>
          <span className="mr-3">
            {/* <Link className="nav-link-pp" to="/register">
              REGISTER
            </Link> */}
            <a className="nav-link-pp" href="https://personsplanet.com">
              BACK TO SITE
            </a>
          </span>
          <span className="mr-3">|</span>
          <span className="mr-3">
            <Link className="nav-link-pp" to="/login">
              MEMBER LOGIN
            </Link>
          </span>
        </div>
      </div>
    );

    return (
      <nav id="top-nav" className="navbar navbar-expand-lg navbar-light ">
        <div className="collapse navbar-collapse ">
          <div id="navbar-nav">
            <a href="https://personsplanet.com">
              <img
                className="logo"
                src={logo}
                alt="persons planet logo"
              />
            </a>
          </div>
        </div>

        {isAuthenticated ? authLinks : guestLinks}
      </nav>
    );
  }
}

TopNav.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(TopNav);
