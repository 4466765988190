import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import AddSubscription from "./AddSubscription";
import SubToPas from "../pas/SubToPas";
import moment from "moment";
import {
  UnSubUserFromSubscription,
  UnSubUserFromCourse,
  subscribeForSub
} from "../../actions/memberActions";

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isSubLifetime(
    isLifetime,
    index,
    name,
    activationDate,
    expirationDate,
    _id,
    newSubDate
  ) {
    if (isLifetime === false) {
      return (
        <div className="row">
          <div className="col-1">{index}</div>
          <div className="col-3">{name}</div>
          <div className="col-3">
            {moment(activationDate).format("dddd, MMMM Do YYYY")}
          </div>
          <div className="col-3">
            {moment(expirationDate).format("dddd, MMMM Do YYYY")}
          </div>
          <div className="col-1">
            <i
              onClick={() =>
                this.props.subscribeForSub(this.props.userID, _id, newSubDate)
              }
              className="fas fa-plus-square"
            />
          </div>
          <div className="col-1">
            <i
              onClick={() =>
                this.props.UnSubUserFromSubscription(this.props.userID, _id)
              }
              className="fas fa-user-times"
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-1">{index}</div>
          <div className="col-3">{name}</div>
          <div className="col-3">
            {moment(activationDate).format("dddd, MMMM Do YYYY")}
          </div>
          <div className="col-3">
            <span className="text-success">Lifetime</span>
          </div>
          <div className="col-1" />
          <div className="col-1">
            <i
              onClick={() =>
                this.props.UnSubUserFromSubscription(this.props.userID, _id)
              }
              className="fas fa-user-times"
            />
          </div>
        </div>
      );
    }
  }

  render() {
    let courses;

    if (this.props.courses.length === 0) {
      courses = "This user has no courses";
    } else {
      courses = this.props.courses.map((course, index) => {
        return (
          <li
            key={course._id}
            className="list-group-item list-group-item-action"
          >
            <div className="row">
              <div className="col-1">{index}</div>
              <div className="col-7">{course.name}</div>
              <div className="col-3">
                {moment(course.date).format("dddd, MMMM Do YYYY")}
              </div>
              <div className="col-1">
                <i
                  onClick={() =>
                    this.props.UnSubUserFromCourse(
                      this.props.userID,
                      course._id
                    )
                  }
                  className="fas fa-user-times"
                />
              </div>
            </div>
          </li>
        );
      });
    }

    let subscriptions;

    if (this.props.subscriptions.length == 0) {
      subscriptions = "This user has no subscriptions";
    } else {
      subscriptions = this.props.subscriptions.map((sub, index) => {
        const newSubDate = moment(sub.expirationDate).add(31, "days");
        return (
          <li key={sub._id} className="list-group-item list-group-item-action">
            {this.isSubLifetime(
              sub.isLifetime,
              index,
              sub.name,
              sub.activationDate,
              sub.expirationDate,
              sub._id,
              newSubDate
            )}
          </li>
        );
      });
    }

    return (
      <div>
        <h2>Services</h2>
        <hr />

        <div className="sub-container">
          <SubToPas userID={this.props.userID} pas={this.props.pas} />
        </div>

        <div className="sub-container mt-5">
          <h3>Courses</h3>
          <AddSubscription userID={this.props.userID} type="course" />
          <div className="row mt-3">
            <div className="col-1">#</div>
            <div className="col-7">Name</div>
            <div className="col-3">Activation Date</div>
            <div className="col-1" />
          </div>
          <ul className="list-group">{courses}</ul>
          <hr />
        </div>

        <div className="sub-container mt-5">
          <h3>Subscriptions</h3>
          <AddSubscription userID={this.props.userID} type="sub" />
          <div className="row mt-3">
            <div className="col-1">#</div>
            <div className="col-3">Name</div>
            <div className="col-3">Activation Date</div>
            <div className="col-3">Expiration Date</div>
            <div className="col-1" />
            <div className="col-1" />
          </div>
          <ul className="list-group">{subscriptions}</ul>
          <hr />
        </div>
      </div>
    );
  }
}

Subscriptions.propTypes = {
  userID: PropTypes.string.isRequired,
  courses: PropTypes.array,
  pas: PropTypes.object,
  subscriptions: PropTypes.array
};

const mapStateToProps = state => ({
  member: state.members.member,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStateToProps,
    { UnSubUserFromSubscription, UnSubUserFromCourse, subscribeForSub }
  )(Subscriptions)
);
