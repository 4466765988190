import React, { Component } from "react";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ""
    };
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  handleQuillChange(value) {
    this.setState({ text: value });
  }

  render() {
    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"]
      ]
    };

    return (
      <div>
        <ReactQuill
          value={this.state.text}
          onChange={this.handleChange}
          modules={modules}
        />
      </div>
    );
  }
}
