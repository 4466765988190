import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DeleteAsset } from '../../actions/newsletterActions'

class Newsletter extends Component {
  constructor(props) {
    super(props)

    this.handleDelete = this.handleDelete.bind(this)
  }
  handleDelete() {
    this.props.DeleteAsset(this.props.asset)
  }

  render() {
    const { asset } = this.props
    return (
      <div className="row d-flex align-items-center">
        <div className="col-3">{asset.name}</div>
        <div className="col-8">
          <a href={asset.source}>{asset.source}</a>
        </div>
        <div className="col-1">
          <button onClick={this.handleDelete} className="btn btn-link" style={{color: "red"}}>
            delete
          </button>
        </div>
      </div>
    )
  }
}

Newsletter.propTypes = {
  asset: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, {
  DeleteAsset
})(withRouter(Newsletter))
