import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import Select from "react-select";
import { CSVLink, CSVDownload } from "react-csv";

import {
  getMembers,
  getMembersBySub,
  getMembersByCourse,
  memberLoading
} from "../../actions/memberActions";
import MemberList from "./MemberList";
import { getCourses } from "../../actions/courseActions";
import { getSubs } from "../../actions/subscriptionsActions";
import {
  getCSVMembers,
  getCSVCourses,
  getCSVSubscriptions,
  clearCSVData,
  clearMembers
} from "../../actions/memberActions";
import {
  showMemberPopup,
  hideMemberPopup
} from "../../actions/popupActions";
import AddMember from "./AddMember";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class Members extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 20,
      search: "",
      searchBy: "Member",
      fieldnames: [
        { label: "First Name", value: "firstName" },
        { label: "Last Name", value: "lastName" },
        { label: "Address", value: "address" },
        { label: "City", value: "city" },
        { label: "Email", value: "email" },
        { label: "Expiry Date", value: "pas.expirationDate" },
        { label: "Phone Number", value: "phone" },
        { label: "Status", value: "status" },
      ],
      subStatus: null,
      selectedOption: null,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.exportMembers = this.exportMembers.bind(this);
    this.exportCourses = this.exportCourses.bind(this);
    this.exportSubscriptions = this.exportSubscriptions.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.loadPage = this.loadPage.bind(this);
    this.handleSearchByMemberStateChange = this.handleSearchByMemberStateChange.bind(
      this
    );
    this.handleSearchByCourseStateChange = this.handleSearchByCourseStateChange.bind(
      this
    );
    this.handleSearchBySubStateChange = this.handleSearchBySubStateChange.bind(
      this
    );
  }

  componentDidMount() {
    const payload = {
      limit: this.state.limit,
      page: this.state.page
    };

    this.props.memberLoading();

    this.props.getMembers(payload);
    this.props.getCourses();
    this.props.getSubs();
  }

  componentWillUnmount() {
    this.props.clearMembers();
  }

  loadPage(page) {
    const { searchBy, selectedOption, subStatus } = this.state;

    if (searchBy === "Member") {
      const payload = {
        limit: this.state.limit,
        search: this.state.search,
        page: page
      };
      this.props.memberLoading();
      this.props.getMembers(payload);
    }

    if (searchBy === "Course") {
      const payload = {
        limit: this.state.limit,
        courseID: selectedOption.value,
        page: page
      };
      this.props.memberLoading();
      this.props.getMembersByCourse(payload);
      console.log(payload);
    }

    if (searchBy === "Sub") {
      const payload = {
        limit: this.state.limit,
        subscriptionID: this.state.selectedOption.value,
        active: subStatus.value,
        page: page
      };
      this.props.memberLoading();
      this.props.getMembersBySub(payload);
      console.log(payload);
    }
  }

  renderPages(numOfPages) {
    let pages = [];
    for (let i = 1; i <= numOfPages; i++) {
      pages.push(
        <div key={i} className="pageCounter" onClick={() => this.loadPage(i)}>
          <span className="mr-2" key={i}>
            {i}
          </span>
        </div>
      );
    }
    return pages;
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSearch(e) {
    e.preventDefault();

    const payload = {
      limit: this.state.limit,
      search: this.state.search,
      page: 1
    };

    this.props.getMembers(payload);
  }

  handleSearchByMemberStateChange(e) {
    this.setState({ searchBy: "Member", selectedOption: null });
    this.props.clearCSVData();
    this.handleSearch(e);
  }

  handleSearchByCourseStateChange(e) {
    this.setState({ searchBy: "Course", selectedOption: null });
    this.props.clearCSVData();
  }

  handleSearchBySubStateChange(e) {
    this.setState({ searchBy: "Sub", selectedOption: null });
    this.props.clearCSVData();
  }

  renderSearchButtons() {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <button
            className="btn btn-link"
            onClick={this.handleSearchByMemberStateChange}
          >
            Search Members
          </button>
        </div>
        <div>
          <button
            className="btn btn-link"
            onClick={this.handleSearchByCourseStateChange}
          >
            Search by Course
          </button>
        </div>
        <div>
          <button
            className="btn btn-link"
            onClick={this.handleSearchBySubStateChange}
          >
            Search by Subscription
          </button>
        </div>
      </div>
    );
  }

  handleSubSelectChange = subStatus => {
    this.setState({ subStatus });

    const { searchBy } = this.state;

    if (searchBy === "Sub") {
      const payload = {
        limit: this.state.limit,
        subscriptionID: this.state.selectedOption.value,
        active: subStatus.value,
        page: 1
      };
      this.props.memberLoading();
      this.props.getMembersBySub(payload);
      console.log(payload);
    }
  };

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });

    const { searchBy } = this.state;

    if (searchBy === "Course") {
      const payload = {
        limit: this.state.limit,
        courseID: selectedOption.value,
        page: 1
      };
      this.props.memberLoading();
      this.props.getMembersByCourse(payload);
      console.log(payload);
    }
  };

  renderSubStatusSelect() {
    const { searchBy, selectedOption, subStatus } = this.state;

    if (selectedOption === null) {
      return null;
    } else {
      return (
        <Select
          value={subStatus}
          onChange={this.handleSubSelectChange}
          options={[
            { label: "Active", value: true },
            { label: "Expired", value: false },
            { label: "All", value: "" }
          ]}
          placeholder="Status"
        />
      );
    }
  }

  handleFieldNamesSelectChange = fieldnames => {
    this.setState({ fieldnames });
  };

  exportMembers() {
    const { fieldnames, search } = this.state;

    

    const payload = {
      search: search,
      fieldnames: fieldnames.map(val => {
        console.log(val)
        console.log({"value": val.value})
        return val.value;
      })
    };
    console.log({payload})

    this.props.clearCSVData();
    this.props.getCSVMembers(payload);
  }

  exportCourses() {
    const { fieldnames, selectedOption } = this.state;

    const payload = {
      courseID: selectedOption.value,
      fieldnames: fieldnames.map(val => {
        return val.value;
      })
    };
    this.props.clearCSVData();
    this.props.getCSVCourses(payload);
  }

  exportSubscriptions() {
    const { fieldnames, selectedOption, subStatus } = this.state;

    const payload = {
      subscriptionID: selectedOption.value,
      active: subStatus.value,
      fieldnames: fieldnames.map(val => {
        return val.value;
      })
    };

    this.props.clearCSVData();
    this.props.getCSVSubscriptions(payload);
  }

  renderDLWhenDataIsReady() {
    const { isDataready } = this.state;

    if (this.props.members.csvData !== null) {
      return <CSVDownload data={this.props.members.csvData} target="_parent" />;
    } else {
      return null;
    }
  }

  renderSearch(options) {
    const { searchBy, selectedOption, subStatus, fieldnames } = this.state;

    switch (searchBy) {
      case "Member":
        return (
          <div>
            <h3>Search by Member</h3>
            <form noValidate onSubmit={this.handleSearch}>
              <div className="form-row">
                <div>
                  <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    value={this.state.search}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
                <div>
                  <input className="btn btn-pp" type="submit" />
                </div>
              </div>
            </form>
            <div id="export-csv">
              <div className="form-row">
                <div className="col-1">
                  <p className="mt-2">Export</p>
                </div>
                <div className="col-10">
                  <Select
                    value={fieldnames}
                    onChange={this.handleFieldNamesSelectChange}
                    options={[
                      { label: "First Name", value: "firstName" },
                      { label: "Last Name", value: "lastName" },
                      { label: "Address", value: "address" },
                      { label: "City", value: "city" },
                      { label: "Email", value: "email" },
                      { label: "Zip", value: "zip" },
                      { label: "State", value: "state" },
                      { label: "Expiry Date", value: "pas.expirationDate" },
                      { label: "Phone Number", value: "phone" },
                      { label: "Status", value: "status" },
                    ]}
                    isSearchable={true}
                    isClearable={true}
                    isMulti
                    placeholder="Fieldnames"
                  />
                </div>
                <div className="col-1">
                  <button className="btn btn-link" onClick={this.exportMembers}>
                    Export csv
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "Course":
        return (
          <div>
            <h3>Search by Course</h3>
            <Select
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
              isSearchable={true}
              isClearable={false}
              placeholder="Select a course"
            />
            <div id="export-csv">
              <div className="form-row">
                <div className="col-1">
                  <p className="mt-2">Export</p>
                </div>
                <div className="col-10">
                  <Select
                    value={fieldnames}
                    onChange={this.handleFieldNamesSelectChange}
                    options={[
                      { label: "First Name", value: "firstName" },
                      { label: "Last Name", value: "lastName" },
                      { label: "Address", value: "address" },
                      { label: "City", value: "city" },
                      { label: "Email", value: "email" }
                    ]}
                    isSearchable={true}
                    isClearable={true}
                    isMulti
                    placeholder="Fieldnames"
                  />
                </div>
                <div className="col-1">
                  <button className="btn btn-link" onClick={this.exportCourses}>
                    Export csv
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case "Sub":
        return (
          <div>
            <h3>Search by Subscription</h3>
            <div className="form-row">
              <div className="col">
                <Select
                  value={selectedOption}
                  onChange={this.handleSelectChange}
                  options={options}
                  isSearchable={true}
                  isClearable={false}
                  placeholder="Select a subscription"
                />
              </div>
              <div className="col">{this.renderSubStatusSelect()}</div>
            </div>
            <div id="export-csv">
              <div className="form-row">
                <div className="col-1">
                  <p className="mt-2">Export</p>
                </div>
                <div className="col-10">
                  <Select
                    value={fieldnames}
                    onChange={this.handleFieldNamesSelectChange}
                    options={[
                      { label: "First Name", value: "firstName" },
                      { label: "Last Name", value: "lastName" },
                      { label: "Address", value: "address" },
                      { label: "City", value: "city" },
                      { label: "Email", value: "email" }
                    ]}
                    isSearchable={true}
                    isClearable={true}
                    isMulti
                    placeholder="Fieldnames"
                  />
                </div>
                <div className="col-1">
                  <button
                    className="btn btn-link"
                    onClick={this.exportSubscriptions}
                  >
                    Export csv
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
        break;
    }
  }

  render() {
    let total = "loading..";
    let numOfPages = "loading..";
    let members = [];
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isAdd = privileges && isVisible(privileges, "members", "create")
    const isShow = privileges && isVisible(privileges, "members")
    if(!isShow){
      return <div className="p-5"></div>
    }
    if (this.props.members.members !== null) {
      total = this.props.members.members.total;
      numOfPages = this.props.members.members.pages;
      members = this.props.members.members.docs;
    }

    const { courses } = this.props.courses;
    const { subscriptions } = this.props.subscriptions;
    const { searchBy } = this.state;
    let options; // options for select

    if (searchBy === "Course") {
      if (courses) {
        options = courses.map(value => {
          return { label: value.name, value: value._id };
        });
      }
    } else if (searchBy === "Sub") {
      if (subscriptions) {
        let pasOptions = { label: "Planning and Scanning", value: "PAS" };
        options = subscriptions.map(value => {
          return { label: value.name, value: value._id };
        });

        options.push(pasOptions);
      }
    } else {
      options = null;
    }

    const modalStyleAddLesson = {
      position: "fixed",
      zIndex: "1",
      height: "100%",
      width: "60vw",
      padding: "30px",
      right: "0",
      top: "0"
    };

    return (
      <div className="container">
        {this.renderDLWhenDataIsReady()}
        <h1>Members</h1>
        <div className="row mb-3">
          <div className="col">
            <div className="mb-2">Total Members: {total}</div>
          </div>
          <div className="col">{this.renderSearchButtons()}</div>
        </div>
        <div className="mt-4 mb-4">{this.renderSearch(options)}</div>
        {isAdd && <button onClick={
          ()=>{
            this.props.showMemberPopup()
          }
        } className="btn btn-link">Add member</button>}
        <Popup
          // trigger={<button className="btn btn-link">Add member</button>}
          position="right center"
          modal={true}
          open={this.props.popup.isMemberPopup}
          closeOnDocumentClick
          onClose={this.props.hideMemberPopup}
          contentStyle={modalStyleAddLesson}
          arrow={false}
          closeOnEscape={true}
        >
          <div className="modal-container">
            <AddMember />
          </div>
        </Popup>

        <div>
          {this.props.members.loading === false ? (
            <MemberList members={members} />
          ) : (
            <div className="text-center">
              <img src={require("../Orders/loading.gif")} alt="" />
            </div>
          )}
        </div>
        <div className="d-flex flex-row flex-wrap">
          {this.renderPages(numOfPages)}
        </div>
      </div>
    );
  }
}

Members.propTypes = {
  auth: PropTypes.object.isRequired,
  members: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  members: state.members,
  courses: state.courses,
  subscriptions: state.subscriptions,
  popup: state.popup
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getMembers,
      getCourses,
      getSubs,
      getMembersBySub,
      getMembersByCourse,
      memberLoading,
      getCSVMembers,
      getCSVCourses,
      getCSVSubscriptions,
      clearCSVData,
      clearMembers,
      showMemberPopup,
      hideMemberPopup
    }
  )(Members)
);
