import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getCourses } from '../../actions/courseActions'
import Link from 'react-router-dom/Link'
import moment from 'moment'

class CourseList extends Component {
  state = {
    page: 0,
    size: 10,
  }
  componentDidMount() {
    this.props.getCourses()
  }

  renderPages(numOfPages) {
    console.log({ numOfPages })
    let pages = []
    for (let i = 1; i <= numOfPages; i++) {
      pages.push(
        <div
          key={i}
          className='pageCounter mr-2'
          onClick={() => this.setState({ page: i - 1 })}
        >
          {i}
        </div>
      )
    }
    return pages
  }

  render() {
    let courses
    let current
    if (this.props.courses.courses) {
      courses = this.props.courses.courses
      current = this.props.courses.courses
        //.reverse()
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(
          this.state.page * this.state.size,
          (this.state.page + 1) * this.state.size
        )
        .map((course, index) => {
          return (
            <li
              key={course._id}
              className='list-group-item list-group-item-action'
            >
              <div className='row'>
                <div className='col-1'>
                  {this.state.page * this.state.size + (1 + index)}
                </div>
                <div className='col-5'>{course.name}</div>
                <div className='col-3'>
                  {course.date &&
                    moment(course.date).format('dddd, MMMM Do YYYY')}
                </div>
                <div className='col-2 text-center'>{course.lessons.length}</div>
                <div className='col-1'>
                  <Link to={'/admin/dashboard/courses/' + course._id}>
                    Edit
                  </Link>
                </div>
              </div>
            </li>
          )
        })
    }

    return (
      <div>
        <div>
          <div className='row'>
            <div className='col-1'>#</div>
            <div className='col-5'>Name</div>
            <div className='col-3'>Created on</div>
            <div className='col-2'>Lessons</div>
            <div className='col-1'>Edit</div>
          </div>
          <ul className='list-group'>{current}</ul>
        </div>
        {courses && (
          <div className='d-flex flex-row flex-wrap'>
            {this.renderPages(Math.ceil(courses.length / 10))}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  courses: state.courses,
})

export default connect(mapStateToProps, { getCourses })(CourseList)
