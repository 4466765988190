import React, { Component } from "react";
import PropTypes from "prop-types";
// import classnames from "classnames";
import { connect } from "react-redux";
import { addMemberClearSuccess, addMemberSetSuccess } from "../../actions/formActions";
import { addRole, editPrivileges } from "../../actions/roleActions";
import { withRouter } from "react-router-dom";

class EditRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      name: "",
      published: false,
      errors: {},  
      isSuccess: false,
      privileges: [],
      id: props.role._id
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handlePrivilegesChange = this.handlePrivilegesChange.bind(this);
    this.handleAllPrivilegesChange = this.handleAllPrivilegesChange.bind(this);
  }
  componentDidMount(){
    console.log({
      Props: this.props,
    })
    const {
        privileges,
        name,
        status, 
        description,
        _id
    } = this.props.role
    console.log({
      rolestat: this.props.role
    })
    this.setState({
        privileges,
        name,
        published: status,
        description,
        id: _id
    })
  }
  componentWillReceiveProps(nextProps) {
    console.log({
      nextProps
    })
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.forms){
      if(nextProps.forms.addMember.isSuccess){
        this.setState({ 
          isSuccess: true,
          description: "",
          name: "",
          published: false,
          errors: {},
        }, ()=> {
          this.props.addMemberClearSuccess()
          setTimeout(() => {
            this.setState({
              isSuccess: false,
            })
          }, 2000);
        });
      }
    }
  }

  //Handles state changes for inputs
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  //Handles state changes for inputs
  handleCheckbox(e) {
    const {
      name
    } = e.target
    this.setState(prevState=> ({ [name]: !prevState[name] }));
  }
  handlePrivilegesChange(e){
    const {
      privileges
    } = this.state
    const {
      name,
      attributes
    } = e.target
    const value = attributes["data-change"].value
    const newPrivileges = {
      ...privileges,
      [name]: {
        ...privileges[name],
        [value]: !privileges[name][value]
      }
    }
    this.setState({
      privileges: {
        ...newPrivileges
      }
    });
  }
  handleAllPrivilegesChange(e){
    const {
      privileges
    } = this.state
    const {
      name,
      attributes
    } = e.target
    const newFlag = Object.values(privileges[name]).every(el => el)

    let filter = privileges[name];
    Object.keys(filter).forEach(v => {
      if(newFlag)
      filter[v] = false
      else
      filter[v] = true
    })
    const newPrivileges = {
      ...privileges,
      [name]: {
        ...filter,
      }
    }
    this.setState({
      privileges: newPrivileges
    });
  }
  //Sends login credentials/payload to the backend
  handleSubmit(e) {
    e.preventDefault();
    const {
      id,
      privileges ,
      published
    } = this.state
    const newPrivileges = {
      privileges,
      status: published
    };

    this.props.editPrivileges(id, newPrivileges);
  }
  isPrivileges(obj, name){
    return Object.keys(obj).some(el => el === name)
  }
  isAllPrivileges(obj){
    return Object.values(obj).every(el => el)
  }
  render() {
    const { errors, privileges } = this.state;
    return (
      <div className="wrapper-alt">
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-11 m-auto">
                <h1 className="display-4 text-center">Edit Privileges</h1>

                <form noValidate onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label>
                      Name
                    </label>
                    <p>
                      {this.state.name}
                    </p>
                  </div>
                  <div className="form-group">
                    <label>
                      Description
                    </label>
                    <p>
                      {this.state.description}
                    </p>
                  </div>
                  <div>
                    <label className="form-check-inline" >
                      <input 
                        type="checkbox"
                        name="published"
                        className="form-check-inline"
                        checked={this.state.published}
                        onChange={this.handleCheckbox}
                        // readOnly
                      />
                      Published
                    </label>

                  </div>
                  <hr />
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-3">
                                    Module
                                </div>
                                <div className="col-4">
                                </div>
                                <div className="col-1">
                                    View
                                </div>
                                <div className="col-1">
                                    Add
                                </div>
                                <div className="col-1">
                                    Edit
                                </div>
                                <div className="col-1">
                                    Delete
                                </div>
                                <div className="col-1">
                                    All
                                </div>
                            </div>
                        </div>
                        <ul className="list-group">
                            {
                            Object.keys(privileges).map((el, index)=> (
                                <li className="list-group-item list-group-item-action" key={index}>
                                    <div className="row">
                                        <div className="col-4 text-capitalize">
                                            {el}
                                        </div>
                                        <div className="col-3">
                                        </div>
                                        <div className="col-1">
                                            {
                                                this.isPrivileges(privileges[el], "view")
                                                && 
                                                <input 
                                                    type="checkbox"
                                                    checked={privileges[el].view}
                                                    name={el}
                                                    data-change="view"
                                                    onChange={this.handlePrivilegesChange}
                                                />
                                            }
                                        </div>
                                        <div className="col-1">
                                            {
                                                this.isPrivileges(privileges[el], "create")
                                                && 
                                                <input type="checkbox"
                                                    checked={privileges[el].create}
                                                    name={el}
                                                    data-change="create"
                                                    onChange={this.handlePrivilegesChange}
                                                />
                                            }
                                        </div>
                                        <div className="col-1"> 
                                            {
                                                this.isPrivileges(privileges[el], "edit")
                                                &&
                                                <input type="checkbox"
                                                    checked={privileges[el].edit}
                                                    name={el}
                                                    data-change="edit"
                                                    onChange={this.handlePrivilegesChange}
 />
                                            }
                                        </div>
                                        <div className="col-1">
                                            {
                                                this.isPrivileges(privileges[el], "delete")
                                                &&
                                                <input type="checkbox"
                                                    checked={privileges[el].delete}
                                                    name={el}
                                                    data-change="delete"
                                                    onChange={this.handlePrivilegesChange}
                                                />
                                            }
                                        </div>
                                        <div className="col-1">
                                            {
                                                <input type="checkbox"
                                                checked={this.isAllPrivileges(privileges[el])}
                                                name={el}
                                                data-change="all"
                                                onChange={this.handleAllPrivilegesChange}
                                                />
                                            }
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                  {this.state.isSuccess && 
                    <div className="alert alert-success">Member Successfully Created </div>
                  }
                  <input type="submit" className="btn btn-pp btn-block mt-4" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditRole.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  role: PropTypes.object.isRequired,
  editPrivileges: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  forms: state.forms,
  roles: state.roles,
});

export default connect(
  mapStateToProps,
  { addMemberClearSuccess, 
    addMemberSetSuccess, 
    addRole,
    editPrivileges
  }
)(withRouter(EditRole));
