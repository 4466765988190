import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getLatestPost,
  clearLatestPost,
  getPAS
} from "../../actions/pasActions";
import MediaElement from "./MediaElement";
import moment from "moment";
import UserNav from "./UserNav";
import isEmpty from "../../validation/is-empty";
import { Redirect } from "react-router";
import axios from "axios";

class PlanningAndScanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      errors: null,
      tradingRoomLink: null
    };
    this.loadPage = this.loadPage.bind(this);
    this.getTradingRoomLink = this.getTradingRoomLink.bind(this);
    this.goToTradingRoom = this.goToTradingRoom.bind(this);
  }

  componentDidMount() {
    this.props.getLatestPost();
    const user = "archive";
    this.props.getPAS(this.state.page, user);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

    if (nextProps.pas.latestPost) {
      this.setState({
        title: nextProps.pas.latestPost.title,
        video: nextProps.pas.latestPost.video.value,
        datePublished: nextProps.pas.latestPost.datePublished,
        documents: nextProps.pas.latestPost.documents
      });
    }
  }

  componentWillUnmount() {
    this.props.clearLatestPost();
  }

  loadPage(page) {
    const user = "archive";

    this.props.getPAS(page, user);
  }

  renderPages(numOfPages) {
    let pages = [];
    for (let i = 1; i <= numOfPages; i++) {
      pages.push(
        <div key={i} className="pageCounter" onClick={() => this.loadPage(i)}>
          <span className="mr-2" key={i}>
            {i}
          </span>
        </div>
      );
    }
    return pages;
  }

  loadVideoFromArchive(title, video, datePublished, documents) {
    this.setState({
      title: title,
      video: video.value,
      datePublished: datePublished,
      documents: documents
    });
  }

  getTradingRoomLink() {
    axios
      .get("/api/users/getTradingRoomLink")
      .then(res => {
        this.setState({
          tradingRoomLink: res.data
        });
      })
      .catch(err => {
        console.log("Error getting trading room link");
      });
  }

  goToTradingRoom() {
    if (this.state.tradingRoomLink !== null) {
      // window.open(this.state.tradingRoomLink, "" ,`width=${window.outerWidth},height=${window.outerHeight}`);
      // window.open(this.state.tradingRoomLink, "_blank");
      window.location.href = this.state.tradingRoomLink
    }
  }

  render() {
    const { title, documents, datePublished, video } = this.state;
    // let title = "loading...";
    // let documents = [];
    // let datePublished = null;
    // let video = null;
    const { errors } = this.state;
    let blahdocuments;

    // if (this.props.pas.latestPost) {
    //   title = this.props.pas.latestPost.title;
    //   video = this.props.pas.latestPost.video.value;
    //   datePublished = this.props.pas.latestPost.datePublished;
    //   documents = this.props.pas.latestPost.documents;
    // }

    if (documents) {
      blahdocuments = documents.map(val => {
        return (
          <li className="list-item" key={val._id}>
            <a href={val.value} target="_blank">
              {val.label}
            </a>
          </li>
        );
      });
    }

    const sources = [{ src: video, type: "video/mp4" }],
      config = { mode: "shim" },
      tracks = {};

    let total = "loading..";
    let numOfPages = "loading..";
    let posts = [];

    if (this.props.pas.pas) {
      total = this.props.pas.pas.total;
      numOfPages = this.props.pas.pas.pages;
      posts = this.props.pas.pas.docs;
    }

    const renderPosts = posts.map(post => {
      return (
        <li key={post._id} className="list-group-item list-group-item-action">
          <div className="row">
            <div className="col-8">{post.title}</div>
            <div className="col-3">
              {moment(post.datePublished).format("dddd, MMMM Do YYYY")}
            </div>
            <div className="col-1">
              <button
                onClick={() => {
                  this.loadVideoFromArchive(
                    post.title,
                    post.video,
                    post.datePublished,
                    post.documents
                  );
                  window.scrollTo(0, 0);
                }}
                className="btn btn-pp"
              >
                View
              </button>
            </div>
          </div>
        </li>
      );
    });

    return (
      <div>
        <UserNav />
        {this.goToTradingRoom()}
        <div className="wrapper-alt">
          <div className="container">
            <h1>Planning and Scanning</h1>

            {isEmpty(errors) === false ? (
              <span>{errors.message}</span>
            ) : (
              <div>
                <div className="d-flex justify-content-between align-items-middle mt-3">
                  <div>
                    <h2>{title}</h2>
                    <button
                      onClick={this.getTradingRoomLink}
                      className="btn btn-pp"
                    >
                      Trading room
                    </button>
                    <div>
                      <small className="text-muted">
                        Please make sure to enable popups when accessing the
                        trading room.
                      </small>
                    </div>
                  </div>
                  <div>
                    <h3>
                      {moment(datePublished).format("dddd, MMMM Do YYYY")}
                    </h3>
                  </div>
                </div>

                <div className="mt-3 mb-5">
                  <div
                    onContextMenu={e => {
                      e.preventDefault();
                      return false;
                    }}
                  >
                    <MediaElement
                      id="player1"
                      mediaType="video"
                      preload="none"
                      controls
                      width="100%"
                      height=""
                      poster=""
                      sources={JSON.stringify(sources)}
                      options={JSON.stringify(config)}
                      tracks={JSON.stringify(tracks)}
                    />
                  </div>
                </div>

                <div>
                  <h4>Attachment</h4>
                  <ul className="list-item-group">{blahdocuments}</ul>
                </div>

                <hr />
                <div className="p-5 mt-5">
                  <h2>Archived Videos</h2>
                  <ul className="list-group">{renderPosts}</ul>
                  <div className="d-flex flex-row flex-wrap">
                    {this.renderPages(numOfPages)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  pas: state.pas
});

export default connect(
  mapStateToProps,
  { getLatestPost, clearLatestPost, getPAS }
)(PlanningAndScanning);
