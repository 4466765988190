import axios from 'axios'
import {
  GET_ERRORS,
  GET_NEWSLETTERS,
  GET_NEWSLETTER,
  CLEAR_NEWSLETTER,
  GET_CURRENT_NEWSLETTER,
  EDIT_NEWSLETTER,
  CLEAR_COURSE,
  SET_NEWSLETTER_LOADING,
} from './types'

// Get newsletters
export const getNewsletters = () => (dispatch) => {
  dispatch({
    type: SET_NEWSLETTER_LOADING
  });
  axios
    .get('/api/admin/getNewsletters')
    .then((res) => {
      dispatch({
        type: GET_NEWSLETTERS,
        payload: res.data,
      })
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      })
    })
}

// Create newsletter
export const createNewsletter = (newsletter) => (dispatch) => {
  axios
    .post('/api/admin/createNewsletter', newsletter)
    .then((res) => {
      dispatch({
        type: GET_NEWSLETTER,
        payload: res.data,
      })
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      })
    })
}

export const AddAsset = (payload) => (dispatch) => {
  axios
    .post('/api/admin/assets', payload)
    .then((res) => {
      console.log({ res })
      dispatch({
        type: 'GET ASSETS',
        payload: res.data,
      })
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      })
    })
}

export const GetAsset = (payload) => (dispatch) => {
  axios
    .get('/api/admin/assets', payload)
    .then((res) => {
      console.log({ res })
      dispatch({
        type: 'GET ASSETS',
        payload: res.data,
      })
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      })
    })
}

export const DeleteAsset = (payload) => (dispatch) => {
  axios
    .put('/api/admin/assets', payload)
    .then((res) => { 
      console.log({ delete: res , payload})
      dispatch(GetAsset())
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      })
    })
}

//Get newsletter
export const getCurrentNewsletter = (id) => (dispatch) => {
  axios
    .get(`/api/admin/editemail/${id}`)
    .then((res) => {
      console.log({
        data: res.data,
      })
      dispatch({
        type: GET_CURRENT_NEWSLETTER,
        payload: res.data,
      })
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      })
    })
}

// Save newsletter
export const saveNewsletter = (newsletter) => (dispatch) => {
  const payload = {
    sent: false,
    ...newsletter,
  }
  axios
    .post('/api/admin/editemail/' + newsletter._id, payload)
    .then((res) => {
      console.log({
        res,
      })
      dispatch({
        type: GET_NEWSLETTER,
        payload: res.data,
      })
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      })
    })
}

// Send newsletter
export const sendNewsletter = (id) => (dispatch) => {
  const payload = {
    newsletterID: id,
  }

  axios
    .post('/api/admin/sendNewsletter', payload)
    .then((res) => {
      dispatch(getNewsletters())
    })
    .catch((error) => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data,
      })
    })
}

// Clear newsletter
export const clearNewsletter = () => (dispatch) => {
  dispatch({
    type: CLEAR_NEWSLETTER,
  })
}

export const newsletterLoading = () => dispatch => {
  dispatch({
    type: SET_NEWSLETTER_LOADING
  });
};




// //Sbr
// export const clearInterval{
//   clearImmediate = () => dispatch => {
//     disoatch({
//       type: CLEAR_NEWSLETTER,
//       then: CLEAR_COURSE
//     })
//   }
// }
// export const clearInterval{
//   clearImmediate = () => dispatch => {
//     disoatch({
//       type: CLEAR_NEWSLETTER,

//     })
//   }
// }
