import React, { Component } from "react";
import { connect } from "react-redux";
import {} from "../../actions/courseActions";
import Link from "react-router-dom/Link";
import PropTypes from "prop-types";
import Lesson from "./Lesson";

class LessonList extends Component {
  render() {
    let courses;
    if (this.props.lessons) {
      courses = this.props.lessons.map((lesson, index) => {
        return (
          <li
            key={lesson._id}
            className="list-group-item list-group-item-action"
          >
            <Lesson lesson={lesson} index={index} />
          </li>
        );
      });
    }

    return (
      <div>
        <div>
          <div className="row">
            <div className="col-1">#</div>
            <div className="col-8">Name</div>
            <div className="col-2">Questions</div>
            <div className="col-1">Edit</div>
          </div>
          <ul className="list-group">{courses}</ul>
        </div>
      </div>
    );
  }
}

LessonList.propTypes = {
  lessons: PropTypes.array
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {}
)(LessonList);
