import React from "react";
import Link from "react-router-dom/Link";

export default () => {
  return (
    <div className="">
      <footer className="text-white p-4 text-center ">
        <div className="text-center">
          Copyright &copy; {new Date().getFullYear()} PersonsPlanet
        </div>
      </footer>
    </div>
  );
};
