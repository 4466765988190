import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fileUpload, clearFile } from '../../actions/courseActions'

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      file: null,
    }
    this.handleImgChange = this.handleImgChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleImgChange(e) {
    this.setState({
      file: e.target.files[0],
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.state.file) {
      const payload = new FormData()
      payload.append('document', this.state.file)

      console.log({ file: this.state.file })

      this.props.cb && this.props.cb(this.state.file)

      this.props.fileUpload(payload)
    }
  }

  componentWillUnmount() {
    this.props.clearFile()
  }

  renderButton() {
    const { another } = this.props

    if (this.props.courses.file === null) {
      return (
        <button className="btn btn-link" onClick={this.handleSubmit}>
          Upload
        </button>
      )
    } else {
      return <span className="text-success">Image Uploaded</span>
    }
  }

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div>
          <input type="file" onChange={this.handleImgChange} />
        </div>
        <div>{this.renderButton()}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  courses: state.courses,
})

export default connect(mapStateToProps, { fileUpload, clearFile })(FileUpload)
