import axios from "axios";
import {
  GET_ERRORS,
  GET_PAS,
  GET_LATEST_POST,
  CLEAR_LATEST_POST
} from "./types";
import { getUser } from "./memberActions";

// Get planning and scanning
export const getPAS = page => dispatch => {
  axios
    .get("/api/pas/", { params: { page: page } })
    .then(res => {
      dispatch({
        type: GET_PAS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Create post
export const createPAS = (payload, history) => dispatch => {
  const user = "";
  axios
    .post("/api/pas", payload)
    .then(res => {
      dispatch(getPAS(1, user));
      history.push("/admin/dashboard/pas");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Edit post
export const editPAS = payload => dispatch => {
  const user = "";
  axios
    .put("/api/pas", payload)
    .then(res => {
      dispatch(getPAS(1, user));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Delete post
export const deletePAS = id => dispatch => {
  axios
    .delete("/api/pas", {
      params: {
        id: id
      }
    })
    .then(res => {
      dispatch(getPAS(1));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Publish post
export const publishPAS = id => dispatch => {
  const payload = {
    postID: id
  };
  axios
    .post("/api/pas/togglePublished", payload)
    .then(res => {
      console.log("post published");
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// USER PLANNING AND SCANNING ACTIONS

// Subscribe a user to PAS
export const subToPAS = (userID, date) => dispatch => {
  const payload = {
    userID: userID,
    date: date
  };

  axios
    .post("/api/pas/subscribeUser", payload)
    .then(res => {
      dispatch(getUser(userID));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Get latest post
export const getLatestPost = () => dispatch => {
  axios
    .get("/api/pas/getLatest")
    .then(res => {
      dispatch({
        type: GET_LATEST_POST,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// CLEAR LATEST POST
export const clearLatestPost = () => dispatch => {
  dispatch({
    type: CLEAR_LATEST_POST
  });
};
