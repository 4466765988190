import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { subToPAS } from "../../actions/pasActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import ToggleButton from "react-toggle-button";

class SubToPas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      isActive: false,
      errors: ""
    };
    this.handleCalendar = this.handleCalendar.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.pas) {
      this.setState({
        isActive: this.props.pas.active,
        date: moment(this.props.pas.expirationDate)
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

    // if (nextProps.member.pas && nextProps.member.pas.expirationDate !== null) {
    //   this.setState({
    //     isActive: nextProps.member.pas.active,
    //     date: moment(nextProps.member.pas.expirationDate)
    //   });
    // }
  }

  handleCalendar(date) {
    this.setState({
      date: date
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.subToPAS(this.props.member._id, this.state.date._d);

    console.log(this.state.date._d);
  }

  render() {
    const { isActive, date } = this.state;
    let activatedOn = null;
    let subscribe;

    if (isActive === true) {
      if (this.props.member.pas) {
        activatedOn = moment(this.props.member.pas.applyDate).format(
          "MM/DD/YYYY"
        );
      }

      subscribe = (
        <div>
          <div>
            Activated on <b>{activatedOn}</b>
          </div>
          <div className="d-flex align-items-center mt-3">
            <span> Expires on: </span>
            <DatePicker
              selected={this.state.date}
              onChange={this.handleCalendar}
              dateFormat="MM/DD/YYYY"
              className="form-control"
            />
            <button onClick={this.handleSubmit} className="btn btn-link">
              Save
            </button>
          </div>
        </div>
      );
    } else {
      subscribe = (
        <div>Expired on: {moment(date).format("dddd, MMMM Do YYYY")}</div>
      );
    }

    return (
      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h3>Planning and Scanning</h3>
          </div>

          <div>
            <ToggleButton
              value={this.state.isActive}
              inactiveLabel="OFF"
              activeLabel="ON"
              onToggle={isActive => {
                this.setState({
                  isActive: !isActive
                });
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-column">{subscribe}</div>
      </div>
    );
  }
}

SubToPas.propTypes = {
  userID: PropTypes.string.isRequired,
  pas: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  member: state.members.member,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { subToPAS }
)(SubToPas);
