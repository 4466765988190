import {
  GET_NEWSLETTERS,
  GET_NEWSLETTER,
  CLEAR_NEWSLETTER,
  GET_CURRENT_NEWSLETTER,
  SET_NEWSLETTER_LOADING
} from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  newsletters: null,
  currentNewsletter: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_NEWSLETTERS:
      return {
        ...state,
        loading:false,
        newsletters: action.payload
      };
    case GET_NEWSLETTER:
      return {
        ...state,
        newsletter: action.payload
      };

    case GET_CURRENT_NEWSLETTER:
      return {
        ...state,
        currentNewsletter: action.payload
      };

    case CLEAR_NEWSLETTER:
      return {
        ...state,
        newsletter: null,
        currentNewsletter: null
      };

      case SET_NEWSLETTER_LOADING:
        return {
          ...state,
          loading: true
        };

    default:
      return state;
  }
}
