import React, { Component } from "react";
import { Link } from "react-router-dom";
import Menu from "../common/Menu";
import { connect } from "react-redux";
import { getNewsletters } from "../../actions/newsletterActions";
import NewsletterList from "./NewsletterList";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class Newsletters extends Component {
  componentDidMount() {
    this.props.getNewsletters();
  }

  render() {
    const {
      auth
    } = this.props

    console.log(this.props.newsletters.loading)
    const privileges = getPrivileges(auth);
    const isShow = privileges && isVisible(privileges, "emails")
    const isAdd = privileges && isVisible(privileges, "emails", "create")

    let newsletters = [];
    if (this.props.newsletters.newsletters !== null) {
      newsletters = this.props.newsletters.newsletters;
    }
    if (!isShow) {
      return <div className="p-5" />
    }
    return (
      <div className="wrapper-alt">
        <Menu />
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Newsletters</h1>
            </div>
            <div>
              {isAdd && <Link to="/admin/dashboard/newsletters/create">New Email</Link>}
            </div>
          </div>
          <div>
            {this.props.newsletters.loading===true ? (<div className="text-center">
              <img src={require("../Orders/loading.gif")} alt="" />
            </div>) : (
              <NewsletterList newsletters={newsletters} />
            )}

          </div>
        </div>
      </div>
    );
  }
}

Newsletters.propTypes = {};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  newsletters: state.newsletters
});

export default connect(
  mapStateToProps,
  { getNewsletters }
)(Newsletters);
