import {
  GET_COURSES,
  CREATE_COURSE,
  GET_COURSE,
  EDIT_LESSON,
  GET_USER_COURSES,
  CLEAR_FILE,
  CLEAR_COURSE,
  UPLOAD_FILE,
} from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  courses: null,
  file: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_COURSES:
      return {
        ...state,
        courses: action.payload
      };
    case GET_COURSE:
      return {
        ...state,
        course: action.payload
      };
    case GET_USER_COURSES:
      return {
        ...state,
        userCourses: action.payload
      };
    case CREATE_COURSE:
      return {
        ...state,
        course: action.payload
      };
    case UPLOAD_FILE:
      return {
        ...state,
        file: action.payload
      };
    case CLEAR_FILE:
      return {
        ...state,
        file: null
      };
    case CLEAR_COURSE:
      return {
        courses: null
      };
    default:
      return state;
  }
}
