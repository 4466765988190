import React, { Component } from "react";
import SubscriptionsList from "./SubscriptionsList";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Menu from "../common/Menu";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class SubscriptionsPar extends Component {
  render() {
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isEditable = privileges && isVisible(privileges, "subscription", "edit")
    const isShow = privileges && isVisible(privileges, "subscription")
    const isAdd = privileges && isVisible(privileges, "subscription", "create")
    const isDeletable = privileges && isVisible(privileges, "subscription", "delete")

    return (
      <div className="wrapper">
        <Menu />
        {!isShow && <div className="p-5" />}
        {isShow && <div className="container">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <div>
              <h1>Subscriptions & Services</h1>
            </div>

            <div>
              {isAdd && <Link
                to="/admin/dashboard/subscriptions/create"
                className="btn btn-pp"
              >
                Create a new subscription
              </Link>}
            </div>
          </div>

          <div>
            <SubscriptionsList />
          </div>
        </div>}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SubscriptionsPar);
