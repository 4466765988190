import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'
import { getSubs } from '../../actions/subscriptionsActions'
import { Link } from 'react-router-dom'
import renderHTML from 'react-render-html'
import moment from 'moment'

class SubscriptionsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      size: 10,
    }

    this.loadPage = this.loadPage.bind(this)
  }

  componentDidMount() {
    this.props.getSubs(this.state.page)
  }

  loadPage(page) {
    this.props.getSubs(page)
  }

  renderPages(numOfPages) {
    console.log({ numOfPages })
    let pages = []
    for (let i = 1; i <= numOfPages; i++) {
      pages.push(
        <div
          key={i}
          className='pageCounter mr-2'
          onClick={() => this.setState({ page: i - 1 })}
        >
          {i}
        </div>
      )
    }
    return pages
  }

  render() {
    let total = 'loading..'
    let numOfPages = 'loading..'
    let subscriptions = []

    console.log(subscriptions)

    if (this.props.subscriptions.subscriptions) {
      // total = this.props.subscriptions.subscriptions.total
      // numOfPages = this.props.subscriptions.subscriptions.pages
      subscriptions = this.props.subscriptions.subscriptions
    }

    console.log(subscriptions)

    const renderSubscriptions = subscriptions
      //.reverse()
      .slice(
        this.state.page * this.state.size,
        (this.state.page + 1) * this.state.size
      )
      .map((sub) => {
        return (
          <li key={sub._id} className='list-group-item list-group-item-action'>
            <div className='row'>
              <div className='col-1'>
                <Link
                  className='btn-link'
                  to={'/admin/dashboard/subscriptions/' + sub._id}
                >
                  Edit
                </Link>
              </div>
              <div className='col-7'>{sub.name}</div>
              <div className='col-3'>
                {sub.date && moment(sub.date).format('dddd, MMMM Do YYYY')}
              </div>
              <div className='col-1 text-right'>{sub.posts.length}</div>
            </div>
          </li>
        )
      })

    return (
      <div>
        <div className='row'>
          <div className='col-1'>Edit</div>
          <div className='col-7'>Name</div>
          <div className='col-3'>Date</div>
          <div className='col-1'>Posts</div>
        </div>
        <ul className='list-group'>{renderSubscriptions}</ul>
        {subscriptions && (
          <div className='d-flex flex-row flex-wrap'>
            {this.renderPages(Math.ceil(subscriptions.length / 10))}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  subscriptions: state.subscriptions,
})

export default connect(mapStateToProps, { getSubs })(SubscriptionsList)
