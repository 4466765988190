import React, { Component } from "react";
import UserNav from "./UserNav";
import { connect } from "react-redux";
import isEmpty from "../../validation/is-empty";

class UserDownloads extends Component {
  render() {
    let downloads;

    if (isEmpty(this.props.auth.user.downloads) === true) {
      downloads = "You don't have anything to download.";
    } else {
      downloads = this.props.auth.user.downloads.map(dl => {
        return (
          <li className="list-group-item">
            <div className="col-8">{dl.label}</div>
            <div className="col-4">
              <a href={dl.value} target="_blank">
                Download
              </a>
            </div>
          </li>
        );
      });
    }

    return (
      <div>
        <UserNav />
        <div className="wrapper-alt">
          <div className="container">
            <h1>Downloads</h1>
            <ul className="list-group">{downloads}</ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {}
)(UserDownloads);
