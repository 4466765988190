import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import classnames from "classnames";
import { connect } from "react-redux";
import {ImageResize} from 'quill-image-resize-module';
import { getCourses, clearFile } from "../../actions/courseActions";
import { getSubs } from "../../actions/subscriptionsActions";
import { 
    getCurrentNewsletter,
    saveNewsletter,
    clearNewsletter,
    createNewsletter
} from "../../actions/newsletterActions";
import Select from "react-select";
import FileUpload from "../common/FileUpload";
import Recipient from "./Recipient";
import {
  getMembers,
  getMembersBySub,
  getMembersByCourse,
  memberLoading,
  clearMembers
} from "../../actions/memberActions";
import isEmpty from "../../validation/is-empty";
import AsyncSelect from "react-select/lib/Async";
import axios from "axios";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"




class EditNewsletter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Email",
      fromName: "",
      subject: "",
      html: "",
      attachments: [],
      status: null,
      selectedOption: null,
      subStatus: "",
      recipients: [],
      defaultOptions: [],
      errors: [],
      offerHtml: "",
      isWeeklyOffer: false,
      _id: this.props.match.params.id
    };
    this.handleQuillChange = this.handleQuillChange.bind(this);
    this.handleOfferQuillChange = this.handleOfferQuillChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleButtons = this.handleButtons.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.promiseOptions = this.promiseOptions.bind(this);
    this.createNew = this.createNew.bind(this);
  }

  componentDidMount() {
    // Quill.register('modules/ImageResize', ImageResize);
    this.props.getCourses();
    this.props.getSubs();
    this.props.getCurrentNewsletter(this.props.match.params.id)
  }
  componentWillUnmount() {
    this.props.clearNewsletter();
    this.props.clearMembers();
  }
  checkNewNewsLetter(newsletter, nextNewsletter){
    if(newsletter){
      if(newsletter._id === nextNewsletter._id)
        return false
    }
    return true
  }
  componentWillReceiveProps(nextProps) {
    // let recipients = [];
    // let defaultOptions = [];
    console.log({
      nextProps, 
      props: this.props
    })
    if (nextProps.members.members) {
      const recipients = nextProps.members.members.map(val => {
        if (val.emailStatus === true) {
          return {
            label: val.firstName + " " + val.lastName,
            value: val.email
          }
        } 
        return null
      }).filter(el => el);
      const defaultOptions = nextProps.members.members.map(val => {
        if (val.emailStatus !== true) {
          return {
            label: val.firstName + " " + val.lastName,
            value: val.email
          };
        } 
        return null
      }).filter(el => el);
      
      this.setState(prevState => ({
        recipients: [...prevState.recipients, ...recipients],
        defaultOptions: [...prevState.defaultOptions, ...defaultOptions]
      }));
    }
    if(nextProps.currentNewsletter){
      const {
        subject,
        html,
        attachments,
        status,
        subStatus,
        recipients,
        offer,
        isWeeklyOffer,
        fromName,
        name
      } = nextProps.currentNewsletter
      console.log(nextProps.currentNewsletter)
      if(this.checkNewNewsLetter(this.props.currentNewsletter, nextProps.currentNewsletter)){
        this.setState({
          subject : subject || "",
          fromName : fromName || "",
          html: html || "",
          name: name || this.state.name,
          attachments: attachments || [],
          status: status || null,
          subStatus : subStatus ||null,
          recipients: recipients ? recipients.map(el => ({value: el.address, label: el.name })) || [] : [],
          offerHtml : offer || "",
          isWeeklyOffer: offer ? true : false
        })
      }
    }
    
  }
    

  handleSubmit(e) {
    e.preventDefault();

    const {
      name,
      fromName,
      subject,
      html,
      status,
      selectedOption,
      subStatus,
      attachments,
      offerHtml,
      recipients,
      isWeeklyOffer,
      _id
    } = this.state;

    const dobijaciMejla = recipients.map(val => {
      return {
        name: val.label,
        address: val.value
      };
    });
    const newsletter = {
      name: name,
      fromName: fromName,
      subject: subject,
      html:html,
      offer:offerHtml,
      attachments: attachments,
      recipients: dobijaciMejla,
      _id
    };

    this.props.saveNewsletter(newsletter);
  }
  createNew(e) {
    e.preventDefault();

    const {
      name,
      fromName,
      subject,
      html,
      status,
      selectedOption,
      subStatus,
      attachments,
      offerHtml,
      recipients,
      isWeeklyOffer,
    } = this.state;

    const dobijaciMejla = recipients.map(val => {
      return {
        name: val.label,
        address: val.value
      };
    });
    const newsletter = {
      name: name,
      fromName: fromName,
      subject: subject,
      html:html,
      offer:offerHtml,
      attachments: attachments,
      recipients: dobijaciMejla,
    };

    this.props.createNewsletter(newsletter);
  }

  handleQuillChange(value) {
    this.setState({ html: value });
  }

  handleOfferQuillChange(value) {
    this.setState({ offerHtml: value });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckBoxChange(e) {
    this.setState({ [e.target.name]: e.target.checked });
  }

  handleButtons(e) {
    this.setState({
      name: e.target.name,
      selectedOption: null,
      subStatus: null
    });
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleSubSelectChange = subStatus => {
    this.setState({ subStatus });
  };

  handleAsyncSelectChange = recipients => {
    this.setState({ recipients });
  };

  renderSubStatusSelect() {
    const { selectedOption, subStatus } = this.state;

    if (selectedOption === null) {
      return null;
    } else {
      return (
        <Select
          value={subStatus}
          onChange={this.handleSubSelectChange}
          options={[
            { label: "Active", value: true },
            { label: "Expired", value: false },
            { label: "All", value: "" }
          ]}
          placeholder="Status"
        />
      );
    }
  }

  renderSelects(options) {
    const { name, selectedOption } = this.state;

    if (name === "Course") {
      return (
        <div>
          <Select
            value={selectedOption}
            onChange={this.handleSelectChange}
            options={options}
            isSearchable={true}
            isClearable={false}
            placeholder="Select a course"
          />
        </div>
      );
    } else if (name === "Sub") {
      return (
        <div className="form-row">
          <div className="col">
            <Select
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
              isSearchable={true}
              isClearable={false}
              placeholder="Select a subscription"
            />
          </div>
          <div className="col">{this.renderSubStatusSelect()}</div>
        </div>
      );
    }
  }

  handleAddFile(file, stateName = "attachments") {
    // const { attachments } = this.state;

    // let arej = attachments;
    let arej = this.state[stateName];

    arej.push(file);

    this.setState({
      [stateName]: arej
    });
    if(stateName !== "attachments"){
      this.props.clearFile2();
    }else{
      this.props.clearFile();
    }
  }

  renderFileUpload(stateName = "attachments") {
    const file = this.props.file;

    return (
      <div className="form-row">
        <div className="row">
          <div className="col-10">
            <FileUpload />
          </div>
          <div className="col-2">
            <button
              onClick={() => this.handleAddFile(file, stateName)}
              className="btn btn-link"
            >     
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }

  handleSearch() {
    const { name, selectedOption, subStatus } = this.state;
    if (name === "Course") {
      const payload = {
        returnAll: true,
        courseID: selectedOption.value
      };
      this.props.getMembersByCourse(payload);
    }

    if (name === "Sub") {
      const payload = {
        returnAll: true,
        subscriptionID: this.state.selectedOption.value,
        active: subStatus.value
      };
      this.props.getMembersBySub(payload);
    }
  }

  renderDownloads(stateName = "attachments") {
    // const { attachments } = this.state;
    const attachments = this.state[stateName];

    if (isEmpty(attachments) === true) {
      return <div>No attachments.</div>;
    } else {
      const renderDls = attachments.map((dl, index) => {
        let file = "";
        if(dl){
          if(typeof dl === "object")
            file = dl.file
          else if (typeof dl === "string")
            file = dl
        }
        return (
          <li key={index} className="list-group-item">
            <div className="row">
              <div className="col-6">{index + 1}</div>
              <div className="col-5">
                <a href={file} target="_blank">
                  view
                </a>
              </div>
              <div className="col-1">
                <i
                  onClick={() => this.deleteDl(index, stateName)}
                  className="fas fa-trash-alt"
                />
              </div>
            </div>
          </li>
        );
      });
      return renderDls;
    }
  }

  deleteDl(index, stateName = "attachments") {
    let arej = this.state[stateName];
    arej.splice(index, 1);
    this.setState({
      [stateName]: arej
    });
  }

  promiseOptions = inputValue =>
    new Promise((resolve, reject) => {
      const payload = {
        search: inputValue,
        returnAll: true
      };
      if (inputValue.length >= 3) {
        axios
          .post("/api/admin/listUsers", payload)
          .then(response => {
            resolve(
              response.data.map(user => {
                return {
                  label: user.firstName + " " + user.lastName,
                  value: user.email
                };
              })
            );
          })
          .catch(error => {
            console.log(error.response.data);
            reject(error);
          });
      } else {
        resolve([]);
      }
    });

  render() {
    const {
      fromName,
      subject,
      html,
      name,
      selectedOption,
      status,
      recipients,
      defaultOptions,
      errors,
      isWeeklyOffer,
      offerHtml
    } = this.state;

    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isShow = privileges && isVisible(privileges, "emails")
    const isEditable = privileges && isVisible(privileges, "emails", "edit")
    const isAdd = privileges && isVisible(privileges, "emails", "create")
    const isDeletable = privileges && isVisible(privileges, "emails", "delete")

    if(!isEditable){
      return <div className="p-5" />
    }
    Quill.register('modules/ImageResize', ImageResize);
    let modules = {
      ImageResize: {
        displaySize: true,
      },
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        // [{ direction: "rtl" }], // text direction

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        ["link", "image"]
      ]
    };

    const { courses } = this.props.courses;
    const { subscriptions } = this.props.subscriptions;
    let options; // options for select

    if (name === "Course") {
      if (courses) {
        options = courses.map(value => {
          return { label: value.name, value: value._id };
        });
      }
    } else if (name === "Sub") {
      if (subscriptions) {
        let pasOptions = { label: "Planning and Scanning", value: "PAS" };
        options = subscriptions.map(value => {
          return { label: value.name, value: value._id };
        });

        options.push(pasOptions);
      }
    } else {
      options = null;
    }

    if (this.props.newsletters.newsletter) {
      
      this.props.history.push("/admin/dashboard/newsletters/");
    }
    return (
      <div className="wrapper-alt">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Edit email</h1>
            </div>
            <div>
              <button onClick={this.handleSubmit} className="btn btn-pp">
                Save
              </button>
              <span className="pl-2" />
              {isAdd && <button onClick={this.createNew} className="btn btn-pp">
                Save As New
              </button>}
            </div>
          </div>

          <div id="newsletter-form" className="mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">From Name</span>
              </div>

              <input
                type="text"
                className={classnames("form-control", {
                  "is-invalid": errors.fromName
                })}
                placeholder="From Name"
                name="fromName"
                value={this.state.fromName}
                onChange={this.handleChange}
              />
              {errors.fromName && (
                <div className="invalid-feedback">{errors.fromName}</div>
              )}
            </div>

            <div className="input-group mt-2">
              <div className="input-group-prepend">
                <span className="input-group-text">Subject</span>
              </div>

              <input
                type="text"
                className={classnames("form-control", {
                  "is-invalid": errors.subject
                })}
                placeholder="Subject"
                name="subject"
                value={this.state.subject}
                onChange={this.handleChange}
              />
              {errors.subject && (
                <div className="invalid-feedback">{errors.subject}</div>
              )}
            </div>

            <div className="form-group mt-2">
              <ReactQuill
                value={html}
                onChange={this.handleQuillChange}
                modules={modules}
              />
            </div>
          </div>

          <div className="mt-3">
            <div className="form-group form-check">
              <label className="form-check-label" htmlFor="isWeeklyOffer" >
                <input className="form-check-input" 
                onChange={this.handleCheckBoxChange} 
                id="isWeeklyOffer" 
                name="isWeeklyOffer" 
                checked={isWeeklyOffer}
                type="checkbox" /> Weekly Offer
              </label>
            </div>
            {isWeeklyOffer &&
            <div className="form-group mt-2">
              <ReactQuill
                value={offerHtml}
                onChange={this.handleOfferQuillChange}
                modules={modules}
              />
            </div>}
          </div>
          
          <div>{this.renderFileUpload()}</div>

          <ul className="list-group">{this.renderDownloads()}</ul>

          <div className="mt-3">
            <h2>Select a Group</h2>
            <div className="d-flex">
              <button
                onClick={this.handleButtons}
                name="Course"
                className="btn btn-link"
              >
                Course
              </button>
              <button
                onClick={this.handleButtons}
                name="Sub"
                className="btn btn-link"
              >
                Subscription
              </button>
            </div>

            <div>{this.renderSelects(options)}</div>

            {selectedOption !== null ? (
              <button onClick={this.handleSearch} className="btn btn-link">
                Add to recipients
              </button>
            ) : null}

            <div className="mt-2 mb-5">
              <h2>Recipients</h2>
              <AsyncSelect
                isMulti
                cacheOptions
                onChange={this.handleAsyncSelectChange}
                value={recipients}
                loadOptions={this.promiseOptions}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log({state})
  return ({
    auth: state.auth,
    members: state.members,
    courses: state.courses,
    subscriptions: state.subscriptions,
    newsletters: state.newsletters,
    file: state.courses.file,
    currentNewsletter: state.newsletters.currentNewsletter,
  })
};

export default connect(
  mapStateToProps,
  {
    getMembers,
    getCourses,
    getSubs,
    saveNewsletter,
    clearNewsletter,
    clearFile,
    getMembersBySub,
    getMembersByCourse,
    clearMembers,
    getCurrentNewsletter,
    createNewsletter
  }
)(EditNewsletter);
