import axios from "axios";
import {
  GET_ERRORS,
  GET_MEMBERS,
  GET_MEMBER,
  GET_CSV_DATA,
  CLEAR_CSV_DATA,
  GET_SHOPPINGCART_ORDER,
  SET_MEMBER_LOADING,
  SET_PAYLOAD,
  CLEAR_MEMBERS,
  GET_ADMINS,
  CLEAR_ERRORS
} from "./types";

// Get members
export const getMembers = payload => dispatch => {
  axios
    .post("/api/admin/listUsers", payload)
    .then(response => {
      dispatch({
        type: GET_MEMBERS,
        payload: response.data
      });
      dispatch({
        type: SET_PAYLOAD,
        payload: payload
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Get Members by Course
export const getMembersByCourse = payload => dispatch => {
  axios
    .post("/api/admin/listByCourse", payload)
    .then(response => {
      dispatch({
        type: GET_MEMBERS,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Get Members by Sub
export const getMembersBySub = payload => dispatch => {
  axios
    .post("/api/admin/listBySubscription", payload)
    .then(response => {
      dispatch({
        type: GET_MEMBERS,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Get user
export const getUser = (id, isadmin) => dispatch => {
  const payloadUser = {
    user: {
      _id: id
    }
  };
  const payloadAdmin = {
    ...payloadUser,
    isadmin
  }
  axios
    .post("/api/admin/getUser", isadmin ? payloadAdmin : payloadUser)
    .then(response => {
      dispatch({
        type: GET_MEMBER,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Edit user profile - ADMIN
export const editUserProfileAdmin = payload => dispatch => {
  axios
    .put("/api/admin/editUser", payload)
    .then(response => {
      dispatch({
        type: GET_MEMBER,
        payload: response.data
      });
      dispatch({
        type: CLEAR_ERRORS,
        payload: {}
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Subcribe a user for a course
export const subscribeForCourse = (user, course) => dispatch => {
  const payload = {
    user: {
      _id: user
    },
    course: {
      _id: course
    }
  };
  axios
    .post("/api/courses/addToUser", payload)
    .then(response => {
      dispatch({
        type: GET_MEMBER,
        payload: response.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Subscribe a user to a Sub
export const subscribeForSub = (
  userID,
  subID,
  expirationDate,
  isLifetime
) => dispatch => {
  const payload = {
    userID: userID,
    subscriptionID: subID,
    date: expirationDate,
    isLifetime: isLifetime
  };
  axios
    .post("/api/subscriptions/subscribeUser", payload)
    .then(res => {
      dispatch({
        type: GET_MEMBER,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Get CSV data members
export const getCSVMembers = payload => dispatch => {
  axios
    .post("/api/admin/exportUsers", payload)
    .then(res => {
      dispatch({
        type: GET_CSV_DATA,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Get CSV data courses
export const getCSVCourses = payload => dispatch => {
  axios
    .post("/api/admin/exportByCourse", payload)
    .then(res => {
      dispatch({
        type: GET_CSV_DATA,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Get CSV data subscriptions
export const getCSVSubscriptions = payload => dispatch => {
  axios
    .post("/api/admin/exportBySubscription", payload)
    .then(res => {
      dispatch({
        type: GET_CSV_DATA,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Clear CSV data
export const clearCSVData = () => dispatch => {
  dispatch({
    type: CLEAR_CSV_DATA
  });
};

// Unsubcribe a user from a Subscription
export const UnSubUserFromSubscription = (userId, subId) => dispatch => {
  const payload = {
    userID: userId,
    subscriptionID: subId
  };
  axios
    .post("/api/subscriptions/unsubscribeUser", payload)
    .then(res => {
      dispatch({
        type: GET_MEMBER,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Unsubscribe a user from a Course
export const UnSubUserFromCourse = (userId, courseId) => dispatch => {
  const payload = {
    userID: userId,
    courseID: courseId
  };
  axios
    .post("/api/courses/unsubscribeUser", payload)
    .then(res => {
      dispatch({
        type: GET_MEMBER,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Delete a user
export const deleteUser = (userId, history,callback = () => { }) => dispatch => {
  axios
    .delete("/api/admin/deleteUser", { params: { id: userId } })
    .then(res => {

      callback()
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    } )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const memberLoading = () => dispatch => {
  dispatch({
    type: SET_MEMBER_LOADING
  });
};

export const clearMembers = () => dispatch => {
  dispatch({
    type: CLEAR_MEMBERS
  });
};

export const getAdmins = () => dispatch => {
  axios
    .get("/api/admin")
    .then(res => {
      dispatch({
        type: GET_ADMINS,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const clearErrors = () => dispatch => {
  dispatch({
    type: CLEAR_ERRORS,
    payload: {}
  });
};
