import {
    SHOW_MEMBER_POPUP,
    HIDE_MEMBER_POPUP
} from "../actions/types";
const initialState = {
    isMemberPopup: false
}
export default function(state = initialState, action) {
    switch (action.type) {
        case SHOW_MEMBER_POPUP:
            return {...state, isMemberPopup: true}

        case HIDE_MEMBER_POPUP:
            return {...state, isMemberPopup: false}
    
        default:
            return state
    }
}