import React, { Component } from "react";
import Question from "./Question";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";
import { setQuestions, clearQuestions } from "../../actions/lessonActions";
import { connect } from "react-redux";

class Questions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      options: [],
      answer: null,
      isEdited: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.questions) {
      this.setState({
        questions: this.props.questions
      });
    }
  }

  componentWillUnmount() {
    this.props.clearQuestions();
  }

  handleSubmit(e) {
    e.preventDefault();

    const { questions } = this.state;
    this.props.setQuestions(questions);

    console.log(questions);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  saveQuestion(question, answer, options, index) {
    let newPackage = {
      question: question,
      answer: answer.value,
      options: options
    };
    let arej = this.state.questions;
    arej.splice(index, 1, newPackage);

    this.setState({ questions: arej });

    // 2 saves fix
    this.props.setQuestions(arej);
  }

  deleteQuestion(index) {
    let arej = this.state.questions;
    arej.splice(index, 1);
    console.log(index);
    this.setState({ questions: arej });

    // 2 saves fix
    this.props.setQuestions(arej);
  }

  createQuestion() {
    const { question, answer, options} = this.state
    if(question && answer && options){
      let newPackage = {
        question: this.state.question,
        answer: this.state.answer.value,
        options: this.state.options
      };
      let arej = this.state.questions;
      arej.push(newPackage);
      this.setState({
        questions: arej,
        answer: null,
        question: "",
        options: []
      });
  
      // 2 saves fix
      this.props.setQuestions(arej);
    }

  }

  handleSelectChange = answer => {
    this.setState({ answer: answer });
  };

  handleOptionsChange = option => {
    this.setState({ options: option });
  };

  render() {
    const { questions, options } = this.state;

    let qs;

    if (questions) {
      qs = questions.map((val, index) => {
        return (
          <Question
            key={index}
            question={val.question}
            answer={val.answer}
            options={val.options}
            index={index}
            saveQuestion={this.saveQuestion.bind(this)}
            deleteQuestion={this.deleteQuestion.bind(this)}
          />
        );
      });
    }

    return (
      <div>
        <div>
          <div className="d-flex justify-content-between mt-3">
            <div>
              <h3>Questions</h3>
            </div>

            <div>
              {/* <button className="btn btn-link" onClick={this.handleSubmit}>
                Save Questions
              </button> */}
            </div>
          </div>

          <div className="mb-2">
            <input
              type="text"
              name="question"
              value={this.state.question}
              onChange={this.handleChange}
              placeholder="Question"
              className="form-control"
            />
          </div>

          <div className="mb-3">
            <CreatableSelect
              isClearable
              isMulti
              value={this.state.options}
              onChange={this.handleOptionsChange}
              placeholder="Add answers..."
            />
          </div>

          <div>
            <Select
              value={this.state.answer}
              onChange={this.handleSelectChange}
              options={options}
              placeholder="Select a correct answer..."
            />
          </div>

          <button
            onClick={this.createQuestion.bind(this)}
            className="btn btn-link"
          >
            Add
          </button>
        </div>
        <div className="qs-container">{qs}</div>
      </div>
    );
  }
}

Questions.propTypes = {
  questions: PropTypes.array
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { setQuestions, clearQuestions }
)(Questions);
