import React, { Component } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { createLesson } from "../../actions/courseActions";
import { getVideos } from "../../actions/videoActions";
import { connect } from "react-redux";
import Select from "react-select";
import Questions from "./Questions";
import FileUpload from "../common/FileUpload";
import PropTypes from "prop-types";
import VideoUpload from "../common/VideoUpload";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

class CreateLesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      video: "",
      attachment: "",
      attachmentDescription: "",
      selectedOption: null, // Selected Video
      questions: [],
      errors: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  componentDidMount() {
    this.props.getVideos();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleQuillChange(value) {
    this.setState({ description: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const id = this.props.id;

    const payload = {
      lesson: {
        name: this.state.name,
        description: this.state.description,
        video: this.state.selectedOption,
        attachment: this.state.attachment,
        attachmentDescription: this.state.attachmentDescription,
        questions: this.props.questions,
        attachment: this.props.file
      },
      courseID: id
    };

    this.props.createLesson(payload, id, this.props.history);
  }

  render() {
    const {
      name,
      description,
      video,
      attachment,
      attachmentDescription,
      errors
    } = this.state;

    const { videos } = this.props.videos;
    let options; // options for select
    if (videos) {
      options = videos;
    }
    const { selectedOption } = this.state;

    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]
      ]
    };

    return (
      <div className="container">
        <div className="d-flex justify-content-between mt-3">
          <div>
            <h1>Add lesson</h1>
          </div>
          <div>
            <button onClick={this.handleSubmit} className="btn btn-pp">
              Save
            </button>
          </div>
        </div>

        <div>
          <div>
            <TextFieldGroup
              placeholder="Name"
              name="name"
              type="name"
              value={name}
              onChange={this.handleChange}
              error={errors.name}
            />
            <ReactQuill
              value={description}
              onChange={this.handleQuillChange}
              modules={modules}
            />
          </div>

          <div className="mb-3 mt-3">
            <h3>Select a video:</h3>
            <Select
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <div className="mt-3 mb-3">
            <div>
              <div className="mb-3">
                <h3>Upload Attachment</h3>
              </div>
              <div>
                <FileUpload />
              </div>
            </div>

            <TextAreaFieldGroup
              placeholder="Attachment Description"
              name="attachmentDescription"
              type="attachmentDescription"
              value={attachmentDescription}
              onChange={this.handleChange}
              error={errors.attachmentDescription}
            />
          </div>

          <Questions />
        </div>
      </div>
    );
  }
}

CreateLesson.propTypes = {
  id: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  videos: state.videos,
  questions: state.lessons.questions,
  file: state.courses.file
});

export default connect(
  mapStateToProps,
  { getVideos, createLesson }
)(CreateLesson);
