import {
    GET_ROLE
  } from "../actions/types";
  
const initialState = {
    roles: []
};
  
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ROLE:
    return {
      ...state,
      roles: action.payload
    };
    default:
    return state;
  }
}
  