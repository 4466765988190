import React, { Component } from "react";
import UserNav from "./UserNav";

class UserDashboard extends Component {
  render() {
    return (
      <div>
        <UserNav />
        <div className="wrapper-alt">
          <div className="container">
            <div className="mb-5">
              <h1>Welcome</h1>

              <p>
                Welcome to our Members section. In this section of our web you
                are welcome to browse and use the services that you paid or
                sign-up for. Additional services tabs are included in this
                section to facilitate the sign up process for you. However, the
                services can only be view when you sign up for a trial or as a
                paid customer.
              </p>
            </div>

            <div className="row mb-5">
              <div className="col">
                <p>
                  <strong>University Students</strong> - Please click on tab and
                  begin your lessons. Please note that you have to view the
                  videos in sequence after viewing the video a set of questions
                  will be display for you to respond. You will not be able to
                  proceed to the next module until you successfully complete
                  each module. If you run into problems with a question, please
                  call or email your question to our moderators from the module
                  page just above the questions table.
                </p>
              </div>
              <div className="col">
                <p>
                  <strong>Traiding room FORUM MEMBERS</strong> - When you are a
                  member of the LIVE training room, you are a Forum member of
                  Personsplanet.com - Forum members, have access to all planning
                  and scanning videos and documents, and participate on the
                  daily trading sessions. John Person's moderates the room
                  during the morning 9-12PM or one of his trusted students and
                  traders assist in moderating the room
                </p>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col">
                <p>
                  <strong>Planning and Scanning</strong> - is on Monday mornings
                  in the Live Training Room. Recordings are posted later in the
                  week under the tab Planning and Scanning.
                </p>
              </div>
              <div className="col">
                <p>
                  <strong>Courses</strong> - If you purchased a course, click on
                  the course tab and select the course you purchased. If you
                  have more then one course, please refresh your browser to add
                  the additional purchased.
                </p>
              </div>
            </div>

            <div className="row mb-5">
              <div className="col">
                <p>
                  <strong>Software and Indicators</strong> - Videos are
                  available for viewing to help you understand Person's
                  Indicators. For better understanding of Persons indicators we
                  offer a variaty of courses available when you click store.
                </p>
              </div>
              <div className="col">
                <p>
                  <strong>Newsletters</strong> - As a register user of the
                  Newsletters you also received notices when a new video is
                  uploaded. Please make sure you accept and add our emails to
                  your safe folders of your email box. If you are not receiving
                  our emails, please contact our office 561-655-1881 and check
                  your spam box.
                </p>
              </div>
            </div>

            <div className="row mb-5">
              <div>
                <p>
                  Please read our disclosure statement and legal terms of use
                  for our website
                </p>
                <p>
                  LEGAL NOTICE: All video files, video output and their
                  corresponding slide presentations resulting from any video on
                  our webside or webinar are copyright 1999-2013 by John Person
                  Inc dba PersonsPlanet.com. The contents of the video output,
                  video files, slide presentations, articles may not be altered
                  in whole or in part. The video output, video files, slide
                  presentations, and articles may not be decoded or change from
                  their current state. The videos, and slide presentations may
                  not be altered from their current binary form or encoded into
                  another form. None of the material on this website, including
                  all videos, articles, slide presentations may not be copied or
                  rebroadcast in whole or in part using media including but not
                  limited to websites, email, computer files, raw or encoded
                  binary data, television, print, photography, radio or analog
                  or digital audio or video. Limited exceptions to this
                  copyright notice can only be obtained through express wirtten
                  permission of John Person Inc, dba/Personsplanet.com.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserDashboard;
