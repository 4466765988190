import React, { Component } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { getVideos } from "../../actions/videoActions";
import { createSubPost } from "../../actions/subscriptionsActions";
import { connect } from "react-redux";
import Select from "react-select";
import FileUpload from "../common/FileUpload";
import PropTypes from "prop-types";
import ToggleButton from "react-toggle-button";
import FileUploadSubs from "../common/FileUploadSubs";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class CreateSubPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      published: false,
      video: "",
      attachment: "",
      selectedOption: null, // Selected Video
      filename: "",
      errors: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  componentDidMount() {
    this.props.getVideos();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleQuillChange(value) {
    this.setState({ description: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const id = this.props.id;

    const attachment = {
      label: this.state.filename,
      value: this.props.subscriptions.file
    };

    const payload = {
      post: {
        title: this.state.name,
        video: this.state.selectedOption,
        published: this.state.published,
        attachment: attachment
      },
      subscriptionID: id
    };

    this.props.createSubPost(payload, id, this.props.history);
  }

  render() {
    const { name, video, published, errors, filename } = this.state;
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isAdd = privileges && isVisible(privileges, "subscription", "create")
    if(!isAdd){
      return <div className="p-5" />
    }
    const { videos } = this.props.videos;
    let options; // options for select
    if (videos) {
      options = videos;
    }
    const { selectedOption } = this.state;

    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]
      ]
    };

    return (
      <div className="container">
        <div className="d-flex justify-content-between mt-3">
          <div>
            <h1>Add post</h1>
          </div>
          <div>
            <button onClick={this.handleSubmit} className="btn btn-pp">
              Save
            </button>
          </div>
        </div>

        <div>
          <div>
            <TextFieldGroup
              placeholder="Name"
              name="name"
              type="name"
              value={name}
              onChange={this.handleChange}
              error={errors.name}
            />

            <ReactQuill
              value={this.state.description}
              onChange={this.handleQuillChange}
              modules={modules}
            />
          </div>

          <div>
            <h3>Select a video:</h3>
            <Select
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <div className="mt-3 mb-3" />
          <div>
            <div className="mr-5">
              <h2>Document upload</h2>
            </div>
            <div className="mr-3">
              <TextFieldGroup
                placeholder="filename"
                name="filename"
                type="name"
                value={filename}
                onChange={this.handleChange}
                error={errors.name}
              />
            </div>

            <div>
              <FileUploadSubs />
            </div>
          </div>

          <div className="mt-5">
            Published
            <ToggleButton
              value={this.state.published}
              inactiveLabel="NO"
              activeLabel="YES"
              onToggle={published => {
                this.setState({
                  published: !published
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

CreateSubPost.propTypes = {
  id: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  videos: state.videos,
  subscriptions: state.subscriptions
});

export default connect(
  mapStateToProps,
  { getVideos, createSubPost }
)(CreateSubPost);
