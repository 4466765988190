import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getShoppingCartOrders,
  setOrderLoading
} from "../../actions/oneShoppingCartActions";
import PropTypes from "prop-types";
import Link from "../../../node_modules/react-router-dom/Link";
import Menu from "../common/Menu";
import OrderList from "./OrderList";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1
    };
  }

  componentDidMount() {
    const { page } = this.state;

    if (this.props.match.params.pageNum) {
      this.props.setOrderLoading();
      this.props.getShoppingCartOrders(this.props.match.params.pageNum);
      this.setState({
        page: this.props.match.params.pageNum
      });
    } else {
      this.props.setOrderLoading();
      this.props.getShoppingCartOrders(page);
    }
  }

  loadPage(page) {
    this.props.setOrderLoading();
    this.props.getShoppingCartOrders(page);
  }

  renderPages(numOfPages) {
    let pages = [];
    for (let i = 1; i <= numOfPages; i++) {
      pages.push(
        <div key={i} className="pageCounter" onClick={() => this.loadPage(i)}>
          <Link to={"/admin/dashboard/orders/" + i} className="mr-2" key={i}>
            {i}
          </Link>
        </div>
      );
    }
    return pages;
  }

  render() {
    let numOfPages = "loading..";
    let orders = [];

    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isShow = privileges && isVisible(privileges, "order")

    if (this.props.orders.orders !== null) {
      numOfPages = this.props.orders.orders.pages;
      orders = this.props.orders.orders.orders;
    }

    return (
      <div className="wrapper-alt">
        <Menu />
        {!isShow && <div className="p-5" /> }
        {isShow && <div className="container">
          <h1>Orders</h1>
          {this.props.orders.loading === false ? (
            <OrderList orders={orders} />
          ) : (
            <div className="text-center">
              <img src={require("./loading.gif")} alt="" />
            </div>
          )}

          <div className="d-flex flex-row flex-wrap">
            {this.renderPages(numOfPages)}
          </div>
        </div>}
      </div>
    );
  }
}

Orders.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  members: state.members,
  orders: state.orders
});

export default connect(
  mapStateToProps,
  { getShoppingCartOrders, setOrderLoading }
)(Orders);
