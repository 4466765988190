import { GET_VIDEOS, GET_BUCKETS } from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  videos: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_VIDEOS:
      return {
        ...state,
        videos: action.payload
      };

    case GET_BUCKETS:
      return {
        ...state,
        buckets: action.payload
      };

    default:
      return state;
  }
}
