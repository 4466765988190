import axios from "axios";
import { GET_VIDEOS, GET_ERRORS, GET_BUCKETS } from "./types";

// GET ALL VIDEOS
export const getVideos = () => dispatch => {
  axios
    .get("/api/videos")
    .then(res => {
      dispatch({
        type: GET_VIDEOS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.res.data
      });
    });
};

// GET ALL BUCKETS
export const getBuckets = () => dispatch => {
  axios
    .get("/api/videos/buckets/getAll")
    .then(res => {
      dispatch({
        type: GET_BUCKETS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.res.data
      });
    });
};
