import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sendNewsletter } from '../../actions/newsletterActions'
import Newsletter from './Newsletter'
import { Dropdown } from 'react-bootstrap'

class NewsletterList extends Component {
  state = {
    page: 0,
    size: 10,
  }

  renderPages(numOfPages) {
    console.log({ numOfPages })
    let pages = []
    for (let i = 1; i <= numOfPages; i++) {
      pages.push(
        <div
          key={i}
          className="pageCounter mr-2"
          onClick={() => this.setState({ page: i - 1 })}
        >
          {i}
        </div>,
      )
    }
    return pages
  }

  render() {
    let newsletters, current
    if (this.props.newsletters) {
      newsletters = this.props.newsletters
      //.reverse()
      current = this.props.newsletters
        .sort((a, b) => {
          // nulls sort after anything else
          if (!a.date) {
            return 1
          } else if (!b.date) {
            return -1
          }
          // otherwise, if we're ascending, lowest sorts first
          return new Date(b.date) - new Date(a.date)

        })
        .slice(
          this.state.page * this.state.size,
          (this.state.page + 1) * this.state.size,
        )
        .map((newsletter) => {
          return (
            <li
              key={newsletter._id}
              className="list-group-item list-group-item-action"
            >
              <Newsletter {...this.props} newsletter={newsletter} />
            </li>
          )
        })
    }

    return (
      <div>

        <select className='form-control' style={{width:"170px"}} name="sort" onChange={(e)=>{
          this.setState({size:e.target.value})
        }} value={this.state.size} id="sort">
          <option value="10">10</option>
          <option value="100">100</option>
          <option value="200">200</option>
         
        </select>
        
        <div className="row">
          <div className="col-3">Subject</div>
          <div className="col-2">Product</div>
          <div className="col-2">From Name</div>
          <div className="col-1" />
          <div className="col-2">Date</div>
          <div className="col-1">Sent</div>
          <div className="col-1"></div>
        </div>
        <ul className="list-group">{current}</ul>
        {newsletters && (
          <div className="d-flex flex-row flex-wrap">
            {this.renderPages(Math.ceil(newsletters.length / this.state.size))}
          </div>
        )}
      </div>
    )
  }
}

NewsletterList.propTypes = {
  newsletters: PropTypes.array,
}

export default NewsletterList
