import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Newsletter from './Asset'

class NewsletterList extends Component {
  state = {
    page: 0,
    size: 10,
  }

  renderPages(numOfPages) {
    console.log({ numOfPages })
    let pages = []
    for (let i = 1; i <= numOfPages; i++) {
      pages.push(
        <div
          key={i}
          className="pageCounter mr-2"
          onClick={() => this.setState({ page: i - 1 })}
        >
          {i}
        </div>,
      )
    }
    return pages
  }

  render() {
    let assets, current
    if (this.props.assets) {
      assets = this.props.assets
      //.()
      current = this.props.assets
        // .sort((a, b) => {
        //   // nulls sort after anything else
        //   if (!a.date) {
        //     return 1
        //   } else if (!b.date) {
        //     return -1
        //   }
        //   // otherwise, if we're ascending, lowest sorts first
        //   return new Date(b.date) - new Date(a.date)
          
        // })
        .slice(
          this.state.page * this.state.size,
          (this.state.page + 1) * this.state.size,
        )
        .map((asset) => {
          return (
            <li
              key={asset._id}
              className="list-group-item list-group-item-action"
            >
              <Newsletter {...this.props} asset={asset} />
            </li>
          )
        })
    }

    return (
      <div>
        <div className="row">
          <div className="col-3">Name</div>
          <div className="col-8">Link</div>
          <div className="col-1"></div>
        </div>
        <ul className="list-group">{current}</ul>
        {assets && (
          <div className="d-flex flex-row flex-wrap">
            {this.renderPages(Math.ceil(assets.length / 10))}
          </div>
        )}
      </div>
    )
  }
}

NewsletterList.propTypes = {
  assets: PropTypes.array,
}

export default NewsletterList
