import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { registerUser } from "../../actions/authActions";
import { addMemberClearSuccess, addMemberSetSuccess } from "../../actions/formActions";
import { withRouter } from "react-router-dom";

class AddMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      errors: {},
      isSuccess: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log({
      nextProps
    })
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.forms){
      if(nextProps.forms.addMember.isSuccess){
        this.setState({ 
          isSuccess: true,
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          errors: {},
        }, ()=> {
          this.props.addMemberClearSuccess()
          setTimeout(() => {
            this.setState({
              isSuccess: false,
            })
          }, 2000);
        });
      }
    }
  }

  //Handles state changes for inputs
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  //Sends login credentials/payload to the backend
  handleSubmit(e) {
    e.preventDefault();

    const newUser = {
      user: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName
      },
      password: this.state.password
    };

    this.props.registerUser(newUser);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="wrapper-alt">
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <h1 className="display-4 text-center">Add member</h1>

                <form noValidate onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.firstName
                      })}
                      placeholder="Firstname"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                    />
                    {errors.firstName && (
                      <div className="invalid-feedback">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.lastName
                      })}
                      placeholder="Lastname"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                    />
                    {errors.lastName && (
                      <div className="invalid-feedback">{errors.lastName}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.email
                      })}
                      placeholder="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>

                  <hr />

                  <div className="form-group">
                    <input
                      type="password"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.password
                      })}
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <small className="form-text text-muted">
                      Your password needs to be at least 8 characters long and
                      contain a number.
                    </small>
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  {this.state.isSuccess && 
                    <div className="alert alert-success">Member Successfully Created </div>
                  }
                  <input type="submit" className="btn btn-pp btn-block mt-4" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddMember.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  forms: state.forms
});

export default connect(
  mapStateToProps,
  { registerUser, addMemberClearSuccess, addMemberSetSuccess }
)(withRouter(AddMember));
