import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { registerAdmin } from "../../actions/authActions";
import { getRoles } from "../../actions/roleActions";
import { withRouter } from "react-router-dom";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      password2: "",
      firstName: "",
      lastName: "",
      errors: {},
      roles: props.roles || [],
      rolemeta: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount(){
    this.props.getRoles()
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if(nextProps.roles){
      this.setState({ roles: nextProps.roles })
    }
  }

  //Handles state changes for inputs
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  //Sends login credentials/payload to the backend
  handleSubmit(e) {
    e.preventDefault();

    const newUser = {
      admin: {
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        rolemeta: this.state.rolemeta
      },
      password: this.state.password,
      password2: this.state.password2,
    };

    this.props.registerAdmin(newUser);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="wrapper-alt">
        <div className="register">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <h1 className="display-4 text-center">
                  Create an admin account
                </h1>

                <form noValidate onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.firstName
                      })}
                      placeholder="Firstname"
                      name="firstName"
                      value={this.state.firstName}
                      onChange={this.handleChange}
                    />
                    {errors.firstName && (
                      <div className="invalid-feedback">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.lastName
                      })}
                      placeholder="Lastname"
                      name="lastName"
                      value={this.state.lastName}
                      onChange={this.handleChange}
                    />
                    {errors.lastName && (
                      <div className="invalid-feedback">{errors.lastName}</div>
                    )}
                  </div>

                  <div className="form-group">
                    <input
                      type="email"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.email
                      })}
                      placeholder="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                    <small className="form-text text-muted">
                      Please enter a valid email address, we'll send you a
                      verification link to this address.
                    </small>
                  </div>

                  <hr />

                  <div className="form-group">
                    <input
                      type="password"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.password
                      })}
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                    />
                    <small className="form-text text-muted">
                      Your password needs to be at least 8 characters long and
                      contain a number.
                    </small>
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className={classnames("form-control form-control-lg", {
                        "is-invalid": errors.password2
                      })}
                      placeholder="Confirm Password"
                      name="password2"
                      value={this.state.password2}
                      onChange={this.handleChange}
                    />
                    {errors.password2 && (
                      <div className="invalid-feedback">{errors.password2}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <select
                      className="form-control form-control-lg"
                      name="rolemeta"
                      value={this.state.rolemeta}
                      onChange={this.handleChange}
                    >
                      {this.state.roles.map(el => (
                        <option key={el._id} value={el._id}>{el.name}</option>
                      ))}
                    </select>
                    {errors.password2 && (
                      <div className="invalid-feedback">{errors.password2}</div>
                    )}
                  </div>
                  <input type="submit" className="btn btn-pp btn-block mt-4" />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  registerAdmin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  getRoles: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  roles: state.roles.roles,
});

export default connect(
  mapStateToProps,
  { registerAdmin, getRoles }
)(withRouter(Register));
