import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getSingleSub,
  clearSubscription,
  editSubscription,
  deleteSubscription
} from "../../actions/subscriptionsActions";
import PropTypes from "prop-types";
import TextFieldGroup from "../common/TextFieldGroup";
import Menu from "../common/Menu";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import CreateSubPost from "./CreateSubPost";
import SubPostList from "./SubPostList";
import FileUploadSubs from "../common/FileUploadSubs";
import FileUpload from "../common/FileUpload";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class EditSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      errors: "",
      filename: "",
      image: "",
      document: {
        label: "",
        value: "Document not found, try uploading one."
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteCourse = this.deleteCourse.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getSingleSub(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

    if (nextProps.subscriptions.subscription) {
      this.setState({
        name: nextProps.subscriptions.subscription.name,
        description: nextProps.subscriptions.subscription.description,
        attachment: nextProps.subscriptions.subscription.attachment,
        image: nextProps.subscriptions.subscription.thumbnail
      });
      if (
        nextProps.subscriptions.subscription.attachment !== null &&
        nextProps.subscriptions.subscription.attachment !== undefined
      ) {
        this.setState({
          document: nextProps.subscriptions.subscription.attachment
        });
      }
    }
    if(nextProps.subscriptions.subscription){
    }
  }

  componentWillUnmount() {
    this.props.clearSubscription();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleQuillChange(value) {
    this.setState({ description: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { name, description } = this.state;
    const id = this.props.match.params.id;

    let image = this.state.image;
    if (this.props.courses.file) {
      image = this.props.courses.file;
    }

    let attachment = null;

    if (this.state.filename !== "" && this.props.subscriptions.file !== null) {
      attachment = {
        label: this.state.filename,
        value: this.props.subscriptions.file
      };
    }

    this.props.editSubscription(name, description, image, attachment, id);
  }

  renderThumb() {
    if (this.state.image) {
      return (
        <div>
          <img className="course-thumbnail" src={this.state.image} />
        </div>
      );
    } else {
      return (
        <div>
          <span>No image found, upload a thumbnail for this course</span>
        </div>
      );
    }
  }

  deleteCourse(e) {
    e.preventDefault();

    this.props.deleteSubscription(this.props.match.params.id);

    window.location.replace("/admin/dashboard/subscriptions");
  }

  render() {
    const { name, description, errors, filename } = this.state;

    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isEditable = privileges && isVisible(privileges, "subscription", "edit")
    const isDeletable = privileges && isVisible(privileges, "subscription", "delete")

    let posts;

    if (this.props.subscriptions.subscription) {
      posts = this.props.subscriptions.subscription.posts;
    }

    const modalStyleAddLesson = {
      position: "fixed",
      zIndex: "1",
      height: "100%",
      width: "40vw",
      padding: "30px",
      right: "0",
      top: "0"
    };

    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]
      ]
    };

    if(!isEditable){
      return <div className="p-5" />
    }
    return (
      <div className="wrapper">
        <Menu />

        <div className="container">
          <div className="d-flex justify-content-between mt-3">
            <div>
              <h1>Edit Subscription</h1>
            </div>

            <div>
              <button className="btn btn-pp" onClick={this.handleSubmit}>
                Save
              </button>
            </div>
          </div>

          <div>
            <TextFieldGroup
              placeholder="name"
              name="name"
              type="name"
              value={name}
              onChange={this.handleChange}
              error={errors.name}
            />

            <div className="form-group">
              <ReactQuill
                value={description}
                onChange={this.handleQuillChange}
                modules={modules}
              />
            </div>

            <hr />

            <div className="mt-3 mb-3">
              <span>Image preview</span>
              {this.renderThumb()}
            </div>
            <div className="d-flex">
              <div className="mr-5">
                <h2>Thumbnail</h2>
              </div>
              <div>
                <FileUpload />
              </div>
            </div>

            <hr />

            <div className="mt-3 mb-3">
              <div>
                <h3>Document:</h3>
              </div>
              <div>
                <span>Name: {this.state.document.label}</span>
              </div>
              <div>
                <span>Link: {this.state.document.value}</span>
              </div>
            </div>
            <div className="d-flex ">
              <div className="mr-5">
                <h2>Document upload</h2>
              </div>
              <div className="mr-3">
                <TextFieldGroup
                  placeholder="filename"
                  name="filename"
                  type="name"
                  value={filename}
                  onChange={this.handleChange}
                  error={errors.name}
                />
              </div>

              <div>
                <FileUploadSubs />
              </div>
            </div>

            <div>
              <div className="d-flex justify-content-between mt-3 mb-2">
                <div>
                  <h2>Posts</h2>
                </div>

                <div>
                  <Popup
                    trigger={<button className="btn btn-pp">Add Post</button>}
                    position="right center"
                    modal={true}
                    closeOnDocumentClick
                    contentStyle={modalStyleAddLesson}
                    arrow={false}
                    closeOnEscape={true}
                  >
                    <div className="modal-container">
                      <CreateSubPost id={this.props.match.params.id} />
                    </div>
                  </Popup>
                </div>
              </div>
              <SubPostList posts={posts} />
            </div>
          </div>
          {isDeletable && <div className="mt-5">
            <button onClick={this.deleteCourse} className="btn btn-danger mt-5">
              Delete Subscription
            </button>
          </div>}
        </div>
      </div>
    );
  }
}

EditSubscription.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  subscriptions: state.subscriptions,
  courses: state.courses
});

export default connect(
  mapStateToProps,
  { getSingleSub, clearSubscription, editSubscription, deleteSubscription }
)(EditSubscription);
