export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const EDIT_PROFILE = "EDIT_PROFILE";
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBER = "GET_MEMBER";
export const CREATE_COURSE = "CREATE_COURSE";
export const GET_COURSES = "GET_COURSES";
export const GET_COURSE = "GET_COURSE";
export const GET_USER_COURSES = "GET_USER_COURSES";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const CLEAR_FILE = "CLEAR_FILE";
export const CLEAR_COURSE = "CLEAR_COURSE";
export const SET_MEMBER_LOADING = "SET_MEMBER_LOADING";
export const SET_NEWSLETTER_LOADING = "SET_NEWSLETTER_LOADING";

export const SET_PAYLOAD = "SET_PAYLOAD";
export const CLEAR_MEMBERS = "CLEAR_MEMBERS";
export const GET_ADMINS = "GET_ADMINS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// Videos
export const GET_VIDEOS = "GET_VIDEOS";
export const GET_BUCKETS = "GET_BUCKETS";

// Lessons
export const SET_QUESTIONS = "SET_QUESTIONS";
export const CLEAR_QUESTIONS = "CLEAR_QUESTIONS";
export const EDIT_LESSON = "EDIT_LESSON";
export const GET_LESSON = "GET_LESSON";
export const REMOVE_LESSON = "REMOVE_LESSON"

// Planning and Scanning
export const GET_PAS = "GET_PAS";
export const CREATE_PAS = "CREATE_PAS";
export const GET_LATEST_POST = "GET_LATEST_POST";
export const CLEAR_LATEST_POST = "CLEAR_LATEST_POST";

// Subscriptions
export const GET_SUBS = "GET_SUBS";
export const CREATE_SUB = "CREATE_SUB";
export const CLEAR_SUB = "CLEAR_SUB";
export const GET_USER_SUBS = "GET_USERS_SUBS";
export const GET_POST = "GET_POST";
export const UPLOAD_FILE_SUBS = "UPLOAD_FILE_SUBS";
export const CLEAR_FILE_SUBS = "CLEAR_FILE_SUBS";

// CSV
export const GET_CSV_DATA = "GET_CSV_DATA";
export const CLEAR_CSV_DATA = "CLEAR_CSV_DATA";

// 1shoppingcart
export const GET_SHOPPINGCART_ORDERS = "GET_SHOPPINGCART_ORDERS";
export const SET_ORDERS_LOADING = "SET_ORDERS_LOADING";

// Newsletters
export const GET_NEWSLETTERS = "GET_NEWSLETTERS";
export const GET_NEWSLETTER = "GET_NEWSLETTER";
export const CLEAR_NEWSLETTER = "CLEAR_NEWSLETTER";

export const GET_CURRENT_NEWSLETTER = "GET_CURRENT_NEWSLETTER";
export const EDIT_NEWSLETTER = "EDIT_NEWSLETTER";

//Popup Controller
export const SHOW_MEMBER_POPUP = "SHOW_MEMBER_POPUP";
export const HIDE_MEMBER_POPUP = "HIDE_MEMBER_POPUP";

//forms
export const ADD_MEMBER_SET_SUCCESS = "ADD_MEMBER_SET_SUCCESS";
export const ADD_MEMBER_CLEAR_SUCCESS = "ADD_MEMBER_CLEAR_SUCCESS";

//role management
export const ADD_ROLE = "ADD_ROLE"
export const GET_ROLE = "GET_ROLE"