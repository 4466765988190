import React, { Component } from 'react'
import 'react-quill/dist/quill.snow.css' // ES6
import { connect } from 'react-redux'
import { clearFile, getCourses } from '../../actions/courseActions'
import {
  clearMembers,
  getMembers,
  getMembersByCourse,
  getMembersBySub,
} from '../../actions/memberActions'
import {
  clearNewsletter,
  AddAsset,
} from '../../actions/newsletterActions'
import { getSubs } from '../../actions/subscriptionsActions'
import FileUpload from '../common/FileUpload'
import TextFieldGroup from '../common/TextFieldGroup'

class CreateNewsletter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      filename: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()

    const {
      name,
      filename
    } = this.state

    const payload = {
      name: name,
      source: filename
    }

    this.props.AddAsset(payload)
    this.props.history.goBack()
  }

  handleAddFile(file) {
    const { attachments, filename } = this.state

    console.log({ attachments, file })

    let arej = attachments

    arej.push({
      filename,
      path: file,
    })

    this.setState({
      attachments: arej,
    })

    console.log({ state: this.state })
    this.props.clearFile()
  }

  renderFileUpload() {
    const file = this.props.file
    if (file && file !== this.state.filename) this.setState({ filename: file })
    return (
      <div className="">
        <FileUpload
          cb={() => {
            // console.log('callback', file)
            // this.setState({ filename: file })
          }}
        />
      </div>
    )
  }

  render() {
    const { name, filename } = this.state
    console.log({ filename, name })
    return (
      <div className="wrapper-alt">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Add an asset</h1>
            </div>
            <div>
              <button onClick={this.handleSubmit} className="btn btn-pp">
                Save
              </button>
            </div>
          </div>
          <div className="mt-4">
            <TextFieldGroup
              placeholder="Name"
              name="name"
              type="name"
              value={name}
              onChange={(e) =>
                this.setState({ [e.target.name]: e.target.value })
              }
              // error={errors.name}
            />
            <div>{this.renderFileUpload()}</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  members: state.members,
  courses: state.courses,
  subscriptions: state.subscriptions,
  newsletters: state.newsletters,
  file: state.courses.file,
})

export default connect(mapStateToProps, {
  getMembers,
  getCourses,
  getSubs,
  AddAsset,
  clearNewsletter,
  clearFile,
  getMembersBySub,
  getMembersByCourse,
  clearMembers,
})(CreateNewsletter)
