import React, { Component } from "react";
import { connect } from "react-redux";
import Link from "react-router-dom/Link";
import PropTypes from "prop-types";
import EditSubPost from "./EditSubPost";
import Popup from "reactjs-popup";

class SubPostList extends Component {
  render() {
    let posts;

    const modalStyle = {
      position: "fixed",
      zIndex: "1",
      height: "100%",
      width: "40vw",
      padding: "30px",
      right: "0",
      top: "0"
    };

    if (this.props.posts) {
      posts = this.props.posts.map((post, index) => {
        return (
          <li key={post._id} className="list-group-item list-group-item-action">
            <div className="row">
              <div className="col-1">
                <Popup
                  trigger={<span className="btn-link">Edit</span>}
                  position="right center"
                  modal={true}
                  closeOnDocumentClick
                  contentStyle={modalStyle}
                  arrow={false}
                  closeOnEscape={true}
                >
                  <div className="modal-container">
                    <EditSubPost post={post} />
                  </div>
                </Popup>
              </div>
              <div className="col-5">{post.title}</div>
              <div className="col-5">{post.video.label}</div>
              <div className="col-1">{post.published.toString()}</div>
            </div>
          </li>
        );
      });
    }

    return (
      <div>
        <div>
          <div className="row">
            <div className="col-1">Edit</div>
            <div className="col-5">Name</div>
            <div className="col-5">Video</div>
            <div className="col-1">Published</div>
          </div>
          <ul className="list-group">{posts}</ul>
        </div>
      </div>
    );
  }
}

SubPostList.propTypes = {
  posts: PropTypes.array
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {}
)(SubPostList);
