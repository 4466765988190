import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaElement from "./MediaElement";
import UserQuestions from "./UserQuestions";
import { withRouter } from "react-router";
import UserNav from "./UserNav";
import { connect } from "react-redux";
import {
  clearSubscription,
  getSingleSub,
  getPost
} from "../../actions/subscriptionsActions";
import { getLesson } from "../../actions/lessonActions";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import isEmpty from "../../validation/is-empty";

class ViewPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "loading...",
      description: null,
      video: "",
      attachment: "",
      attachmentDescription: ""
    };
  }

  componentDidMount() {
    this.props.getPost(this.props.match.params.post);

    const id = this.props.match.params.id;
    this.props.getSingleSub(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.post) {
      this.setState({
        title: nextProps.post.title,
        video: nextProps.post.video,
        description: nextProps.post.description
      });
    }

    if (nextProps.post.attachment) {
      this.setState({
        attachment: nextProps.post.attachment.value
      });
    }
  }

  render() {
    const { title, description, video, questions, attachment } = this.state;

    const sources = [{ src: video.value, type: "video/mp4" }],
      config = { mode: "shim" },
      tracks = {};

    let posts;

    if (this.props.subscription) {
      posts = this.props.subscription.posts.map((post, index) => {
        return (
          <li key={post._id} className="list-group-item list-group-item-action">
            <div className="d-flex flex-column">
              <div>
                <h5>{post.title}</h5>
              </div>
              <div>
                <a
                  href={
                    "/dashboard/subscriptions/" +
                    this.props.match.params.id +
                    "/" +
                    post._id
                  }
                >
                  View
                </a>
              </div>
            </div>
          </li>
        );
      });
    }

    return (
      <div>
        <UserNav />
        <div className="wrapper-alt">
          <div className="container-fluid">
            <div>
              <h2>{title}</h2>
            </div>
            <div>
              {this.state.description !== null ? (
                <p>{renderHTML(description)}</p>
              ) : null}
            </div>

            <div className="row">
              <div className="col-8">
                <div
                  onContextMenu={e => {
                    e.preventDefault();
                    return false;
                  }}
                >
                  <MediaElement
                    id="player1"
                    mediaType="video"
                    preload="none"
                    controls
                    width="80%"
                    height=""
                    poster=""
                    sources={JSON.stringify(sources)}
                    options={JSON.stringify(config)}
                    tracks={JSON.stringify(tracks)}
                  />
                </div>
                {isEmpty(attachment) === true ||
                attachment ===
                  "Document not found, try uploading one." ? null : (
                  <a href={attachment}>View attachment</a>
                )}
              </div>
              <div className="col-4">
                <h4>Posts: </h4>
                {posts}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ViewPost.propTypes = {
  post: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  post: state.subscriptions.post,
  subscription: state.subscriptions.subscription
});

export default connect(
  mapStateToProps,
  { clearSubscription, getSingleSub, getPost }
)(withRouter(ViewPost));
