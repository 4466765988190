import React from "react";

export default () => {
  return (
    <div className="wrapper-alt">
      <h1>Page not found</h1>
      <h2>The page you were looking for isnt there.</h2>
      <a href="/">Go back</a>
    </div>
  );
};
