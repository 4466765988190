import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUserSubs } from "../../actions/subscriptionsActions";
import UserNav from "./UserNav";
import renderHTML from "react-render-html";
import isEmpty from "../../validation/is-empty";

class UserSubscriptions extends Component {
  componentDidMount() {
    this.props.getUserSubs();
  }

  render() {
    const { userSubs } = this.props;
    let subs = "Loading...";
    if (isEmpty(userSubs) === false) {
      subs = userSubs.map((sub, index) => {
        return (
          <li
            key={sub._id}
            className="list-group-item list-group-item-action mb-3 course-prev-container mx-auto"
          >
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <div>
                  <h4>{sub.name}</h4>
                </div>
              </div>

              {/* <div>{renderHTML(sub.description)}</div> */}

              <div className="mt-3">
                <Link
                  className="btn btn-pp btn-block"
                  to={"/dashboard/subscriptions/" + sub._id}
                >
                  View
                </Link>
              </div>
            </div>
          </li>
        );
      });
    } else {
      subs = "You dont have any subscriptions.";
    }
    return (
      <div>
        <UserNav />

        <div className="wrapper-alt">
          <div className="container">
            <h1>Your Subscriptions</h1>
            <ul className="list-group">{subs}</ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  userSubs: state.subscriptions.userSubs
});

export default connect(
  mapStateToProps,
  { getUserSubs }
)(UserSubscriptions);
