import axios from "axios";
import {
  GET_ERRORS,
  CREATE_COURSE,
  GET_COURSES,
  GET_COURSE,
  GET_USER_COURSES,
  EDIT_LESSON,
  CLEAR_COURSE,
  CLEAR_FILE,
  UPLOAD_FILE,
} from "./types";

// Create course
export const createCourse = (name, description) => dispatch => {
  const payload = {
    course: {
      name: name,
      description: description
    }
  };
  axios
    .post("/api/courses/createCourse", payload)
    .then(res => {
      dispatch({
        type: CREATE_COURSE,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Get all courses
export const getCourses = () => dispatch => {
  axios
    .get("/api/courses/getCourses")
    .then(res => {
      dispatch({
        type: GET_COURSES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Gets all of the courses owned by the user
export const getUserCourses = () => dispatch => {
  axios
    .get("/api/courses/getByUser")
    .then(res => {
      dispatch({
        type: GET_USER_COURSES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Get a single course
export const getCourse = id => dispatch => {
  axios
    .get("/api/courses/getCourse", { params: { id: id } })
    .then(res => {
      dispatch({
        type: GET_COURSE,
        payload: res.data
      });
      console.log(id);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Edit a course
export const editCourse = (name, description, image, id) => dispatch => {
  const payload = {
    course: {
      name: name,
      description: description,
      image: image,
      _id: id
    }
  };
  axios
    .put("/api/courses/editCourse", payload)
    .then(res => {
      dispatch({
        type: GET_COURSE,
        payload: res.data
      });
      console.log(res.data);
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Clear course
export const clearCourse = () => dispatch => {
  dispatch({
    type: CLEAR_COURSE
  });
};

// Delete a course
export const deleteCourse = id => dispatch => {
  axios
    .delete("/api/courses/", {
      params: {
        id: id
      }
    })
    .then(res => {
      dispatch({
        type: GET_COURSE,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// Create a lesson
export const createLesson = (payload, id, history) => dispatch => {
  axios
    .post("/api/courses/addLesson", payload)
    .then(res => {
      dispatch({
        type: GET_COURSE,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Edit a lesson
export const editLesson = payload => dispatch => {
  axios
    .put("/api/courses/editLesson", payload)
    .then(res => {
      dispatch({
        type: GET_COURSE,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Delete lesson ADD DISPATCH HERE
export const deleteLesson = id => dispatch => {
  axios
    .delete("/api/courses/deleteLesson", {
      params: {
        id: id
      }
    })
    .then(res => {
      dispatch({
        type: GET_COURSE,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: GET_ERRORS,
        payload: error.response.data
      });
    });
};

// File upload
export const fileUpload = payload => dispatch => {
  axios({
    method: "post",
    url: "/api/admin/uploadFile",
    data: payload
  })
    .then(function(response) {
      dispatch({
        type: UPLOAD_FILE,
        payload: response.data
      });
    })
    .catch(function(response) {
      dispatch({
        type: GET_ERRORS,
        payload: response.data
      });
    });
};
// UPLOAD A VIDEO
export const uploadVideo = (payload, bucketname) => dispatch => {
  axios({
    method: "post",
    url: "/api/videos/" + bucketname,
    data: payload
  })
    .then(function(response) {
      dispatch({
        type: UPLOAD_FILE,
        payload: response.data
      });
      console.log(response.data);
    })
    .catch(function(response) {
      dispatch({
        type: GET_ERRORS,
        payload: response.data
      });
    });
};

// Clear File
export const clearFile = () => dispatch => {
  dispatch({
    type: CLEAR_FILE
  });
};
// Get a University
export const getUniversity = () => dispatch => {
  axios
    .get("/api/courses/getUniversity")
    .then(res => {
      dispatch({
        type: GET_COURSE,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};
