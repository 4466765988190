import isEmpty from '../validation/is-empty'
const isVisible = ({privileges, status}, key, check = "view") =>{
    if(isEmpty(privileges)){
        return true
    }    
    if(!status) return false
    const flag = Object.keys(privileges[key]).some(el => el === check)

    if(!flag)
    return true

    return privileges[key][check]
}
export default isVisible