import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import Popup from "reactjs-popup";
import classNames from "classnames";
import moment from "moment";
import EditMember from "./EditMember";
import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class MemberList extends Component {
    render() {
      const {
        auth
    } = this.props
    const privileges = getPrivileges(auth);
    const isEditable = privileges && isVisible(privileges, "members", "edit")
    const members = this.props.members.map(member => {
      const modalStyleAddLesson = {
        position: "fixed",
        zIndex: "1",
        height: "100%",
        width: "60vw",
        padding: "30px",
        right: "0",
        top: "0"
      };
      return (
        <li key={member._id} className="list-group-item list-group-item-action">
          <div className="row">
            {isEditable && <div className="col-1">
              {/* <Link className="mr-2" to={"/admin/dashboard/user/" + member._id}>
                Edit
              </Link> */}
              <Popup
                trigger={<span className="btn-link">Edit</span>}
                position="right center"
                modal={true}
                closeOnDocumentClick
                contentStyle={modalStyleAddLesson}
                arrow={false}
                closeOnEscape={true}
              >
                <div className="modal-container">
                  <EditMember id={member._id} />
                </div>
              </Popup>
            </div>}
            <div className={classNames({
              "col-3": isEditable,
              "col-4": !isEditable
            })}>
              {member.firstName} {member.lastName}
            </div>
            <div className="col-3">{member.email}</div>
            <div className="col-2">{member.phone}</div>
            <div className="col-2">{member.pas ? moment(member.pas.expirationDate).format("DD/MM/YYYY")  : ""}</div>
            <div className="col-1">{member.status}</div>
          </div>
        </li>
      );
    });

    return (
      <div>
        <div className="row">
          {isEditable && <div className="col-1">Edit</div>}
          <div className={classNames({
            "col-3": isEditable,
            "col-4": !isEditable
          })}>Name</div>
          <div className="col-3">Email</div>
          <div className="col-2">Phone</div>
          <div className="col-2">Expiry Date</div>
          <div className="col-1">Status</div>
        </div>
        <ul className="list-group">{members}</ul>
      </div>
    );
  }
}

MemberList.propTypes = {
  members: PropTypes.array.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(mapStateToProps)(MemberList);
