import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginAdmin } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      errors: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.role === "Admin"
    ) {
      this.props.history.push("/admin/dashboard");
    } else if (
      this.props.auth.isAuthenticated &&
      this.props.auth.user.role === "User"
    ) {
      this.props.history.push("/dashboard");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/admin/dashboard");
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    var userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginAdmin(userData);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="wrapper-alt">
        <div className="container">
          <form onSubmit={this.handleSubmit} className="userLoginForm">
            <h1 className="display-4 text-center">Admin login</h1>
            <p className="lead text-center">
              Hello Admin, please log in and admin away!
            </p>

            <TextFieldGroup
              placeholder="Email"
              name="email"
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              error={errors.message}
            />

            <TextFieldGroup
              placeholder="Password"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleChange}
              error={errors.message}
            />

            <div className="d-flex justify-content-between align-items-center">
              <button type="submit" value="Submit" className="btn btn-pp">
                Log in
              </button>
              <Link to="/login/forgotPassword">Forgot your password?</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginAdmin: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginAdmin }
)(Login);
