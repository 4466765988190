import React, { Component } from "react";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "../common/Menu";
import { getAdmins } from "../../actions/memberActions";
import { deleteAdmin } from "../../actions/authActions";
import { getRoles } from "../../actions/roleActions";
import Popup from "reactjs-popup";
import Register from "../auth/Register";
import EditMember from "./EditMember";
import EditAdmin from "./EditAdmin";
import classNames from "classnames";
import isEmpty from "../../validation/is-empty";
import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class AdminList extends Component {
  constructor(){
    super()
    this.onFilterChange = this.onFilterChange.bind(this)
    this.state = {
      filter_role: "all"
    }
  }
  componentDidMount() {
    this.props.getAdmins();
    this.props.getRoles();
  }

  onFilterChange(e){
    const {
      value
    } = e.target
    this.setState({
      filter_role: value
    })
  }
  checkIfSuperAdmin() {
    if (this.props.auth.user.email !== "mary@nationalfutures.com") {
      // return <Redirect to="/admin/dashboard" />;

    } else {
      return null;
    }
  }
  isSuperAdmin(email, authEmail){
    if(email === authEmail)
    return false
    
    if(email === "mary@nationalfutures.com" || email === "mary@nationalfutures.com" || email === "thirdessential@gmail.com" || email === "johnp@nationalfutures.com") 
    return true

    return false

  }
  render() {
    let admins;
    const {
			auth, roles
		} = this.props
    const {
			filter_role
		} = this.state
		const privileges = getPrivileges(auth);
    const isShow = privileges && isVisible(privileges, "admins")
    const isAdd = privileges && isVisible(privileges, "admins", "create")
    const isDeletable = privileges && isVisible(privileges, "admins", "delete")
    const isEditable = privileges && isVisible(privileges, "admins", "edit")
    console.log({
      roles
    })
    const modalStyleAddLesson = {
      position: "fixed",
      zIndex: "1",
      height: "100%",
      width: "60vw",
      padding: "30px",
      right: "0",
      top: "0"
    };
    if (this.props.admins !== null) {

      admins = this.props.admins.filter(el => {
        console.log({
          filter_role,
          meta: el.rolemeta
        })
        if(filter_role === "all")
        return true
        
        if(el.rolemeta)
        return filter_role === el.rolemeta.name

        return false
      }).map(admin => {
        return (
          <li key={admin._id} className="list-group-item">
            <div className="row">
              <div className={classNames({
                "col-6": isEditable,
                "col-8": !isEditable,
              })}>{admin.email}</div>
              <div className="col-2">
                {admin.rolemeta ? admin.rolemeta.name : "Not Yet Assigned"}
              </div>
              {isEditable 
                && !this.isSuperAdmin(admin.email, auth.user.email) 
                && <div className="col-2">
                <Popup
                  trigger={
                    <span className="btn btn-link">Edit</span>
                  }
                  position="right center"
                  modal={true}
                  closeOnDocumentClick
                  contentStyle={modalStyleAddLesson}
                  arrow={false}
                  closeOnEscape={true}
                >
                  <div className="modal-container">
                    <EditAdmin id={admin._id} isadmin={true} />
                  </div>
                </Popup>
              </div>}
              {isDeletable && !this.isSuperAdmin(admin.email, auth.user.email )  && <div className="col-2">
                <button
                  onClick={() => this.props.deleteAdmin(admin._id)}
                  className="btn btn-link"
                >
                  delete
                </button>
              </div>}
            </div>
          </li>
        );
      });
    } else {
      return null;
    }
    if(!isShow){
      return <div className="p-5" />
    }

    return (
      <div className="wrapper-alt">
        {this.checkIfSuperAdmin()}
        <Menu />
        <div className="container">
          {isAdd && <Popup
            trigger={
              <button className="btn btn-link">Create a new manager</button>
            }
            position="right center"
            modal={true}
            closeOnDocumentClick
            contentStyle={modalStyleAddLesson}
            arrow={false}
            closeOnEscape={true}
          >
            <div className="modal-container">
              <Register />
            </div>
          </Popup>}
          <div className="ml-5 d-inline-block">
            <label className="d-inline-block">Filter Members</label>
            <select name="filter_role" className="form-control w-auto ml-2 d-inline" onChange={this.onFilterChange} value={this.state.filter_role}>
              <option value="all" >All</option>
              {this.props.roles.map((el, key) => <option key={key} value={el.name} >{el.name}</option> )}
            </select>
          </div>
          <ul className="list-group">
          <li className="list-group-item">
            <div className="row">
              <div className={classNames({
                "col-6": isEditable,
                "col-8": !isEditable,
              })}>Email</div>
              {<div className="col-2">
                Role
              </div>}
              {isEditable && <div className="col-2">
                Edit
              </div>}
              {isDeletable && <div className="col-2">
                Delete
              </div>}
            </div>
          </li>
          {admins}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  admins: state.members.admins,
  errors: state.errors,
  roles: state.roles.roles || [],
});

export default connect(
  mapStateToProps,
  { getAdmins, deleteAdmin, getRoles }
)(withRouter(AdminList));
