import React, { Component } from "react";
import Members from "./Members";
import Menu from "../common/Menu";

class AdminDashboard extends Component {
  render() {
    return (
      <div className="wrapper-alt">
        <Menu />
        <div className="container admin-dashboard-container">
          <div>
            <Members history={this.props.history} />
          </div>
        </div>
      </div>
    );
  }
}
export default AdminDashboard;
