import React, { Component } from "react";
import PropTypes from "prop-types";

export default class Recipient extends Component {
  renderButton() {
    const { name, email, id, index } = this.props;

    if (this.props.sendTo === true) {
      return (
        <i
          onClick={() => this.props.removeFromSend(name, email, id, index)}
          className="fas fa-ban"
        />
      );
    } else {
      return (
        <i
          onClick={() => this.props.addToSend(name, email, id, index)}
          className="fas fa-plus-square"
        />
      );
    }
  }

  render() {
    return (
      <div className="recipient-container">
        <span className="mr-2">{this.props.name}</span>
        <span>{this.renderButton()}</span>
      </div>
    );
  }
}

Recipient.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  sendTo: PropTypes.bool.isRequired,
  addToSend: PropTypes.func.isRequired,
  removeFromSend: PropTypes.func.isRequired
};
