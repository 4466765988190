import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MediaElement from './MediaElement'
import UserQuestions from './UserQuestions'
import { withRouter } from 'react-router'
import UserNav from './UserNav'
import { connect } from 'react-redux'
import {
  clearCourse,
  getCourse,
  getUniversity,
} from '../../actions/courseActions'
import { getLesson } from '../../actions/lessonActions'
import { Link } from 'react-router-dom'
import renderHTML from 'react-render-html'
import isEmpty from '../../validation/is-empty'

class ViewLesson extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: 'loading...',
      description: null,
      video: '',
      questions: [],
      attachment: '',
      attachmentDescription: '',
      lessonAvailable: true,
    }
  }

  componentDidMount() {
    this.props.getLesson(this.props.match.params.lesson)

    if (this.props.match.params.id === '5c0956cb4f492a641e891efe') {
      this.props.getUniversity()
    } else {
      this.props.getCourse(this.props.match.params.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lessons.lesson) {
      this.setState({
        name: nextProps.lessons.lesson.name,
        video:
          nextProps.lessons.lesson && nextProps.lessons.lesson.video
            ? nextProps.lessons.lesson.video
            : {},
        questions: nextProps.lessons.lesson.questions,
        description: nextProps.lessons.lesson.description,
        attachment: nextProps.lessons.lesson.attachment,
        attachmentDescription: nextProps.lessons.lesson.attachmentDescription,
      })
    }
    if (isEmpty(nextProps.errors) === false) {
      if (
        nextProps.lessons.lesson &&
        nextProps.lessons.lesson.orderIndex === 1
      ) {
        this.setState({
          lessonAvailable: true,
        })
      } else {
        this.setState({
          lessonAvailable: false,
        })
      }
    }
  }

  // Fisher-Yates shuffle
  shuffleArray(array) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  render() {
    const {
      name,
      description,
      video,
      questions,
      attachment,
      attachmentDescription,
      isUniversity,
    } = this.state
    let shuffledQuestions = []
    if (isEmpty(questions) === false) {
      shuffledQuestions = this.shuffleArray(questions).slice(0, 5)
    }

    const sources = [{ src: video.value, type: 'video/mp4' }],
      config = { mode: 'shim' },
      tracks = {}

    let lessons

    if (this.props.course) {
      lessons = this.props.course.lessons.map((lesson, index) => {
        let lessonUrl =
          '/dashboard/courses/' + this.props.course._id + '/' + lesson._id
        let lessonAccessMsg = 'View lesson'

        return (
          <li
            key={lesson._id}
            className="list-group-item list-group-item-action"
          >
            <div className="d-flex flex-column">
              <div>
                <h5>{lesson.name}</h5>
              </div>
              <div />
              <div>
                <a href={lessonUrl}>{lessonAccessMsg}</a>
              </div>
            </div>
          </li>
        )
      })
    }

    return (
      <div>
        <UserNav />
        <div className="wrapper-alt">
          <div className="container-fluid">
            <div className="row">
              <div className="col-8">
                {this.state.lessonAvailable === true ? (
                  <div>
                    <div>
                      <h2>{name}</h2>
                      <hr />
                    </div>

                    <div>
                      {this.state.description !== null &&
                      isEmpty(this.state.description) === false ? (
                        <p>{renderHTML(description)}</p>
                      ) : null}
                    </div>

                    <div
                      onContextMenu={(e) => {
                        e.preventDefault()
                        return false
                      }}
                    >
                      <MediaElement
                        id="player1"
                        mediaType="video"
                        preload="none"
                        controls
                        width="80%"
                        height=""
                        poster=""
                        sources={JSON.stringify(sources)}
                        options={JSON.stringify(config)}
                        tracks={JSON.stringify(tracks)}
                      />
                    </div>

                    <div>
                      <a href={attachment}>View attachment</a>
                      <p>{attachmentDescription}</p>
                    </div>

                    <div>
                      <UserQuestions questions={shuffledQuestions} />
                    </div>
                  </div>
                ) : (
                  <div className="container">
                    <h2>
                      Lesson not available until you pass the previous lesson.
                    </h2>
                  </div>
                )}
              </div>
              {this.state.lessonAvailable && (
                <div className="col-4">
                  <h4>Lessons: </h4>
                  {lessons}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ViewLesson.propTypes = {
  lesson: PropTypes.object,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  lessons: state.lessons,

  course: state.courses.course,
})

export default connect(mapStateToProps, {
  getLesson,
  getCourse,
  getUniversity,
  clearCourse,
})(withRouter(ViewLesson))
