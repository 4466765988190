import React, { Component } from "react";
import Popup from "reactjs-popup";
import { connect } from "react-redux";

import Menu from "../common/Menu";
import { getRoles, deleteRole } from "../../actions/roleActions";
import AddRole from './AddRole'
import EditRole from './EditRole'

class Roles extends Component {
    constructor(props){
      super(props)
      this.showRoleManage = this.showRoleManage.bind(this)
    }
    componentDidMount(){
      this.props.getRoles()
    }
    // componentWillReceiveProps(nextProps){
    //   console.log({
    //     nextProps
    //   })
    // }
    showRoleManage(){
      const {
          auth
      } = this.props

      if(auth.user.email === "mary@nationalfutures.com" || auth.user.email === "thirdessential@gmail.com") return true
      
      return false
   }
   
    render(){
      if(!this.showRoleManage()){
        this.props.history.push("/admin/dashboard/")
      }   
      const modalStyleAddLesson = {
        position: "fixed",
        zIndex: "1",
        height: "100%",
        width: "60vw",
        padding: "30px",
        right: "0",
        top: "0"
      };

      const roleList = this.props.roles.roles && this.props.roles.roles.map((el, index) => (
        <li key={index}
          className="list-group-item list-group-item-action"
        > 
          <div className="row align-items-center">
            <div className="col-2">
            <Popup
              trigger={
                <button className="btn btn-link">Privileges</button>
              }
              position="right center"
              modal={true}
              closeOnDocumentClick
              contentStyle={modalStyleAddLesson}
              arrow={false}
              closeOnEscape={true}
            >
              <div className="modal-container">
                <EditRole role={el} />
              </div>
            </Popup>
            </div>
            <div className="col-4">
              {el.name}
            </div>
            <div className="col-3">
              {el.description}
            </div>
            <div className="col-2">
              {el.status ? "Active" : "Inactive"}
            </div>
            <div className="col-1">
              <button onClick={()=> {this.props.deleteRole(el._id)}} className="btn btn-link">Delete</button>
            </div>
          </div>
        </li>
      ))
      return (
        <div className="wrapper-alt">
          <Menu />
          <div className="container roles-container">
            <Popup
              trigger={
                <button className="btn btn-link">Add Role</button>
              }
              position="right center"
              modal={true}
              closeOnDocumentClick
              contentStyle={modalStyleAddLesson}
              arrow={false}
              closeOnEscape={true}
            >
              <div className="modal-container">
                <AddRole />
              </div>
            </Popup>
            <div className="container">
              <div className="row">
                <div className="col-2">Privileges</div>
                <div className="col-4">Name</div>
                <div className="col-3">Description</div>
                <div className="col-2">Status</div>
                <div className="col-1">Delete</div>
              </div>
            </div>
            <ul className="list-group">{roleList}</ul>
          </div>
        </div>
      )
    }
}
const mapStateTopProps = state => ({
  roles: state.roles,
  auth: state.auth
})
export default connect(mapStateTopProps, {
  getRoles,
  deleteRole
})(Roles)