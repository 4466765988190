import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import { editUserProfile } from "../../actions/authActions";
import axios from "axios";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      cellular: "",
      errors: {}
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePasswordSubmit = this.handlePasswordSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.errors) {
      this.setState({
        errors: this.props.errors
      });
    }

    if (this.props.auth) {
      const user = this.props.auth.user;

      this.setState({
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        address: user.address,
        city: user.city,
        zip: user.zip,
        state: user.state,
        country: user.country,
        cellular: user.cellular
      });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const payload = {
      user: {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phone: this.state.phone,
        address: this.state.address,
        city: this.state.city,
        zip: this.state.zip,
        state: this.state.state,
        country: this.state.country,
        cellular: this.state.cellular
      }
    };

    this.props.editUserProfile(payload);
  }

  handlePasswordSubmit(e) {
    e.preventDefault();

    const payload = {
      password: this.state.password
    };

    axios
      .post("/api/users/changePassword", payload)
      .then(res => {
        this.setState({
          passwordChangeSuccess: true
        });
      })
      .catch(err => {
        this.setState({
          errors: err.response.data
        });
      });
  }

  renderAdditional() {
    const { errors } = this.state;
    if (this.props.auth.user.role === "User") {
      return (
        <div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Address</span>
            </div>

            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": errors.address
              })}
              placeholder="Address"
              name="address"
              value={this.state.address}
              onChange={this.handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.address}</div>
            )}
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Phone</span>
            </div>

            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": errors.phone
              })}
              placeholder="Phone"
              name="phone"
              value={this.state.phone}
              onChange={this.handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.phone}</div>
            )}
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Cellular</span>
            </div>

            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": errors.cellular
              })}
              placeholder="Cellular"
              name="cellular"
              value={this.state.cellular}
              onChange={this.handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.cellular}</div>
            )}
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Zip</span>
            </div>

            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": errors.zip
              })}
              placeholder="Zip"
              name="zip"
              value={this.state.zip}
              onChange={this.handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.zip}</div>
            )}
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Country</span>
            </div>

            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": errors.country
              })}
              placeholder="Country"
              name="country"
              value={this.state.country}
              onChange={this.handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.country}</div>
            )}
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">State</span>
            </div>
            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": errors.state
              })}
              placeholder="State"
              name="state"
              value={this.state.state}
              onChange={this.handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.state}</div>
            )}
          </div>

          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">City</span>
            </div>
            <input
              type="text"
              className={classnames("form-control", {
                "is-invalid": errors.city
              })}
              placeholder="City"
              name="city"
              value={this.state.city}
              onChange={this.handleChange}
            />
            {errors.email && (
              <div className="invalid-feedback">{errors.city}</div>
            )}
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="wrapper">
        <div className="container">
          <h3>Edit Profile</h3>

          <div className="container col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">First Name</span>
              </div>
              <input
                type="text"
                className={classnames("form-control", {
                  "is-invalid": errors.firstName
                })}
                placeholder="Firstname"
                name="firstName"
                value={this.state.firstName}
                onChange={this.handleChange}
              />
              {errors.firstName && (
                <div className="invalid-feedback">{errors.firstName}</div>
              )}
            </div>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Last Name</span>
              </div>
              <input
                type="text"
                className={classnames("form-control", {
                  "is-invalid": errors.lastName
                })}
                placeholder="Lastname"
                name="lastName"
                value={this.state.lastName}
                onChange={this.handleChange}
              />
              {errors.lastName && (
                <div className="invalid-feedback">{errors.lastName}</div>
              )}
            </div>

            <hr />

            {this.renderAdditional()}
            <button className="btn btn-pp" onClick={this.handleSubmit}>
              Save
            </button>

            <hr />
            <div>
              <h3>Change password</h3>
              <input
                type="password"
                className={classnames("form-control", {
                  "is-invalid": errors.password
                })}
                placeholder="Password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
              {errors.password && (
                <div className="invalid-feedback">{errors.password}</div>
              )}
              <div className="text-right">
                <button
                  className="btn btn-pp mt-3 "
                  onClick={this.handlePasswordSubmit}
                >
                  Change password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditProfile.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { editUserProfile }
)(EditProfile);
