import React, { Component } from "react";
import { connect } from "react-redux";
import { uploadVideo, clearFile } from "../../actions/courseActions";
import { getBuckets } from "../../actions/videoActions";
import Select from "react-select";
import PropTypes from "prop-types";

class VideoUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      bucket: null,
      selectedOption: null // Selected Video
    };
    this.handleVideoChange = this.handleVideoChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleVideoChange(e) {
    this.setState({
      file: e.target.files[0]
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const payload = new FormData();
    payload.append("video", this.state.file);
    // payload.append("bucketname", this.state.selectedOption.value);

    this.props.uploadVideo(payload, this.state.selectedOption.value);
  }

  componentWillUnmount() {
    this.props.clearFile();
  }

  renderButton() {
    if (this.props.courses.file === null) {
      return (
        <button className="btn btn-pp" onClick={this.handleSubmit}>
          Upload
        </button>
      );
    } else {
      return <span className="text-success">Video Uploaded</span>;
    }
  }

  render() {
    let options; // options for select
    if (this.props.buckets) {
      options = this.props.buckets.map(val => {
        return { label: val, value: val };
      });
    }

    const { selectedOption } = this.state;

    return (
      <div>
        <h1>Upload a new video</h1>
        <small className="text-muted">
          Once you upload a video it will be available in the dropdown menu when
          selecting a video to add to your Lesson/Subscription.
        </small>
        <div className="mb-3">
          <Select
            value={selectedOption}
            onChange={this.handleSelectChange}
            options={options}
            isSearchable={true}
            isClearable={true}
            placeholder="Select a bucket"
          />
        </div>

        {selectedOption === null ? null : (
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <input type="file" onChange={this.handleVideoChange} />
            </div>
            <div>{this.renderButton()}</div>
          </div>
        )}
      </div>
    );
  }
}

VideoUpload.propTypes = {
  buckets: PropTypes.array
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  courses: state.courses
});

export default connect(
  mapStateToProps,
  { uploadVideo, clearFile }
)(VideoUpload);
