import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCourses } from "../../actions/courseActions";
import {
  subscribeForCourse,
  subscribeForSub
} from "../../actions/memberActions";
import { getSubs } from "../../actions/subscriptionsActions";
import Select from "react-select";
import moment from "moment";
import DatePicker from "react-datepicker";

class AddSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      isLifetime: false,
      selectedOption: null,
      expirationDate: moment()
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCalendar = this.handleCalendar.bind(this);
  }

  componentDidMount() {
    const { type } = this.props;

    if (type === "course") {
      this.props.getCourses();
    }

    if (type === "sub") {
      this.props.getSubs();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  handleSubmit(e) {
    const { userID } = this.props;
    const courseID = this.state.selectedOption.value;
    const { type } = this.props;
    const { expirationDate, isLifetime } = this.state;

    e.preventDefault();

    if (type === "course") {
      this.props.subscribeForCourse(userID, courseID);
    }

    if (type === "sub") {
      this.props.subscribeForSub(userID, courseID, expirationDate, isLifetime);
    }

    this.setState({
      selectedOption: null,
      isLifetime: false
    });
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleCalendar(date) {
    this.setState({
      expirationDate: date
    });
  }

  renderDatePicker() {
    const { expirationDate, isLifetime } = this.state;
    const { type } = this.props;

    if (type === "sub" && isLifetime === false) {
      return (
        <DatePicker
          selected={this.state.expirationDate}
          onChange={this.handleCalendar}
          dateFormat="MM/DD/YYYY"
          className="form-control"
        />
      );
    } else {
      return null;
    }
  }

  renderIsLifetime() {
    const { isLifetime } = this.state;
    const { type } = this.props;

    if (type === "sub") {
      if (isLifetime === false) {
        return (
          <div className="mr-2">
            <i
              onClick={() => this.handleIsLifetime(isLifetime)}
              className="fas fa-check-square text-danger"
            />
            Lifetime
          </div>
        );
      } else if (isLifetime === true) {
        return (
          <div className="mr-2">
            <i
              onClick={() => this.handleIsLifetime(isLifetime)}
              className="fas fa-check-square text-success"
            />
            Lifetime
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  handleIsLifetime(isLifetime) {
    this.setState({
      isLifetime: !isLifetime
    });
  }

  renderButt() {
    const { selectedOption } = this.state;

    if (selectedOption !== null) {
      return (
        <button onClick={this.handleSubmit} className="btn btn-link">
          Add
        </button>
      );
    } else {
      return null;
    }
  }

  render() {
    const { courses } = this.props.courses;
    const { subscriptions } = this.props.subscriptions;

    let options; // options for select

    if (this.props.type === "course") {
      if (courses) {
        options = courses.map(value => {
          return { label: value.name, value: value._id };
        });
      }
    } else {
      if (subscriptions.length>0) {
        options = subscriptions.map(value => {
          return { label: value.name, value: value._id };
        });
      }
    }

    const { selectedOption } = this.state;
    return (
      <div className="row">
        <div className="col-6">
          <Select
            value={selectedOption}
            onChange={this.handleSelectChange}
            options={options}
            isSearchable={true}
            isClearable={true}
            placeholder={"Select a " + this.props.type}
          />
        </div>
        <div className="col-4 d-flex align-items-center">
          {this.renderIsLifetime()}
          {this.renderDatePicker()}
        </div>
        <div className="col-2">{this.renderButt()}</div>
      </div>
    );
  }
}

AddSubscription.propTypes = {
  userID: PropTypes.string,
  type: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  courses: state.courses,
  subscriptions: state.subscriptions
});

export default connect(
  mapStateToProps,
  { getCourses, subscribeForCourse, getSubs, subscribeForSub }
)(AddSubscription);
