import React, { Component } from "react";
import UserNav from "./UserNav";
import { connect } from "react-redux";
import {
  clearSubscription,
  getSingleSub
} from "../../actions/subscriptionsActions";
import PropTypes from "prop-types";
import ViewLesson from "./ViewLesson";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import isEmpty from "../../validation/is-empty";

class ViewSubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: []
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getSingleSub(id);
  }

  componentWillUnmount() {
    this.props.clearSubscription();
  }

  render() {
    let name;
    let description = "";
    let posts;
    let lesson;
    let image;
    let document = null;
    let documentName = null;

    if (this.props.subscription) {
      name = this.props.subscription.name;
      description = this.props.subscription.description;
      if (this.props.subscription.attachment) {
        documentName = this.props.subscription.attachment.label;
        document = (
          <a href={this.props.subscription.attachment.value}>{documentName}</a>
        );
      }

      posts = this.props.subscription.posts.map((post, index) => {
        return (
          <li key={post._id} className="list-group-item list-group-item-action">
            <div className="d-flex flex-column">
              <div>
                <h5>{post.title}</h5>
              </div>

              <div>
                <a
                  href={
                    "/dashboard/subscriptions/" +
                    this.props.match.params.id +
                    "/" +
                    post._id
                  }
                >
                  View
                </a>
              </div>
            </div>
          </li>
        );
      });
    }

    return (
      <div>
        <UserNav />
        <div className="wrapper-alt">
          <div className="container-fluid">
            <h1>{name}</h1>
            {document}
            <div className="row">
              <div className="col-8">
                <p>{renderHTML(description)}</p>
              </div>

              <div className="col-4">
                <h4>Posts: </h4>
                {posts}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  subscription: state.subscriptions.subscription
});

export default connect(
  mapStateToProps,
  { clearSubscription, getSingleSub }
)(ViewSubscription);
