import React, { Component } from "react";
import { getVideos } from "../../actions/videoActions";
import { getPAS, editPAS, deletePAS } from "../../actions/pasActions";
import { connect } from "react-redux";
import TextFieldGroup from "../common/TextFieldGroup";
import FileUpload from "../common/FileUpload";
import moment from "moment";
import ToggleButton from "react-toggle-button";
import Select from "react-select";
import PropTypes from "prop-types";
import isEmpty from "../../validation/is-empty";
import { clearFile } from "../../actions/courseActions";
import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"


class EditPas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      video: "",
      selectedOption: null, // Selected Video
      document: "",
      datePublished: moment(),
      attachments: [],
      published: false,
      filename: "",
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddDownload = this.handleAddDownload.bind(this);
  }
  componentDidMount() {
    this.props.getVideos();

    this.setState({
      name: this.props.post.title,
      selectedOption: this.props.post.video,
      document: this.props.post.document,
      published: this.props.post.published,
      attachments: this.props.post.documents
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleSubmit(e) {
    e.preventDefault();
    let document;
    if (this.state.document === "") {
      document = this.props.file;
    } else {
      document = this.state.document;
    }

    const payload = {
      post: {
        _id: this.props.post._id,
        title: this.state.name,
        video: this.state.selectedOption,
        documents: this.state.attachments,
        datePublished: moment(this.state.datePublished._d).format("YYYY/MM/DD"),
        published: this.state.published
      }
    };

    console.log(payload);

    this.props.editPAS(payload, this.props.history);
  }

  handleDelete() {
    this.props.deletePAS(this.props.post._id);
  }

  // === Attachments start ===

  handleAddDownload() {
    const { attachments, filename } = this.state;
    const newArej = attachments;
    const newAjtem = {
      label: filename,
      value: this.props.courses.file
    };
    if (this.props.courses.file !== null) {
      newArej.push(newAjtem);
      this.setState({
        attachments: newArej,
        filename: ""
      });
    }
    this.props.clearFile();
  }

  handleDownloads() {
    const { errors, id, filename } = this.state;

    return (
      <div className="mt-3">
        <div>
          <div className="row">
            <div className="col-8">
              <TextFieldGroup
                placeholder="filename"
                name="filename"
                type="name"
                value={filename}
                onChange={this.handleChange}
                error={errors.name}
              />
            </div>

            <div className="col-2" />

            <div className="col-2">
              <div>
                <button
                  onClick={this.handleAddDownload}
                  className="btn btn-link"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <FileUpload />
        </div>
      </div>
    );
  }

  deleteDl(index) {
    let arej = this.state.attachments;
    arej.splice(index, 1);
    this.setState({
      attachments: arej
    });
  }

  renderDownloads() {
    const { attachments } = this.state;

    if (isEmpty(attachments) === true) {
      return <div>No attachments</div>;
    } else {
      const renderDls = attachments.map((dl, index) => {
        return (
          <li key={dl.value} className="list-group-item">
            <div className="row">
              <div className="col-6">{dl.label}</div>
              <div className="col-5">
                <a href={dl.value} target="_blank">
                  view
                </a>
              </div>
              <div className="col-1">
                <i
                  onClick={() => this.deleteDl(index)}
                  className="fas fa-trash-alt"
                />
              </div>
            </div>
          </li>
        );
      });
      return renderDls;
    }
  }

  // === Attachments end ===

  render() {
    const { name, video, datePublished, published, errors } = this.state;
    const { videos } = this.props.videos;
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isShow = privileges && isVisible(privileges, "planningandscanning")
    const isEditable = privileges && isVisible(privileges, "planningandscanning", "edit")
    const isDeletable = privileges && isVisible(privileges, "planningandscanning", "delete")

    let options; // options for select
    if (videos) {
      options = videos;
    }
    const { selectedOption } = this.state;

    let document = "none";
    if (this.props.post.document) {
      document = this.props.post.document;
    }
    if(!isEditable || !isShow ){
      return <div className="p-5" />
    }
    return (
      <div className="wrapper-alt">
        <div className="container">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <div>
              <h1>Edit post</h1>
            </div>

            <div>
              Published
              <ToggleButton
                value={this.state.published}
                inactiveLabel="NO"
                activeLabel="YES"
                onToggle={published => {
                  this.setState({
                    published: !published
                  });
                }}
              />
            </div>

            <div>
              <button
                onClick={this.handleSubmit}
                to="/admin/dashboard/pas/create"
                className="btn btn-pp"
              >
                Save Post
              </button>
            </div>
          </div>

          <TextFieldGroup
            placeholder="name"
            name="name"
            type="name"
            value={name}
            onChange={this.handleChange}
            error={errors.name}
          />

          <div className="mb-5">
            <h3>Select a video:</h3>
            <Select
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <div>Attachment: {document}</div>

          <div>
            <h3>Attachments</h3>
            <div>
              <div>{this.handleDownloads()}</div>
              <div className="row mt-2">
                <div className="col-6">Filename</div>
                <div className="col-5">Link</div>
                <div className="col-1" />
              </div>
              <ul className="list-group">{this.renderDownloads()}</ul>
            </div>
          </div>
        </div>

        {isDeletable && <button className="btn btn-danger mt-5" onClick={this.handleDelete}>
          DELETE
        </button>}
      </div>
    );
  }
}

EditPas.propTypes = {
  post: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  videos: state.videos,
  courses: state.courses
});

export default connect(
  mapStateToProps,
  { getVideos, editPAS, deletePAS, clearFile }
)(EditPas);
