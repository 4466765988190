import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import classNames from "classnames";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class OrderList extends Component {
  render() {
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isShow = privileges && isVisible(privileges, "order")
    const isEditable = privileges && isVisible(privileges, "members", "edit")
    if(!isShow){
      return <div className="p-5"></div>
    }
    const orders = this.props.orders.map(order => {
      let dateCompleted = null;
      if (order.completionDate) {
        dateCompleted = moment(order.completionDate).format("MM-DD-YYYY");
      }
      return (
        <li key={order.Id} className="list-group-item list-group-item-action">
          <div className="row">
            {isEditable && <div className="col-1 text-left">
              <Link
                className="mr-2"
                to={
                  "/admin/handleorder/client/" + order.ClientId + "/" + order.Id
                }
              >
                Edit
              </Link>
            </div>}
            <div className={classNames("text-left ", {
              "col-2": isEditable,
              "col-3": !isEditable,
            })}>{order.CustomerName}</div>
            <div className="col-1 text-right ">{order.Id}</div>
            <div className="col-3 text-right">
              {moment(order.OrderedAt).format("dddd, MMMM Do YYYY")}
            </div>
            <div className="col-3 text-right">{order.ProductName}</div>
            <div className="col-2 text-right">
              <strong>${order.GrandTotal}</strong>
              <br />
              {order.OrderChargeStatusType === "Accepted" ? (
                <span className="text-success">
                  {order.OrderChargeStatusType}
                </span>
              ) : (
                <span className="text-danger">
                  {order.OrderChargeStatusType}
                </span>
              )}
              <br />

              <div>
                {order.completed === true ? (
                  <i className="fas fa-check-double" />
                ) : (
                  <i className="fas fa-times-circle" />
                )}
              </div>
              <div>{dateCompleted}</div>
            </div>
          </div>
        </li>
      );
    });

    return (
      <div>
        <div className="row">
          {isEditable && <div className="col-1 text-center">Edit</div>}
          <div className={classNames("text-center", {
            "col-2": isEditable,
            "col-3": !isEditable,
          })}>Name</div>
          <div className="col-1 text-center">Order ID</div>
          <div className="col-3 text-center">Date</div>
          <div className="col-3 text-center">Product</div>
          <div className="col-2 text-center">Total</div>
        </div>
        <ul className="list-group">{orders}</ul>
      </div>
    );
  }
}

OrderList.propTypes = {
  orders: PropTypes.array.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
})
export default connect(mapStateToProps)(OrderList);
