import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PasList from "./PasList";
import Menu from "../common/Menu";

import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class Pas extends Component {
  render() {
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isShow = privileges && isVisible(privileges, "planningandscanning")
    const isAdd = privileges && isVisible(privileges, "planningandscanning", "create")
    return (
      <div className="wrapper">
        <Menu />
        { !isShow && <div className="p-5" />}
        {isShow && <div className="container">
          <div className="d-flex justify-content-between mt-3 mb-3">
            <div>
              <h1>Planning and Scanning</h1>
            </div>

            <div>
              {isAdd && <Link to="/admin/dashboard/pas/create" className="btn btn-pp">
                Add a new post
              </Link>}
            </div>
          </div>

          <div>
            <PasList />
          </div>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Pas);
