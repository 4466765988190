import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  getCourse,
  editCourse,
  deleteCourse,
  clearCourse
} from "../../actions/courseActions";
import TextFieldGroup from "../common/TextFieldGroup";
import Menu from "../common/Menu";
import Popup from "reactjs-popup";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6
import { Link } from "react-router-dom";
import LessonList from "./LessonList";
import FileUpload from "../common/FileUpload";
import CreateLesson from "./CreateLesson";
import isVisible from "../../common-functions/isVisible"
import getPrivileges from "../../common-functions/getPrivileges"

class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      errors: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteCourse = this.deleteCourse.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getCourse(id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }

    if (nextProps.courses.course) {
      this.setState({
        name: nextProps.courses.course.name,
        description: nextProps.courses.course.description,
        image: nextProps.courses.course.image
      });
    }
  }

  componentWillUnmount() {
    this.props.clearCourse();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleQuillChange(value) {
    this.setState({ description: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { name, description } = this.state;
    const image = this.props.courses.file;
    const id = this.props.match.params.id;

    this.props.editCourse(name, description, image, id);
  }

  renderThumb() {
    if (this.state.image) {
      return (
        <div>
          <img className="course-thumbnail" src={this.state.image} />
        </div>
      );
    } else {
      return (
        <div>
          <span>No image found, upload a thumbnail for this course</span>
        </div>
      );
    }
  }

  deleteCourse(e) {
    e.preventDefault();

    this.props.deleteCourse(this.props.match.params.id);

    window.location.replace("/admin/dashboard/courses");
  }

  render() {
    const { name, description, errors } = this.state;

    
    const {
			auth
		} = this.props
		const privileges = getPrivileges(auth);
    const isEditable = privileges && isVisible(privileges, "courses", "edit")
    const isDeletable = privileges && isVisible(privileges, "courses", "delete")

    let lessons;
    if (this.props.courses.course) {
      lessons = this.props.courses.course.lessons;
    }

    const modalStyleAddLesson = {
      position: "fixed",
      zIndex: "1",
      height: "100%",
      width: "40vw",
      padding: "30px",
      right: "0",
      top: "0"
    };

    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]
      ]
    };

    return (
      <div className="wrapper">
        <Menu />
        {!isEditable && <div className="p-5" />}
        {isEditable && <div className="container">
          <div className="d-flex justify-content-between mt-3">
            <div>
              <h1>Edit Course</h1>
            </div>

            <div>
              <button className="btn btn-pp" onClick={this.handleSubmit}>
                Save
              </button>
            </div>
          </div>

          <div>
            <TextFieldGroup
              placeholder="name"
              name="name"
              type="name"
              value={name}
              onChange={this.handleChange}
              error={errors.name}
            />

            <div className="form-group">
              <ReactQuill
                value={description}
                onChange={this.handleQuillChange}
                modules={modules}
              />
            </div>

            <hr />

            <div className="mt-3 mb-3">
              <span>Image preview</span>
              {this.renderThumb()}
            </div>
            <div className="d-flex">
              <div className="mr-5">
                <h2>Thumbnail</h2>
              </div>
              <div>
                <FileUpload />
              </div>
            </div>
          </div>

          <hr />

          <div>
            <div className="d-flex justify-content-between mt-3 mb-2">
              <div>
                <h2>Lessons</h2>
              </div>

              <div>
                <Popup
                  trigger={<button className="btn btn-pp">Add Lesson</button>}
                  position="right center"
                  modal={true}
                  closeOnDocumentClick
                  contentStyle={modalStyleAddLesson}
                  arrow={false}
                  closeOnEscape={true}
                >
                  <div className="modal-container">
                    <CreateLesson id={this.props.match.params.id} />
                  </div>
                </Popup>
              </div>
            </div>
            <LessonList lessons={lessons} />
          </div>

          {isDeletable && <div className="mt-5">
            <button onClick={this.deleteCourse} className="btn btn-danger mt-5">
              Delete Course
            </button>
          </div>}
        </div>}
      </div>
    );
  }
}

Course.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  courses: state.courses
});

export default connect(
  mapStateToProps,
  { getCourse, editCourse, deleteCourse, clearCourse }
)(Course);
