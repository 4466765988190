import {
  GET_SUBS,
  CREATE_SUB,
  CLEAR_SUB,
  GET_USER_SUBS,
  GET_POST,
  UPLOAD_FILE_SUBS,
  CLEAR_FILE_SUBS
} from "../actions/types";

const initialState = {
  subscriptions: null,
  userSubs: null,
  file: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SUBS:
      return {
        ...state,
        subscriptions: action.payload
      };
    case CREATE_SUB:
      return {
        ...state,
        subscription: action.payload
      };
    case CLEAR_SUB:
      return {
        ...state,
        subscription: action.payload
      };
    case GET_USER_SUBS:
      return {
        ...state,
        userSubs: action.payload
      };
    case GET_POST:
      return {
        ...state,
        post: action.payload
      };
    case UPLOAD_FILE_SUBS:
      return {
        ...state,
        file: action.payload
      };
    case CLEAR_FILE_SUBS:
      return {
        ...state,
        file: null
      };

    default:
      return state;
  }
}
