import React, { Component } from 'react'
import { slide as Slide } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import isVisible from '../../common-functions/isVisible'
import getPrivileges from '../../common-functions/getPrivileges'

class Menu extends Component {
  showRoleManage() {
    const { auth } = this.props
    console.log(auth.user.email)
    if (
      auth.user.email === 'mary@nationalfutures.com' ||
      auth.user.email === 'thirdessential@gmail.com' ||
      auth.user.email === 'johnp@nationalfutures.com'
    )
      return true
    else return false
  }
  render() {
    const { auth } = this.props
    let privileges = getPrivileges(auth)

    if (privileges) {
      return (
        <Slide>
          {isVisible(privileges, 'order') && (
            <Link to="/admin/dashboard/orders">Orders</Link>
          )}
          {isVisible(privileges, 'members') && (
            <Link to="/admin/dashboard">Members</Link>
          )}
          {isVisible(privileges, 'courses') && (
            <Link to="/admin/dashboard/courses/">Courses</Link>
          )}
          {isVisible(privileges, 'planningandscanning') && (
            <Link to="/admin/dashboard/pas">Planning and Scanning</Link>
          )}
          {isVisible(privileges, 'subscription') && (
            <Link to="/admin/dashboard/subscriptions">Subscriptions</Link>
          )}
          {isVisible(privileges, 'emails') && (
            <Link to="/admin/dashboard/newsletters">Emails</Link>
          )}
          {isVisible(privileges, 'upload') && (
            <Link to="/admin/dashboard/video-upload">Upload a Video</Link>
          )}
          {isVisible(privileges, 'admins') && (
            <Link to="/admin/dashboard/admins">Managers</Link>
          )}
          {isVisible(privileges, 'upload') && (
            <Link to="/admin/dashboard/asset-upload">Upload assets</Link>
          )}
          {this.showRoleManage() && (
            <Link to="/admin/dashboard/role-management">Role Management</Link>
          )}
        </Slide>
      )
    }
    return (
      <Slide>
        <Link to="/admin/dashboard/orders">Orders</Link>
        <Link to="/admin/dashboard">Members</Link>
        <Link to="/admin/dashboard/courses/">Courses</Link>
        <Link to="/admin/dashboard/pas">Planning and Scanning</Link>
        <Link to="/admin/dashboard/subscriptions">Subscriptions</Link>
        <Link to="/admin/dashboard/newsletters">Emails</Link>
        <Link to="/admin/dashboard/video-upload">Upload a Video</Link>
        <Link to="/admin/dashboard/admins">Managers</Link>
        <Link to="/admin/dashboard/asset-upload">Upload assets</Link>
        {this.showRoleManage() && (
          <Link to="/admin/dashboard/role-management">Role Management</Link>
        )}
      </Slide>
    )
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
})
export default connect(mapStateToProps)(Menu)
