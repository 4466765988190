import React, { Component } from "react";
import TextFieldGroup from "../common/TextFieldGroup";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { editLesson, deleteLesson } from "../../actions/courseActions";
import { getVideos } from "../../actions/videoActions";
import { setQuestions } from "../../actions/lessonActions";
import { connect } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import Questions from "./Questions";
import FileUpload from "../common/FileUpload";
import ToggleButton from "react-toggle-button";
import isEmpty from "../../validation/is-empty";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

class EditLesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      video: "",
      attachment: "",
      attachmentDescription: "",
      selectedOption: null, // Selected Video
      questions: [],
      showQuestions: false,
      errors: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.deleteLesson = this.deleteLesson.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  componentDidMount() {
    this.props.getVideos();

    if (this.props.lesson) {
      this.setState({
        name: this.props.lesson.name,
        selectedOption: this.props.lesson.video,
        attachment: this.props.lesson.attachment,
        attachmentDescription: this.props.lesson.attachmentDescription
      });
    }

    if (this.props.lesson.description) {
      this.setState({
        description: this.props.lesson.description
      });
    }

    if (isEmpty(this.props.lesson.questions) === false) {
      this.setState({
        showQuestions: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption });
  };

  handleQuillChange(value) {
    this.setState({ description: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    let attachment;

    if (this.props.file !== null && this.props.file !== undefined) {
      attachment = this.props.file;
    } else {
      attachment = this.state.file;
    }

    const payload = {
      lesson: {
        name: this.state.name,
        description: this.state.description,
        video: this.state.selectedOption,
        attachment: attachment,
        attachmentDescription: this.state.attachmentDescription,
        questions: this.props.questions,
        _id: this.props.lesson._id
      }
    };

    this.props.editLesson(payload);
  }

  toggleQuestions() {
    const { showQuestions } = this.state;
    if (showQuestions === false) {
      return null;
    } else {
      return (
        <div>
          <Questions questions={this.props.lesson.questions} />
        </div>
      );
    }
  }

  deleteLesson(e) {
    e.preventDefault();

    this.props.deleteLesson(this.props.lesson._id);
  }

  render() {
    const {
      name,
      description,
      video,
      attachment,
      attachmentDescription,
      errors
    } = this.state;

    const questions = this.props.lesson.questions;
    if (questions) {
      this.props.setQuestions(questions);
    }

    const { videos } = this.props.videos;

    let options; // options for select

    if (videos) {
      options = videos;
    }

    const { selectedOption } = this.state;

    let document = "none";
    if (this.props.lesson.attachment) {
      document = this.props.lesson.attachment;
    }

    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link"]
      ]
    };

    return (
      <div className="container">
        <div className="d-flex justify-content-between mt-3">
          <div>
            <h1>Edit lesson</h1>
          </div>
          <div>
            <button onClick={this.handleSubmit} className="btn btn-pp">
              Save
            </button>
          </div>
        </div>

        <div>
          <TextFieldGroup
            placeholder="Name"
            name="name"
            type="name"
            value={name}
            onChange={this.handleChange}
            error={errors.name}
          />

          <ReactQuill
            value={description}
            onChange={this.handleQuillChange}
            modules={modules}
          />
          <div className="mb-3 mt-3">
            <label>Select a video:</label>
            <Select
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
              isSearchable={true}
              isClearable={true}
            />
          </div>

          <div>Attachment: {document}</div>

          <div className="mb-2">
            Upload new attachment
            <FileUpload />
          </div>

          <TextAreaFieldGroup
            placeholder="Attachment Description"
            name="attachmentDescription"
            type="attachmentDescription"
            value={attachmentDescription}
            onChange={this.handleChange}
            error={errors.attachmentDescription}
          />

          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3>Show questions</h3>
              </div>
              <div>
                <ToggleButton
                  value={this.state.showQuestions}
                  inactiveLabel="NO"
                  activeLabel="YES"
                  onToggle={showQuestions => {
                    this.setState({
                      showQuestions: !showQuestions
                    });
                  }}
                />
              </div>
            </div>
            {this.toggleQuestions()}
          </div>

          <div className="mt-5">
            <button onClick={this.deleteLesson} className="btn btn-danger">
              Delete Lesson
            </button>
          </div>
        </div>
      </div>
    );
  }
}

EditLesson.propTypes = {
  lesson: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  videos: state.videos,
  file: state.courses.file,
  questions: state.lessons.questions
});

export default connect(
  mapStateToProps,
  { getVideos, editLesson, deleteLesson, setQuestions }
)(EditLesson);
