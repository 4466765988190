import {
    ADD_MEMBER_SET_SUCCESS,
    ADD_MEMBER_CLEAR_SUCCESS
} from '../actions/types'

const initialState = {
    addMember: {
      isSuccess: false
    }
}

export default function(state = initialState, action) {
    switch (action.type) {
      case ADD_MEMBER_SET_SUCCESS:
        return {...state, addMember: {
            isSuccess: true
        }};
        
      case ADD_MEMBER_CLEAR_SUCCESS:
        return {...state, addMember: {
            isSuccess: false
        }};

      default:
        return state;
    }
  }
  