const getPrivileges = auth =>{
  let privileges = {}
  let status = false
  if(auth)
    if(auth.user)
      if(auth.user.rolemeta)
        if(auth.user.rolemeta.privileges){
          privileges = auth.user.rolemeta.privileges
          status = auth.user.rolemeta.status
        }
          
  return {privileges, status};
}
export default getPrivileges;
