import React, { Component } from "react";
import axios from "axios";
import TextFieldGroup from "../common/TextFieldGroup";

class resetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: "",
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    var payload = {
      token: this.props.match.params.token,
      password: this.state.password,
      password2: this.state.password2
    };

    axios
      .post("/api/users/reset-password", payload)
      .then(response => {
        if (response.status === 200) {
          window.location.replace("/login");
        }
      })
      .catch(error => {
        this.setState({
          errors: error.response.data
        });
      });
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="container">
        <h3>Reset your password</h3>

        <TextFieldGroup
          placeholder="Create your new password"
          name="password"
          type="password"
          value={this.state.password}
          onChange={this.handleChange}
          error={errors.password}
        />

        <TextFieldGroup
          placeholder="Confirm your new password"
          name="password2"
          type="password"
          value={this.state.password2}
          onChange={this.handleChange}
          error={errors.password2}
        />

        <span>{this.state.damn}</span>

        <button className="btn btn-pp" onClick={this.handleSubmit}>
          Submit
        </button>
      </div>
    );
  }
}

export default resetPassword;
