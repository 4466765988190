import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sendNewsletter } from '../../actions/newsletterActions'
import moment from 'moment'
import { withRouter } from 'react-router-dom'

class Newsletter extends Component {
  constructor(props) {
    super(props)

    this.handleSend = this.handleSend.bind(this)
  }
  handleSend() {
    this.props.sendNewsletter(this.props.newsletter._id)
  }

  render() {
    const { newsletter } = this.props

    return (
      <div className="row d-flex align-items-center">
        <div className="col-3">{newsletter.subject}</div>
        <div className="col-2">{newsletter.name}</div>
        <div className="col-2">{newsletter.fromName}</div>
        <div
          className="col-1"
          style={{ cursor: 'pointer', color: 'blue' }}
          onClick={() =>
            this.props.history.push(
              `/admin/dashboard/newsletters/edit/${newsletter._id}`,
            )
          }
        >
          Edit
        </div>
        <div className="col-2">
          {newsletter.date &&
            moment(newsletter.date).format('dddd, MMMM Do YYYY')}
        </div>
        <div className="col-1">{newsletter.sent.toString()}</div>
        <div className="col-1">
          {newsletter.sent === false ? (
            <button onClick={this.handleSend} className="btn btn-link">
              Send
            </button>
          ) : null}
        </div>
      </div>
    )
  }
}

Newsletter.propTypes = {
  newsletter: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, {
  sendNewsletter,
})(withRouter(Newsletter))
