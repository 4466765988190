import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/lib/Creatable";

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question: "",
      answer: null,
      options: [],
      isEdit: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      question: this.props.question,
      answer: { label: this.props.answer, value: this.props.answer },
      options: this.props.options
    });
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSelectChange = answer => {
    this.setState({ answer: answer });
  };

  handleOptionsChange = option => {
    this.setState({ options: option });
  };

  renderFaq() {
    let answer = "loading...";
    if (this.state.answer !== null) {
      answer = this.state.answer;
    }

    if (this.state.isEdit === false) {
      return (
        <div>
          <div>
            <span className="text-muted mr-2">Question:</span>
            <span>{this.props.question}</span>
          </div>

          <div>
            <span className="text-muted mr-2">Answer:</span>
            <span>{answer.label}</span>
          </div>

          <button
            className="btn btn-link"
            onClick={() => {
              this.setState({
                isEdit: true
              });
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-link"
            onClick={() => {
              this.props.deleteQuestion(this.props.index);
            }}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div className="question-edit-container">
          <span className="text-muted">Question:</span>
          <input
            type="text"
            name="question"
            value={this.state.question}
            onChange={this.handleChange}
            className="form-control"
            placeholder="Question"
          />
          <div className="mb-3">
            <CreatableSelect
              isClearable
              isMulti
              value={this.state.options}
              onChange={this.handleOptionsChange}
            />
          </div>
          <span className="text-muted">Answer:</span>
          <Select
            value={this.state.answer}
            onChange={this.handleSelectChange}
            options={this.state.options}
          />

          <button
            className="btn btn-link"
            onClick={() => {
              this.props.saveQuestion(
                this.state.question,
                this.state.answer,
                this.state.options,
                this.props.index
              );
              this.setState({
                isEdit: false
              });
            }}
          >
            Save
          </button>
        </div>
      );
    }
  }

  render() {
    return <div className="dash-faq-container">{this.renderFaq()}</div>;
  }
}

Question.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  options: PropTypes.array,
  index: PropTypes.number,
  saveQuestion: PropTypes.func.isRequired,
  deleteQuestion: PropTypes.func.isRequired
};

export default Question;
