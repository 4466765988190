import {
  GET_MEMBERS,
  GET_MEMBER,
  GET_CSV_DATA,
  CLEAR_CSV_DATA,
  GET_SHOPPINGCART_ORDERS,
  SET_MEMBER_LOADING,
  SET_PAYLOAD,
  CLEAR_MEMBERS,
  GET_ADMINS
} from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  members: null,
  member: null,
  admins: null,
  csvData: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MEMBERS:
      return {
        ...state,
        members: action.payload,
        loading: false
      };
    case GET_ADMINS:
      return {
        ...state,
        admins: action.payload
      };
    case CLEAR_MEMBERS:
      return {
        ...state,
        members: null
      };
    case SET_PAYLOAD:
      return {
        ...state,
        search: action.payload
      };
    case GET_MEMBER:
      return {
        ...state,
        member: action.payload
      };
    case GET_CSV_DATA:
      return {
        ...state,
        csvData: action.payload
      };
    case CLEAR_CSV_DATA:
      return {
        ...state,
        csvData: null
      };
    case SET_MEMBER_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
}
