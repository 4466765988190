import React, { Component } from 'react'
// Router
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'
import { Provider } from 'react-redux'
// CSS
import './App.css'
// Alerts
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/scale.css'

// Redux
import store from './store'
import { setCurrentUser } from './actions/authActions'

// Components
import Register from './components/auth/Register'
import PrivateRoute from './components/common/PrivateRoute'
import PrivateRouteUser from './components/common/PrivateRouteUser'
import Login from './components/auth/Login'
import TopNav from './components/layout/TopNav'
import Footer from './components/layout/Footer'
import forgotPassword from './components/auth/forgotPassword'
import Verified from './components/auth/Verified'
import PleaseVerify from './components/auth/PleaseVerify'
import resetPassword from './components/auth/resetPassword'
import NotFound from './components/NotFound'
import UserDashboard from './components/UserDashboard/UserDashboard'
import AdminDashboard from './components/AdminDashboard/AdminDashboard'
import EditProfile from './components/UserDashboard/EditProfile'
import AdminLogin from './components/auth/AdminLogin'
import EditMember from './components/AdminDashboard/EditMember'
import HandleOrder from './components/AdminDashboard/HandleOrder'
import AdminList from './components/AdminDashboard/AdminList'
import CreateCourse from './components/courses/CreateCourse'
import Courses from './components/courses/Courses'
import Course from './components/courses/Course'
import CreateLesson from './components/courses/CreateLesson'
import UserCourses from './components/UserDashboard/UserCourses'
import ViewCourse from './components/UserDashboard/ViewCourse'
import ViewUniversity from './components/UserDashboard/ViewUniversity'
import ViewLesson from './components/UserDashboard/ViewLesson'
import CreatePas from './components/pas/CreatePas'
import Pas from './components/pas/Pas'
import PlanningAndScanning from './components/UserDashboard/PlanningAndScanning'
import CreateSubscription from './components/subscriptions/CreateSubscription'
import SubscriptionsPar from './components/subscriptions/SubscriptionsPar'
import EditSubscription from './components/subscriptions/EditSubscription'
import Orders from './components/Orders/Orders'
import VideoUploadParent from './components/common/VideoUploadParent'
import Assets from './components/Assets/Assets'
import EditorTest from './components/common/EditorTest'
import Newsletters from './components/Newsletter/Newsletters'
import CreateNewsletter from './components/Newsletter/CreateNewsletter'
import AddAsset from './components/Assets/AddAsset'
import EditNewsletter from './components/Newsletter/EditNewsletter'
import UserSubscriptions from './components/UserDashboard/UserSubscriptions'
import ViewSubscription from './components/UserDashboard/ViewSubscription'
import ViewPost from './components/UserDashboard/ViewPost'
import UserDownloads from './components/UserDashboard/UserDownloads'

import Roles from './components/RoleManagement/Roles'

var retrieveUserData = localStorage.getItem('userData')
var retrievedUserData = JSON.parse(retrieveUserData)

if (localStorage.userData) {
  store.dispatch(setCurrentUser(retrievedUserData))
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="App">
            <TopNav />

            <Switch>
              <Route exact path="/" component={Login} />

              {/* <Route exact path="/register" component={Register} /> */}
              <Route exact path="/login" component={Login} />
              <Route exact path="/admin" component={AdminLogin} />

              {/* Password recovery */}
              <Route
                exact
                path="/login/forgotPassword"
                component={forgotPassword}
              />
              <Route path="/users/reset/:token" component={resetPassword} />
              <Route path="/users/verified" component={Verified} />
              <Route path="/users/verifyaccount" component={PleaseVerify} />
              {/* Password recovery END */}

              {/* PROTECTED ROUTES */}
              {/* USER */}
              <PrivateRouteUser
                exact
                path="/dashboard/"
                component={UserDashboard}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/edit-profile"
                component={EditProfile}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/courses"
                component={UserCourses}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/courses/:id"
                component={ViewCourse}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/courses/:id/:lesson"
                component={ViewLesson}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/university/"
                component={ViewUniversity}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/university/:id/:lesson"
                component={ViewLesson}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/planning-and-scanning"
                component={PlanningAndScanning}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/subscriptions"
                component={UserSubscriptions}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/subscriptions/:id"
                component={ViewSubscription}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/subscriptions/:id/:post"
                component={ViewPost}
              />

              <PrivateRouteUser
                exact
                path="/dashboard/downloads"
                component={UserDownloads}
              />

              {/* ADMIN */}
              <PrivateRoute
                exact
                path="/admin/dashboard/"
                component={AdminDashboard}
              />

              <PrivateRoute
                exact
                path="/admin/handleorder/client/:id/:orderId"
                component={HandleOrder}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/user/:id?"
                component={EditMember}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/admins"
                component={AdminList}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/courses/"
                component={Courses}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/courses/create"
                component={CreateCourse}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/courses/:id"
                component={Course}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/courses/:id/createlesson"
                component={CreateLesson}
              />

              <PrivateRoute exact path="/admin/dashboard/pas" component={Pas} />

              <PrivateRoute
                exact
                path="/admin/dashboard/pas/create"
                component={CreatePas}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/subscriptions/create"
                component={CreateSubscription}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/subscriptions/:id"
                component={EditSubscription}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/subscriptions"
                component={SubscriptionsPar}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/orders/:pageNum?"
                component={Orders}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/video-upload"
                component={VideoUploadParent}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/asset-upload"
                component={Assets}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/editor"
                component={EditorTest}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/newsletters"
                component={Newsletters}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/newsletters/create"
                component={CreateNewsletter}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/assets/add"
                component={AddAsset}
              />

              <PrivateRoute
                exact
                path="/admin/dashboard/newsletters/edit/:id"
                component={EditNewsletter}
              />
              <PrivateRoute
                exact
                path="/admin/dashboard/role-management"
                component={Roles}
              />

              {/* 404 */}
              <Route component={NotFound} />
            </Switch>
            <Footer />
          </div>
        </BrowserRouter>
      </Provider>
    )
  }
}

export default App
