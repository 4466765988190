import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

export default class HandleOrder extends Component {
  constructor() {
    super();
    this.state = {
      id: null,
      message: "Finding the user in our database... hang tight..."
    };
  }

  componentDidMount() {
    axios
      .get("/api/admin/getUserByClientId", {
        params: { clientID: this.props.match.params.id }
      })
      .then(res => {
        console.log(res.data._id);
        this.setState({
          id: res.data._id
        });

        const payload = {
          orderID: this.props.match.params.orderId
        };
        axios
          .post("/api/admin/completeOrder", payload)
          .then(res => {
            console.log("Order status changed to completed.");
          })
          .catch(err => {
            console.log("Order status change failed.");
          });
      })
      .catch(err => {
        this.setState({
          message: "User not found."
        });
        console.log("User not found");
      });
  }

  render() {
    const { id, message } = this.state;

    let redirect;

    if (id === null) {
      return <div>{message}</div>;
    } else {
      return <Redirect to={"/admin/dashboard/user/" + id} />;
    }

    return <div>{redirect}</div>;
  }
}
