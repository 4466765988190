import React, { Component } from "react";
import axios from "axios";

class forgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  //Handles state changes for all input fields based on the name
  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    let email = {
      email: this.state.email
    };

    axios
      .post("/api/users/forgotPassword", email)
      .then(response => {
        this.setState({
          message: "Please check your email for the password reset link"
        });
      })
      .catch(err => {
        this.setState({
          message: "Something went wrong, please try again later"
        });
      });
  }

  render() {
    return (
      <div className="container">
        <h3>Enter your email to receive a password reset link</h3>
        <div className="form-group">
          <input
            type="email"
            name="email"
            value={this.state.email}
            onChange={this.handleChange}
            className="form-control form-control-lg"
          />
          <button onClick={this.handleSubmit} className="btn btn-pp">
            Submit
          </button>
        </div>

        <span>{this.state.message}</span>
      </div>
    );
  }
}

export default forgotPassword;
