import { combineReducers } from 'redux'
import authReducer from './authReducer'
import errorReducer from './errorReducer'
import memberReducer from './memberReducer'
import courseReducer from './courseReducer'
import videoReducer from './videoReducer'
import lessonReducer from './lessonReducer'
import pasReducer from './pasReducer'
import subscriptionsReducer from './subscriptionsReducer'
import oneShoppingCartReducer from './oneShoppingCartReducer'
import newslettersReducer from './newslettersReducer'
import popupReducer from './popupReducer'
import formReducer from './formReducer'
import roleReducer from './roleReducer'
import assetsReducer from './assets'

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  members: memberReducer,
  courses: courseReducer,
  videos: videoReducer,
  lessons: lessonReducer,
  pas: pasReducer,
  subscriptions: subscriptionsReducer,
  orders: oneShoppingCartReducer,
  newsletters: newslettersReducer,
  popup: popupReducer,
  forms: formReducer,
  roles: roleReducer,
  assets: assetsReducer,
})
