import { SET_QUESTIONS, CLEAR_QUESTIONS, GET_LESSON, REMOVE_LESSON } from '../actions/types'
import isEmpty from '../validation/is-empty'

const initialState = {
  questions: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      }
    case CLEAR_QUESTIONS:
      return {
        ...state,
        questions: null,
      }
    case GET_LESSON:
      return {
        ...state,
        lesson: action.payload,
      }
    case REMOVE_LESSON:
      return {
        ...state,
        lesson: {
          ...state.lesson,
          orderIndex: 0
        },
      }

    default:
      return state
  }
}
