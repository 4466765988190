import React, { Component } from "react";
import { Link } from "react-router-dom";

class UserNav extends Component {
  render() {
    return (
      <nav className="navbar sticky-top navbar-dark bg-dark">
        <div className="container">
          <Link className="user-nav-item" to="/dashboard/planning-and-scanning">
            Planning and Scanning
          </Link>
          <Link className="user-nav-item" to="/dashboard/university/">
            University
          </Link>
          <Link className="user-nav-item" to="/dashboard/courses">
            Courses
          </Link>
          <Link className="user-nav-item" to="/dashboard/subscriptions">
            Subscriptions
          </Link>
          <Link className="user-nav-item" to="/dashboard/downloads">
            Downloads
          </Link>
        </div>
      </nav>
    );
  }
}

export default UserNav;
