import React, { Component } from 'react'
import ReactQuill from 'react-quill' // ES6
import 'react-quill/dist/quill.snow.css' // ES6
import classnames from 'classnames'
import { connect } from 'react-redux'
import { getCourses, clearFile } from '../../actions/courseActions'
import { getSubs } from '../../actions/subscriptionsActions'
import {
  createNewsletter,
  clearNewsletter,
} from '../../actions/newsletterActions'
import Select from 'react-select'
import FileUpload from '../common/FileUpload'
import Recipient from './Recipient'
import {
  getMembers,
  getMembersBySub,
  getMembersByCourse,
  memberLoading,
  clearMembers,
} from '../../actions/memberActions'
import isEmpty from '../../validation/is-empty'
import AsyncSelect from 'react-select/lib/Async'
import axios from 'axios'

class CreateNewsletter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: 'Email',
      fromName: '',
      subject: '',
      html: '',
      attachments: [],
      status: null,
      selectedOption: null,
      subStatus: '',
      recipients: [],
      defaultOptions: [],
      errors: [],
      filename: '',
      isweeklyoffer: false,
      offerHtml: false,
    }
    this.handleQuillChange = this.handleQuillChange.bind(this)
    this.handleOfferQuillChange = this.handleOfferQuillChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this)
    this.handleButtons = this.handleButtons.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.promiseOptions = this.promiseOptions.bind(this)
  }

  componentDidMount() {
    this.props.getCourses()
    this.props.getSubs()
  }
  componentWillUnmount() {
    this.props.clearNewsletter()
    this.props.clearMembers()
  }

  componentWillReceiveProps(nextProps) {
    let recipients = []
    let defaultOptions = []
    if (nextProps.members.members) {
      nextProps.members.members.map((val) => {
        let newItem = {
          label: val.firstName + ' ' + val.lastName,
          value: val.email,
        }

        if (val.emailStatus === true) {
          recipients.push(newItem)
        } else {
          defaultOptions.push(newItem)
        }
        this.setState({
          recipients: recipients,
          defaultOptions: defaultOptions,
        })
      })
    }
  }

  handleSubmit(e) {
    e.preventDefault()

    const {
      name,
      fromName,
      subject,
      html,
      status,
      selectedOption,
      subStatus,
      attachments,
      recipients,
      offerHtml
    } = this.state

    const dobijaciMejla = recipients.map((val) => {
      return {
        name: val.label,
        address: val.value,
      }
    })

    const newsletter = {
      name: name,
      fromName: fromName,
      subject: subject,
      html: html,
      attachments: attachments,
      offer: offerHtml,
      recipients: dobijaciMejla,
    }

    this.props.createNewsletter(newsletter)
  }

  handleQuillChange(value) {
    this.setState({ html: value })
  }
  

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleButtons(e) {
    this.setState({
      name: e.target.name,
      selectedOption: null,
      subStatus: null,
    })
  }

  handleCheckBoxChange(e) {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleSelectChange = (selectedOption) => {
    this.setState({ selectedOption })
  }

  handleSubSelectChange = (subStatus) => {
    this.setState({ subStatus })
  }

  handleAsyncSelectChange = (recipients) => {
    this.setState({ recipients })
  }

  handleOfferQuillChange(value) {
    this.setState({ offerHtml: value })
  }


  renderSubStatusSelect() {
    const { selectedOption, subStatus } = this.state

    if (selectedOption === null) {
      return null
    } else {
      return (
        <Select
          value={subStatus}
          onChange={this.handleSubSelectChange}
          options={[
            { label: 'Active', value: true },
            { label: 'Expired', value: false },
            { label: 'All', value: '' },
          ]}
          placeholder="Status"
        />
      )
    }
  }

  renderSelects(options) {
    const { name, selectedOption } = this.state

    if (name === 'Course') {
      return (
        <div>
          <Select
            value={selectedOption}
            onChange={this.handleSelectChange}
            options={options}
            isSearchable={true}
            isClearable={false}
            placeholder="Select a course"
          />
        </div>
      )
    } else if (name === 'Sub') {
      return (
        <div className="form-row">
          <div className="col">
            <Select
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={options}
              isSearchable={true}
              isClearable={false}
              placeholder="Select a subscription"
            />
          </div>
          <div className="col">{this.renderSubStatusSelect()}</div>
        </div>
      )
    }
  }

  handleAddFile(file) {
    const { attachments, filename } = this.state

    console.log({ attachments, file })

    let arej = attachments

    arej.push({
      filename,
      path: file,
    })

    this.setState({
      attachments: arej,
    })

    console.log({ state: this.state })
    this.props.clearFile()
  }

  renderFileUpload() {
    const file = this.props.file

    return (
      <div className="form-row">
        <div className="row">
          <div className="col-10">
            <FileUpload
              cb={(file) => {
                // console.log('callback', file)
                this.setState({ filename: file.name })
              }}
            />
          </div>
          <div className="col-2">
            <button
              onClick={() => this.handleAddFile(file)}
              className="btn btn-link"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    )
  }

  handleSearch() {
    const { name, selectedOption, subStatus } = this.state
    if (name === 'Course') {
      const payload = {
        returnAll: true,
        courseID: selectedOption.value,
      }
      this.props.getMembersByCourse(payload)
    }

    if (name === 'Sub') {
      const payload = {
        returnAll: true,
        subscriptionID: this.state.selectedOption.value,
        active: subStatus.value,
      }
      this.props.getMembersBySub(payload)
    }
  }

  renderDownloads() {
    const { attachments } = this.state

    if (isEmpty(attachments) === true) {
      return <div>No attachments.</div>
    } else {
      const renderDls = attachments.map((dl, index) => {
        return (
          <li key={dl.file} className="list-group-item">
            <div className="row">
              <div className="col-6">{index + 1}</div>
              <div className="col-5">
                <a href={dl.file} target="_blank">
                  view
                </a>
              </div>
              <div className="col-1">
                <i
                  onClick={() => this.deleteDl(index)}
                  className="fas fa-trash-alt"
                />
              </div>
            </div>
          </li>
        )
      })
      return renderDls
    }
  }

  deleteDl(index) {
    let arej = this.state.attachments
    arej.splice(index, 1)
    this.setState({
      attachments: arej,
    })
  }

  promiseOptions = (inputValue) =>
    new Promise((resolve, reject) => {
      const payload = {
        search: inputValue,
        returnAll: true,
      }
      if (inputValue.length >= 3) {
        axios
          .post('/api/admin/listUsers', payload)
          .then((response) => {
            resolve(
              response.data.map((user) => {
                return {
                  label: user.firstName + ' ' + user.lastName,
                  value: user.email,
                }
              }),
            )
          })
          .catch((error) => {
            console.log(error.response.data)
            reject(error)
          })
      } else {
        resolve([])
      }
    })

  render() {
    const {
      fromName,
      subject,
      html,
      name,
      selectedOption,
      status,
      recipients,
      defaultOptions,
      errors,
      isweeklyoffer,
      offerHtml,
    } = this.state

    let modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: "rtl" }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],
        ['link', 'image'],
      ],
    }

    const { courses } = this.props.courses
    const { subscriptions } = this.props.subscriptions
    let options // options for select

    if (name === 'Course') {
      if (courses) {
        options = courses.map((value) => {
          return { label: value.name, value: value._id }
        })
      }
    } else if (name === 'Sub') {
      if (subscriptions) {
        let pasOptions = { label: 'Planning and Scanning', value: 'PAS' }
        options = subscriptions.map((value) => {
          return { label: value.name, value: value._id }
        })

        options.push(pasOptions)
      }
    } else {
      options = null
    }

    if (this.props.newsletters.newsletter) {
      this.props.history.push('/admin/dashboard/newsletters/')
    }

    return (
      <div className="wrapper-alt">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h1>Create an email</h1>
            </div>
            <div>
              <button onClick={this.handleSubmit} className="btn btn-pp">
                Save
              </button>
            </div>
          </div>

          <div id="newsletter-form" className="mt-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">From Name</span>
              </div>

              <input
                type="text"
                className={classnames('form-control', {
                  'is-invalid': errors.fromName,
                })}
                placeholder="From Name"
                name="fromName"
                value={this.state.fromName}
                onChange={this.handleChange}
              />
              {errors.fromName && (
                <div className="invalid-feedback">{errors.fromName}</div>
              )}
            </div>

            <div className="input-group mt-2">
              <div className="input-group-prepend">
                <span className="input-group-text">Subject</span>
              </div>

              <input
                type="text"
                className={classnames('form-control', {
                  'is-invalid': errors.subject,
                })}
                placeholder="Subject"
                name="subject"
                value={this.state.subject}
                onChange={this.handleChange}
              />
              {errors.subject && (
                <div className="invalid-feedback">{errors.subject}</div>
              )}
            </div>

            <div className="form-group mt-2">
              <ReactQuill
                value={html}
                onChange={this.handleQuillChange}
                modules={modules}
              />
            </div>
          </div>

          <div>{this.renderFileUpload()}</div>

          <ul className="list-group">{this.renderDownloads()}</ul>
          <div className='mt-3'>
            <div className='form-group form-check'>
              <label class='form-check-label' htmlFor='isweeklyoffer'>
                <input
                  class='form-check-input'
                  onChange={this.handleCheckBoxChange}
                  id='isweeklyoffer'
                  name='isweeklyoffer'
                  type='checkbox'
                />{' '}
                Weekly Offer
              </label>
            </div>
            {isweeklyoffer && (
              <div className='form-group mt-2'>
                <ReactQuill
                  value={offerHtml}
                  onChange={this.handleOfferQuillChange}
                  modules={modules}
                />
              </div>
            )}
          </div>
          <div className="mt-3">
            <h2>Select a Group</h2>
            <div className="d-flex">
              <button
                onClick={this.handleButtons}
                name="Course"
                className="btn btn-link"
              >
                Course
              </button>
              <button
                onClick={this.handleButtons}
                name="Sub"
                className="btn btn-link"
              >
                Subscription
              </button>
            </div>

            <div>{this.renderSelects(options)}</div>

            {selectedOption !== null ? (
              <button onClick={this.handleSearch} className="btn btn-link">
                Add to recipients
              </button>
            ) : null}

            <div className="mt-2 mb-5">
              <h2>Recipients</h2>
              <AsyncSelect
                isMulti
                cacheOptions
                onChange={this.handleAsyncSelectChange}
                value={recipients}
                loadOptions={this.promiseOptions}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  members: state.members,
  courses: state.courses,
  subscriptions: state.subscriptions,
  newsletters: state.newsletters,
  file: state.courses.file,
})

export default connect(mapStateToProps, {
  getMembers,
  getCourses,
  getSubs,
  createNewsletter,
  clearNewsletter,
  clearFile,
  getMembersBySub,
  getMembersByCourse,
  clearMembers,
})(CreateNewsletter)
