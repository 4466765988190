import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getUserCourses } from "../../actions/courseActions";
import UserNav from "./UserNav";
import renderHTML from "react-render-html";
import isEmpty from "../../validation/is-empty";

class UserCourses extends Component {
  componentDidMount() {
    this.props.getUserCourses();
  }

  render() {
    const { userCourses } = this.props;
    let courses = "Loading...";
    if (isEmpty(userCourses) === false) {
      courses = userCourses.map((course, index) => {
        return (
          <li
            key={course._id}
            className="list-group-item list-group-item-action mb-3 course-prev-container mx-auto"
          >
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between">
                <div>
                  <h4>{course.name}</h4>
                </div>

                <div>
                  <span className="text-muted">
                    Lessons: {course.lessons.length}
                  </span>
                </div>
              </div>
              {/* <div>{renderHTML(course.description)}</div> */}

              <div className="mt-3">
                <Link
                  className="btn btn-pp btn-block"
                  to={"/dashboard/courses/" + course._id}
                >
                  View
                </Link>
              </div>
            </div>
          </li>
        );
      });
    } else {
      courses = "You don't have any courses";
    }

    return (
      <div>
        <UserNav />

        <div className="wrapper-alt">
          <div className="container">
            <h1>Your Courses</h1>
            <ul className="list-group">{courses}</ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  userCourses: state.courses.userCourses
});

export default connect(
  mapStateToProps,
  { getUserCourses }
)(UserCourses);
