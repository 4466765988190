import axios from "axios";
import {
    ADD_ROLE,
    GET_ROLE,
    GET_ERRORS,
    CLEAR_ERRORS
} from './types'

export const addRole = roleData => dispatch => {
    axios
    .post("/api/roles/addrole", roleData)
    .then(response => {
        console.log({
            response
        })
      dispatch(getRoles());
      dispatch({
        type: CLEAR_ERRORS,
        payload: {}
      })
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
}
export const getRoles = role => dispatch => {
    axios
    .get("/api/roles/getall")
    .then(response => {
        console.log({
            response
        })
        dispatch({
            type: GET_ROLE,
            payload: response.data.roles
        })
    })
    .catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    })

}

export const editPrivileges = (id, privileges) => dispatch => {
    axios
    .post("/api/roles/privileges/"+id, privileges)
    .then(response => {
        console.log({response})
        dispatch(getRoles());
    })
    .catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    })
}

export const deleteRole = id => dispatch => {
    axios
    .get("/api/roles/deleterole/"+id)
    .then(response => {
        console.log({ response })
        dispatch(getRoles())
    })
    .catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    })
}