import {
  GET_PAS,
  CREATE_PAS,
  GET_LATEST_POST,
  CLEAR_LATEST_POST
} from "../actions/types";
import isEmpty from "../validation/is-empty";

const initialState = {
  pas: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PAS:
      return {
        ...state,
        pas: action.payload
      };
    case GET_LATEST_POST:
      return {
        ...state,
        latestPost: action.payload
      };
    case CLEAR_LATEST_POST:
      return {
        ...state,
        latestPost: null
      };
    default:
      return state;
  }
}
