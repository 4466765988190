import React, { Component } from "react";
import UserNav from "./UserNav";
import { connect } from "react-redux";
import { clearCourse, getCourse } from "../../actions/courseActions";
import PropTypes from "prop-types";
import ViewLesson from "./ViewLesson";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";

class ViewCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lessons: []
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.props.getCourse(id);
  }

  componentWillUnmount() {
    this.props.clearCourse();
  }

  render() {
    let name;
    let description = "";
    let lessons;
    let lesson;
    let image;

    if (this.props.course) {
      name = this.props.course.name;
      description = this.props.course.description;
      lessons = this.props.course.lessons.map((lesson, index) => {
        return (
          <li
            key={lesson._id}
            className="list-group-item list-group-item-action"
          >
            <div className="d-flex flex-column">
              <div>
                <h5>{lesson.name}</h5>
              </div>

              <div>
                <a
                  href={
                    "/dashboard/courses/" +
                    this.props.match.params.id +
                    "/" +
                    lesson._id
                  }
                >
                  View
                </a>
              </div>
            </div>
          </li>
        );
      });
      image = this.props.course.image;
    }

    return (
      <div>
        <UserNav />
        <div className="wrapper-alt">
          <div className="container-fluid">
            <h1>{name}</h1>

            <div className="row">
              <div className="col-8">
                <p>{renderHTML(description)}</p>
              </div>
              <div className="col-4">
                <h4>Lessons: </h4>
                {lessons}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  course: state.courses.course
});

export default connect(
  mapStateToProps,
  { getCourse, clearCourse }
)(ViewCourse);
