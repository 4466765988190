import React, { Component } from "react";
import PropTypes from "prop-types";
import EditPas from "./EditPas";
import Popup from "reactjs-popup";
import { getPAS } from "../../actions/pasActions";
import { connect } from "react-redux";
import moment from 'moment'
class PasList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1
    };

    this.loadPage = this.loadPage.bind(this);
  }

  componentDidMount() {
    // const user = "";
    this.props.getPAS(this.state.page); //Why am i sending a user here?
  }

  loadPage(page) {
    this.props.getPAS(page);
  }

  renderPages(numOfPages) {
    let pages = [];
    for (let i = 1; i <= numOfPages; i++) {
      pages.push(
        <div key={i} className="pageCounter" onClick={() => this.loadPage(i)}>
          <span className="mr-2" key={i}>
            {i}
          </span>
        </div>
      );
    }
    return pages;
  }

  render() {
    const modalStyle = {
      position: "fixed",
      zIndex: "1",
      height: "100%",
      width: "40vw",
      padding: "30px",
      right: "0",
      top: "0"
    };

    let total = "loading..";
    let numOfPages = "loading..";
    let posts = [];

    if (this.props.pas.pas) {
      total = this.props.pas.pas.total;
      numOfPages = this.props.pas.pas.pages;
      posts = this.props.pas.pas.docs;
    }
    const renderPosts = posts.map(post => {
      return (
        <li key={post._id} className="list-group-item list-group-item-action">
          <div className="row">
            <div className="col-1">
              <Popup
                trigger={<span className="btn-link">Edit</span>}
                position="right center"
                modal={true}
                closeOnDocumentClick
                contentStyle={modalStyle}
                arrow={false}
                closeOnEscape={true}
              >
                <div className="modal-container">
                  <EditPas post={post} />
                </div>
              </Popup>
            </div>
            <div className="col-2">{post.title}</div>
            <div className="col-2">
              <a href={post.document} target="_blank">
                Attachment
              </a>
            </div>
            <div className="col-3">{post.video.label}</div>
            <div className="col-3">{moment(post.datePublished).format("dddd, MMMM Do YYYY")}</div>
            <div className="col-1">{post.published.toString()}</div>
          </div>
        </li>
      );
    });

    return (
      <div>
        <div className="row">
          <div className="col-1">Edit</div>
          <div className="col-2">Name</div>
          <div className="col-2">Attachment</div>
          <div className="col-3">Video</div>
          <div className="col-3">Date</div>
          <div className="col-1">Published</div>
        </div>
        <ul className="list-group">{renderPosts}</ul>
        <div className="d-flex flex-row flex-wrap">
          {this.renderPages(numOfPages)}
        </div>
      </div>
    );
  }
}
PasList.propTypes = {
  posts: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  pas: state.pas
});

export default connect(
  mapStateToProps,
  { getPAS }
)(PasList);
