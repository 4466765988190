import React, { Component } from "react";
import UserNav from "./UserNav";
import { connect } from "react-redux";
import { clearCourse, getUniversity } from "../../actions/courseActions";
import PropTypes from "prop-types";
import ViewLesson from "./ViewLesson";
import { Link } from "react-router-dom";
import renderHTML from "react-render-html";
import isEmpty from "../../validation/is-empty";

class ViewUniversity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lessons: [],
      isUniversity: true
    };
  }

  componentDidMount() {
    this.props.getUniversity();
  }

  componentWillUnmount() {
    // this.props.clearCourse();
  }

  render() {
    let name;
    let description = "";
    let lessons;
    let lesson;
    let image;

    if (this.props.course) {
      name = this.props.course.name;
      description = this.props.course.description;
      lessons = this.props.course.lessons.map((lesson, index) => {
        let lessonUrl =
          "/dashboard/courses/" + this.props.course._id + "/" + lesson._id;
        let lessonAccessMsg = "View lesson";
        return (
          <li
            key={lesson._id}
            className="list-group-item list-group-item-action"
          >
            <div className="d-flex flex-column">
              <div>
                <h5>{lesson.name}</h5>
              </div>

              <div>
                <a href={lessonUrl}>{lessonAccessMsg}</a>
              </div>
            </div>
          </li>
        );
      });
      image = this.props.course.image;
    }

    return (
      <div>
        <UserNav />
        <div className="wrapper-alt">
          <div className="container-fluid">
            {isEmpty(this.props.errors) === true ? (
              <div>
                <h1>{name}</h1>
                <div className="row">
                  <div className="col-8">
                    <p>{renderHTML(description)}</p>
                  </div>
                  <div className="col-4">
                    <h4>Lessons: </h4>
                    {lessons}
                  </div>
                </div>
              </div>
            ) : (
              <div>{this.props.errors.message}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  course: state.courses.course
});

export default connect(
  mapStateToProps,
  { getUniversity, clearCourse }
)(ViewUniversity);
