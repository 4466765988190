import axios from 'axios'
import {
  SET_QUESTIONS,
  CLEAR_QUESTIONS,
  GET_ERRORS,
  GET_LESSON,
  REMOVE_LESSON,
} from './types'

// Set questions
export const setQuestions = (questions) => (dispatch) => {
  dispatch({
    type: SET_QUESTIONS,
    payload: questions,
  })
}

export const clearQuestions = () => {
  return {
    type: CLEAR_QUESTIONS,
  }
}

// Get lesson
export const getLesson = (id) => (dispatch) => {
  axios
    .get('/api/courses/getLesson', { params: { lessonID: id } })
    .then((res) => {
      dispatch({
        type: GET_LESSON,
        payload: res.data,
      })
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
      dispatch({
        type: REMOVE_LESSON,
        payload: err.response.data,
      })
    })
}
