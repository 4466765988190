import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import TextFieldGroup from "../common/TextFieldGroup";
import { createCourse } from "../../actions/courseActions";
import Menu from "../common/Menu";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css"; // ES6

class CreateCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      errors: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleQuillChange = this.handleQuillChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleQuillChange(value) {
    this.setState({ description: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { name, description } = this.state;

    this.props.createCourse(name, description);
  }

  render() {
    const { errors } = this.state;

    if (this.props.courses.course) {
      this.props.history.push(
        "/admin/dashboard/courses/" + this.props.courses.course._id
      );
    }

    let modules = {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ script: "sub" }, { script: "super" }], // superscript/subscript

        [{ color: [] }, { background: [] }],
        ["link"]
      ]
    };

    return (
      <div className="wrapper">
        <Menu />
        <div className="container">
          <h1>Create a new course</h1>

          <TextFieldGroup
            placeholder="name"
            name="name"
            type="name"
            value={this.state.name}
            onChange={this.handleChange}
            error={errors.name}
          />

          <div className="form-group">
            <ReactQuill
              value={this.state.description}
              onChange={this.handleQuillChange}
              modules={modules}
            />
          </div>
          <button className="btn btn-pp" onClick={this.handleSubmit}>
            Save
          </button>
        </div>
      </div>
    );
  }
}

CreateCourse.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  courses: state.courses
});

export default connect(
  mapStateToProps,
  { createCourse }
)(CreateCourse);
