import React, { Component } from "react";
import PropTypes from "prop-types";
import { RadioGroup, Radio } from "react-radio-group";

class UserQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAnswer: "",
      isCorrect: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.tryAgain = this.tryAgain.bind(this);
  }

  handleChange(value) {
    this.setState({ userAnswer: value });

    if (value === this.props.question.answer) {
      this.setState({ isCorrect: true });
      this.props.countCorrect();
    } else {
      this.setState({ isCorrect: false });
    }
  }

  tryAgain() {
    this.setState({ isCorrect: null });
  }

  renderQuestion(displayOptions) {
    const { isCorrect, userAnswer } = this.state;

    if (isCorrect === null) {
      return (
        <div>
          <RadioGroup
            name="userAnswer"
            selectedValue={this.state.userAnswer}
            onChange={this.handleChange}
          >
            {displayOptions}
          </RadioGroup>
        </div>
      );
    } else if (isCorrect === true) {
      return (
        <div>
          <span className="text-success">Correct, well done!</span>
        </div>
      );
    } else if (isCorrect === false) {
      return (
        <div>
          <span className="text-danger mr-3">Incorrect</span>
          <span onClick={this.tryAgain} className="btn-link">
            Try again
          </span>
        </div>
      );
    }
  }

  render() {
    const { question, answer, options } = this.props.question;

    const displayOptions = options.map((val, index) => {
      return (
        <label key={index} className="mr-3">
          <Radio value={val.value} />
          {val.label}
        </label>
      );
    });

    return (
      <div className="mb-3">
        <div>{question}</div>
        <div>{this.renderQuestion(displayOptions)}</div>
      </div>
    );
  }
}

UserQuestion.propTypes = {
  question: PropTypes.object,
  countCorrect: PropTypes.func.isRequired
};

export default UserQuestion;
