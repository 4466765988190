import axios from "axios";
import {
  GET_ERRORS,
  GET_SUBS,
  CREATE_SUB,
  CLEAR_SUB,
  GET_USER_SUBS,
  GET_POST,
  UPLOAD_FILE_SUBS,
  CLEAR_FILE_SUBS
} from "./types";

// Get all subscriptions
export const getSubs = page => dispatch => {
  axios
    .get("/api/subscriptions/getAll", { params: { page: page } })
    .then(res => {
      dispatch({
        type: GET_SUBS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Create subscription
export const createSubs = (name, description) => dispatch => {
  const payload = {
    subscription: {
      name: name,
      description: description
    }
  };
  axios
    .post("/api/subscriptions", payload)
    .then(res => {
      dispatch({
        type: CREATE_SUB,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Get a single subscription
export const getSingleSub = id => dispatch => {
  axios
    .get("/api/subscriptions", { params: { id: id } })
    .then(res => {
      dispatch({
        type: CREATE_SUB, //same as GET_SUB
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Clear Subscription
export const clearSubscription = () => dispatch => {
  dispatch({
    type: CLEAR_SUB,
    payload: null
  });
};

// Edit subscription
export const editSubscription = (
  name,
  description,
  image,
  attachment,
  id
) => dispatch => {
  const payload = {
    subscription: {
      name: name,
      description: description,
      thumbnail: image,
      attachment: attachment,
      _id: id
    }
  };
  axios
    .put("/api/subscriptions", payload)
    .then(res => {
      dispatch({
        type: CREATE_SUB, //same as GET_SUB
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Delete subscription
export const deleteSubscription = id => dispatch => {
  axios
    .delete("/api/subscriptions", { params: { id: id } })
    .then(res => {
      dispatch({
        type: CREATE_SUB, //same as GET_SUB
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Create SubPost
export const createSubPost = (payload, id, history) => dispatch => {
  axios
    .post("/api/subscriptions/addPost", payload)
    .then(res => {
      dispatch({
        type: CREATE_SUB,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Edit SubPost
export const editSubPost = payload => dispatch => {
  axios
    .put("/api/subscriptions/editPost", payload)
    .then(res => {
      dispatch({
        type: CREATE_SUB,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Delete Sub post
export const deleteSubPost = id => dispatch => {
  axios
    .delete("/api/subscriptions/deletePost", { params: { id: id } })
    .then(res => {
      dispatch({
        type: CREATE_SUB, //same as GET_SUB
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Get User Subs
export const getUserSubs = () => dispatch => {
  axios
    .get("/api/subscriptions/getByUser")
    .then(res => {
      dispatch({
        type: GET_USER_SUBS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// Get single post
export const getPost = id => dispatch => {
  axios
    .get("/api/subscriptions/getPost", { params: { id: id } })
    .then(res => {
      dispatch({
        type: GET_POST,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

// File upload
export const fileUpload = payload => dispatch => {
  axios({
    method: "post",
    url: "/api/admin/uploadFile",
    data: payload
  })
    .then(function(response) {
      dispatch({
        type: UPLOAD_FILE_SUBS,
        payload: response.data
      });
    })
    .catch(function(response) {
      dispatch({
        type: GET_ERRORS,
        payload: response.data
      });
    });
};

// Clear File
export const clearFile = () => dispatch => {
  dispatch({
    type: CLEAR_FILE_SUBS
  });
};
