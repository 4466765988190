import React, { Component } from "react";
import { Link } from "react-router-dom";

class Verified extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="d-flex flex-column align-items-center">
        <div>
          <h2>
            Thank you for verifying your account, please go ahead and log in.
          </h2>
        </div>

        <div>
          <Link to="/login" className="btn btn-link">
            Login page
          </Link>
        </div>
      </div>
    );
  }
}

export default Verified;
