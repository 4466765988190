import React, { Component } from "react";
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import EditLesson from "./EditLesson";

class Lesson extends Component {
  render() {
    const { name, description, questions, _id } = this.props.lesson;
    const { index } = this.props;

    const modalStyle = {
      position: "fixed",
      zIndex: "1",
      height: "100%",
      width: "40vw",
      padding: "30px",
      right: "0",
      top: "0"
    };

    let qs;
    if (questions === null) {
      qs = [];
    } else {
      qs = questions.length;
    }

    return (
      <div className="row">
        <div className="col-1">{index + 1}</div>
        <div className="col-8">{name}</div>
        <div className="col-2 text-center">{qs}</div>
        <div className="col-1">
          <Popup
            trigger={<span className="btn-link">Edit</span>}
            position="right center"
            modal={true}
            closeOnDocumentClick
            contentStyle={modalStyle}
            arrow={false}
            closeOnEscape={true}
          >
            <div className="modal-container">
              <EditLesson lesson={this.props.lesson} />
            </div>
          </Popup>
        </div>
      </div>
    );
  }
}

Lesson.propTypes = {
  lesson: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default Lesson;
